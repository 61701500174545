import * as React from 'react';
import { memo } from 'react';

import ContentLoader from 'react-content-loader';
import { useWindowSize } from 'services/hooks';
import { CLIENT_WIDTH } from 'services/ui';

export const ProgramPreloader = memo((props) => {
  const { width } = useWindowSize();
  return width && width >= CLIENT_WIDTH.DESKTOP ? (
    <ContentLoader
      speed={2}
      width={286}
      height={666}
      viewBox="0 0 286 887"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <path d="M0 128H240V154H0V128Z" fill="#F4F5F7" />
      <path d="M0 158H286V184H0V158Z" fill="#F4F5F7" />
      <path d="M0 266H126V287H0V266Z" fill="#F4F5F7" />
      <path d="M17 299H286V317H17V299Z" fill="#F4F5F7" />
      <path d="M17 329H240V347H17V329Z" fill="#F4F5F7" />
      <path d="M17 359H286V377H17V359Z" fill="#F4F5F7" />
      <path d="M17 389H240V407H17V389Z" fill="#F4F5F7" />
      <path d="M17 419H286V437H17V419Z" fill="#F4F5F7" />
      <path d="M17 449H240V467H17V449Z" fill="#F4F5F7" />
      <path d="M17 479H286V497H17V479Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M17 509H240V527H17V509Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M17 539H286V557H17V539Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M17 569H240V587H17V569Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M17 599H286V617H17V599Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M17 629H240V647H17V629Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M17 659H286V677H17V659Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M17 689H240V707H17V689Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M17 719H286V737H17V719Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M17 749H240V767H17V749Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M17 779H286V797H17V779Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M17 809H240V827H17V809Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M17 839H286V857H17V839Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M17 869H240V887H17V869Z" fill="#F4F5F7" />
      <circle cx="11" cy="308" r="2" fill="#F4F5F7" />
      <circle cx="11" cy="336" r="2" fill="#F4F5F7" />
      <rect y="196" width="48" height="16" fill="#F4F5F7" />
      <rect x="72" y="196" width="48" height="16" fill="#F4F5F7" />
      <rect x="176" y="196" width="48" height="16" fill="#F4F5F7" />
      <rect y="216" width="48" height="20" fill="#F4F5F7" />
      <rect x="72" y="216" width="80" height="20" fill="#F4F5F7" />
      <rect x="176" y="216" width="48" height="20" fill="#F4F5F7" />
      <path d="M0 0H240V104H0V0Z" fill="#F4F5F7" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width="100%"
      height={266}
      viewBox="0 0 100% 266"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <rect width="100%" height="246" fill="#F4F5F7" />
      <rect width="100%" height="20" fill="#FFFFFF" />
    </ContentLoader>
  );
});
