import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { Colors, Directions, Sizes } from 'context';

export interface IArrow {
  direction: Directions;
  size?: Sizes;
  className?: string;
}

export const Arrow = memo(({ direction, size = Sizes.md, className }: IArrow) => (
  <ArrowStyled direction={direction} size={size} className={className} />
));

const ArrowStyled = styled.span<IArrow>`
  border: solid ${Colors.black};
  display: inline-block;
  ${({ size }) => {
    switch (size) {
      case Sizes.xs:
        return css`
          border-width: 0 1px 1px 0;
          padding: 2px;
        `;
      case Sizes.sm:
        return css`
          border-width: 0 1px 1px 0;
          padding: 2.6px;
        `;
      case Sizes.md:
        return css`
          border-width: 0 2px 2px 0;
          padding: 4px;
        `;
      default:
        return css`
          border-width: 0 2px 2px 0;
          padding: 5.6px;
        `;
    }
  }}

  ${({ direction }) => {
    switch (direction) {
      case Directions.right:
        return css`
          transform: rotate(-45deg);
        `;
      case Directions.left:
        return css`
          transform: rotate(135deg);
        `;
      case Directions.up:
        return css`
          transform: rotate(-135deg);
        `;
      case Directions.down:
        return css`
          transform: rotate(45deg);
        `;
      default:
        return css`
          transform: rotate(-45deg);
        `;
    }
  }}
`;
