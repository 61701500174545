import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NetworkErrors } from 'context';

export const useGoToErrorPage = () => {
  const navigate = useNavigate();

  const goToErrorPage = useCallback(
    (error?: NetworkErrors) => {
      navigate(`/${error || NetworkErrors.otherError}`, { replace: true });
    },
    [navigate],
  );

  return goToErrorPage;
};
