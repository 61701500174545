import { useCallback } from 'react';

import { AddStudentsMarksMutationVariables, useAddStudentsMarksMutation } from 'core/graphql';
import { LessonStudentsMarksQuery } from 'core/operations';

import { TaskTypes } from 'context';

export enum LessonTaskTypes {
  homework = 'Homework',
  coursework = 'Coursework',
  graduation_project = 'GraduationProject',
}

type AddStudentsMarksType = [
  (variables: AddStudentsMarksMutationVariables) => void,
  { success: boolean; loading: boolean; error?: string | null },
];

export const useAddStudentsMarks = (
  lessonsId: string,
  taskType: TaskTypes,
): AddStudentsMarksType => {
  const [addStudentsMarksMutation, { data, loading }] = useAddStudentsMarksMutation({
    refetchQueries: [
      {
        query: LessonStudentsMarksQuery,
        variables: {
          lessonsId,
          lessonIdForMarks: lessonsId,
          taskType,
        },
      },
    ],
  });

  const addStudentsMarks = useCallback(
    (variables) => addStudentsMarksMutation({ variables }),
    [addStudentsMarksMutation],
  );

  return [
    addStudentsMarks,
    {
      success: Boolean(data?.setStudentsMarks?.success),
      loading,
      error: data?.setStudentsMarks?.customErrors?.[0]?.code,
    },
  ];
};
