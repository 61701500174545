import { nanoid } from 'nanoid';

import { ILanguages, ISocialMedia, SocialMedia } from 'context';
import { IEducationValues, IExperienceValues } from 'organisms';

export const initialSocialMedia: ISocialMedia[] = [
  { id: nanoid(), name: SocialMedia.github, link: '' },
  { id: nanoid(), name: SocialMedia.linkedin, link: '' },
  { id: nanoid(), name: SocialMedia.telegram, link: '' },
];

export const initialExperience: IExperienceValues[] = [
  {
    id: nanoid(),
    company: '',
    position: '',
    startDate: {
      year: '',
      month: '',
    },
    endDate: {
      year: '',
      month: '',
    },
    projects: [],
  },
];

export const initialEducation: IEducationValues[] = [
  {
    id: nanoid(),
    institution: '',
    degree: '',
    specialization: '',
    startDate: {
      year: '',
      month: '',
    },
    endDate: {
      year: '',
      month: '',
    },
  },
];

export const initialLanguage: ILanguages[] = [
  {
    id: nanoid(),
    language: '',
    level: '',
  },
];
