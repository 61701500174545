import React, { memo } from 'react';

import generatePicker from 'antd/es/date-picker/generatePicker';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localePl from 'antd/es/date-picker/locale/pl_PL';
import { ConfigProvider } from 'antd';
import 'antd/es/date-picker/style/index';
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';
import { DateTime } from 'luxon';

import styled from 'styled-components';

import { Icon, IconNames } from 'atoms/Icon';
import { Colors } from 'context';
import fontService, { FontFamily, FontWeight } from 'services/FontService';

const AntdDatePicker = generatePicker<DateTime>(luxonGenerateConfig);

export interface IDatePicker {
  name?: string;
  value: Date | null;
  placeholder?: string;
  className?: string;
  defaultValue?: Date;
  disabled?: boolean;
  onChange: (value: Date | null) => void;
  disabledDate?: (date: Date) => boolean;
}

export const DatePicker = memo(
  ({
    name,
    value,
    placeholder,
    className,
    disabled,
    defaultValue,
    onChange,
    disabledDate,
  }: IDatePicker) => (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: `${Colors.main}`,
          colorLink: `${Colors.black}`,
        },
      }}>
      <StyledDatePicker
        locale={process.env.REACT_APP_LANG === 'pl' ? localePl : localeRu}
        className={className}
        name={name}
        defaultValue={defaultValue && DateTime.fromJSDate(defaultValue)}
        value={value ? DateTime.fromJSDate(value) : null}
        placeholder={placeholder}
        style={{ height: '32px', width: '224px' }}
        onChange={(changedValue) => (changedValue ? onChange(changedValue.toJSDate()) : undefined)}
        format="DD.MM.YYYY"
        disabled={disabled}
        suffixIcon={<Icon name={IconNames.Calendar} height={14} width={14} />}
        disabledDate={disabledDate ? (date) => disabledDate(date.toJSDate()) : undefined}
      />
    </ConfigProvider>
  ),
);

const StyledDatePicker = styled(AntdDatePicker)`
  border: 1px solid ${Colors.gray};
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  background-color: ${Colors.white} !important;
  ${fontService.text({ size: 12, lineHeight: 15, weight: FontWeight.regular })};

  & input {
    ${fontService.getFontFamily(FontFamily.rubik)};
  }
  & input[disabled] {
    color: ${Colors.black};
  }
  .ant-picker-clear {
    display: none;
  }
  &:hover {
    border-color: ${Colors.gray};
  }
  &.ant-picker-focused {
    border-color: ${Colors.main} !important;
  }
`;
