import React, { memo } from 'react';

import styled from 'styled-components';
import { Button } from 'atoms';
import { FormFieldset } from 'organisms/ResumeForm/FormFieldset';
import { Colors, IExperienceProject, IResumeDate, IResumeFields, Languages } from 'context';
import { IExperienceValues, ResumeExperienceCard } from 'organisms';

export interface IExperienceBlock {
  language: Languages;
  fields: IResumeFields;
  experience: IExperienceValues[];
  onAddExperience: () => void;
  onChangeProjects: (experienceId: string, projects: IExperienceProject[]) => void;
  onInputChange: (name: string, value: string | IResumeDate) => void;
  onRemoveExperience: (id: string) => void;
}

export const ExperienceBlockComponent = memo(
  ({
    language,
    fields,
    experience,
    onAddExperience,
    onChangeProjects,
    onRemoveExperience,
    onInputChange,
  }: IExperienceBlock) => (
    <FormFieldset groupTitle={fields.experience.fieldsetTitle}>
      <ItemWrapper>
        {!!experience.length &&
          experience.map((item, index) => (
            <ResumeExperienceCard
              key={item.id}
              language={language}
              fields={fields}
              index={index}
              values={item}
              onChangeProjects={(projects) => onChangeProjects(item.id, projects)}
              onRemoveExperience={onRemoveExperience}
              onInputChange={onInputChange}
            />
          ))}
      </ItemWrapper>
      <Button variant="outline" colors={{ borderColor: Colors.gray }} onClick={onAddExperience}>
        {fields.experience.addCompanyButton}
      </Button>
    </FormFieldset>
  ),
);

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;
