import { ISelectOptions } from 'context';

export const getYears = (): string[] => {
  const currentYear = new Date().getFullYear();
  const years = new Array(100);
  for (let i = 0; i <= 100; i += 1) {
    years[i] = i === 0 ? `${currentYear}` : `${currentYear - i}`;
  }

  return years;
};

export const getYearsOptions = (yearPlaceholder: string): ISelectOptions[] => {
  const years = getYears();

  return years.reduce(
    (acc: ISelectOptions[], item) => {
      const option: ISelectOptions = {
        label: item,
        value: item,
      };

      return [...acc, option];
    },
    [{ label: yearPlaceholder, value: '' }],
  );
};
