import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import styled from 'styled-components';
import { Navigate } from 'react-router-dom';
import { PATHS } from 'router/config';

import { Colors } from 'context';
import { Button, Icon, IconNames } from 'components/atoms';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet } from 'services/ui';
import { ExternalPaths } from 'router/external';

type errorPagesType = {
  [status: number | string]: {
    img: IconNames;
    heading: string;
    text: string;
    link?: string;
    linkText?: string;
    buttonText: string;
  };
};

const getErrorPages = (t: TFunction): errorPagesType => ({
  404: {
    img: IconNames.Error404,
    heading: `${t('error.404.heading')}`,
    text: `${t('error.404.text')}`,
    link: ExternalPaths.mailto_info,
    linkText: `${t('footer.infoEmail')}`,
    buttonText: `${t('error.404.button')}`,
  },
  500: {
    img: IconNames.Error500,
    heading: `${t('error.500.heading')}`,
    text: `${t('error.500.text')}`,
    link: ExternalPaths.mailto_info,
    linkText: `${t('footer.infoEmail')}`,
    buttonText: `${t('error.500.button')}`,
  },
  503: {
    img: IconNames.Error503,
    heading: `${t('error.503.heading')}`,
    text: `${t('error.503.text')}`,
    buttonText: `${t('error.503.button')}`,
  },
  error: {
    img: IconNames.Error,
    heading: `${t('error.error.heading')}`,
    text: `${t('error.error.text')}`,
    link: ExternalPaths.mailto_info,
    linkText: `${t('footer.infoEmail')}`,
    buttonText: `${t('error.error.button')}`,
  },
});

export const ErrorPage = memo(({ status }: { status: number | string }) => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState('');
  const { heading, img, text, link, linkText, buttonText } = getErrorPages(t)[status];

  const goHome = useCallback(() => setRedirect(PATHS.main), []);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <ErrorPageContainer>
      <IconStyled name={img} />

      <Heading>{heading}</Heading>

      <Text>
        {text}{' '}
        <TextLink href={link} target="_blank">
          {linkText}
        </TextLink>
      </Text>

      <ButtonWrapper>
        <Button onClick={goHome}>{buttonText}</Button>
      </ButtonWrapper>
    </ErrorPageContainer>
  );
});

const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 30px;

  ${desktop} {
    width: 100vw;
    padding: 0;
  }
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  display: inline-block;
  width: 100%;
  height: auto;

  ${tablet} {
    width: 616px;
    height: 326px;
  }
`;

const Heading = styled.h4`
  ${fontService.text({ size: 18, lineHeight: 133, weight: FontWeight.bold })};
  margin: 16px 0 0 0;
  text-align: center;

  ${tablet} {
    ${fontService.text({ size: 32, lineHeight: 138, weight: FontWeight.bold })};
  }
`;

const Text = styled.div`
  ${fontService.text({ size: 14, lineHeight: 142, weight: FontWeight.regular })};
  margin-top: 8px;
  text-align: center;

  ${tablet} {
    ${fontService.text({ size: 22, lineHeight: 145, weight: FontWeight.regular })};
  }

  ${desktop} {
    width: 808px;
  }
`;

const TextLink = styled.a`
  color: ${Colors.black};
  text-decoration: underline;
  &:hover {
    transition: 0.5s;
    color: ${Colors.gray_dark};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 36px;
`;
