import { Icon, IconNames } from 'components/atoms';
import React, { memo } from 'react';
import styled from 'styled-components';

export const Splash = memo(() => (
  <SplashContainer>
    <Icon name={IconNames.Splash} width={250} height={250} />
  </SplashContainer>
));

export const SplashContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
