import React, { memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useToaster, useUploadVideo } from 'core';
import { ToastTypes } from 'atoms';
import { VideoUploadComponent } from './VideoUpload';

export const VideoUpload = memo(() => {
  const { t } = useTranslation();
  const { lessonId } = useParams();
  const { addToast } = useToaster();

  const [videoLink, setVideoLink] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [uploadVideo, cancelUpload, { success, customError, loading, cancelError }] =
    useUploadVideo(lessonId);

  const onInputChange = useCallback((value: string) => setVideoLink(value.split(' ').join('')), []);

  const onSubmitClick = useCallback(() => {
    if (lessonId) {
      uploadVideo(lessonId, videoLink);
    }
  }, [lessonId, videoLink, uploadVideo]);

  const onCancelClick = useCallback(() => {
    if (lessonId) {
      cancelUpload(lessonId);
      setShowLoading(false);
    }
  }, [cancelUpload, lessonId]);

  useEffect(() => {
    if (loading) {
      setShowLoading(true);
    }
  }, [loading]);

  useEffect(() => {
    if (!success && customError) {
      if (customError === 'get_rec_error') {
        setErrorMessage(`${t('lesson.video.videoId.errorWrongId')}`);
      }
      addToast('add_lesson_video', {
        type: ToastTypes.danger,
        text: `${t('lesson.video.uploadError')}`,
      });
      setShowLoading(false);
    }

    if (success) {
      addToast('add_lesson_video', {
        type: ToastTypes.success,
        text: `${t('lesson.video.uploadSuccess')}`,
      });
      setErrorMessage('');
      setIsSuccess(true);
    }

    if (cancelError) {
      addToast('add_lesson_video', {
        type: ToastTypes.danger,
        text: `${t('lesson.video.cancelError')}`,
      });
    }
  }, [addToast, customError, success, cancelError, t]);

  return (
    <VideoUploadComponent
      videoLink={videoLink}
      errorMessage={errorMessage}
      onInputChange={onInputChange}
      onSubmitClick={onSubmitClick}
      onCancelClick={onCancelClick}
      isLoading={showLoading}
      isSuccess={isSuccess}
    />
  );
});
