import React, { memo } from 'react';
import styled from 'styled-components';

import { ITab, Tab, Title } from 'components/atoms';
import { Triangle } from 'atoms/Title/Title';
import { desktop, mobile, tablet } from 'services/ui';

export interface ITabs {
  path?: string;
  tabs: ITab[];
  className?: string;
}

export const Tabs = memo(({ path, tabs, className }: ITabs) => (
  <Wrapper className={className}>
    <List>
      {tabs.map((tab, i) => (
        <ListItem key={i}>
          <Tab to={tab.to} isActive={path === tab.to || tab.isActive} isEnabled={tab.isEnabled}>
            {tab.children}
          </Tab>
          {tab.isEnabled !== undefined && !tab.isEnabled && (
            <TitleStyled text={tab.enabledText} position="right" />
          )}
        </ListItem>
      ))}
    </List>
  </Wrapper>
));

const Wrapper = styled.div`
  position: relative;
`;

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  ${mobile} {
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TitleStyled = styled((props) => <Title {...props} />)`
  display: none;
`;

const ListItem = styled.li`
  &:not(:first-of-type) {
    margin-left: 16px;
    position: relative;

    ${tablet} {
      margin-left: 28px;
    }

    &:hover {
      ${TitleStyled} {
        ${tablet} {
          top: -20px;
          left: 0;
          display: flex;
          & > ${Triangle} {
            top: 100%;
            left: 10%;
            transform: translateY(0) rotate(90deg);
          }
        }

        ${desktop} {
          top: 35%;
          left: calc(100% + 16px);
          & > ${Triangle} {
            top: 50%;
            left: calc(-10px);
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }
`;
