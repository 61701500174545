import React, { memo } from 'react';
import styled from 'styled-components';

export interface IAttachmentsTemplate {
  children: React.ReactElement;
}

export const AttachmentsTemplate = memo(({ children }: IAttachmentsTemplate) => (
  <Wrapper onContextMenu={(e) => e.preventDefault()}>{children}</Wrapper>
));

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media print {
    display: none;
  }
`;
