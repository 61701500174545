import { useEffect, useCallback, useMemo, useState } from 'react';
import { authState, useLoginMutation } from 'core';
import { saveToken } from 'services/AuthService';
import { useTranslation } from 'react-i18next';

type codeErrorType = { authenticationCode: string; isOutOfAttempts?: boolean } | null;

type LoginType = [
  (loginInput: { email: string; authenticationCode: string }) => void,
  {
    success: boolean;
    loading: boolean;
    codeError: codeErrorType;
  },
];

export const useLogin = (): LoginType => {
  const { t } = useTranslation();
  const [loginMutation, { data, loading }] = useLoginMutation({});
  const [codeError, setCodeError] = useState<codeErrorType>(null);

  const success = useMemo(() => Boolean(data?.customLogin?.success), [data?.customLogin?.success]);

  useEffect(() => {
    if (success && data?.customLogin?.refreshToken && data.customLogin.token) {
      authState({
        isLoggedIn: true,
        access_token: data.customLogin.token,
        refresh_token: data.customLogin.refreshToken,
        isTokenRefreshed: true,
        active_role: data.customLogin.user?.activeRole,
        isDemoUser: false,
      });
      saveToken(data.customLogin.refreshToken);
    }
  }, [data, success]);

  const login = useCallback(
    ({ email, authenticationCode }) =>
      loginMutation({
        variables: { email, authenticationCode, scope: process.env.REACT_APP_SCOPE || 'LMS' },
      }),
    [loginMutation],
  );

  useEffect(() => {
    if (data?.customLogin?.customErrors?.[0]?.code === 'invalid_auth_code') {
      setCodeError({ authenticationCode: data.customLogin.customErrors[0].message || '' });
    }

    if (data?.customLogin?.customErrors?.[0]?.code === 'used_attempts') {
      setCodeError({
        authenticationCode: `${t('login.noAttemptLeft')}`,
        isOutOfAttempts: true,
      });
    }
  }, [data?.customLogin?.customErrors, t]);

  return [login, { success, loading, codeError }];
};
