import React, { memo, useState } from 'react';

import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Colors } from 'context';
import { InputTag } from 'atoms';

export interface IInputWithTags {
  tags: string[];
  inputValue: string;
  inputSize: number;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onRemoveTag: (index: number) => void;
}

export const InputWithTagsComponent = memo(
  ({
    tags,
    inputValue,
    inputSize,
    placeholder,
    onInputEnter,
    onChange,
    onRemoveTag,
  }: IInputWithTags) => {
    const [focused, setFocused] = useState<boolean>(false);

    return (
      <Wrapper focused={focused}>
        {tags.map((value, index) => (
          <InputTag key={index} text={value} onClick={() => onRemoveTag(index)} />
        ))}

        <Input
          size={inputSize}
          value={inputValue}
          type="text"
          onChange={onChange}
          onKeyDown={onInputEnter}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={tags.length ? '' : placeholder}
        />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ focused: boolean }>`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: ${Colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ focused }) => (focused ? Colors.main : Colors.gray)};
  border-radius: 8px;
  color: ${Colors.black};
`;

const Input = styled.input`
  flex-grow: 1;
  ${fontService.text({ size: 14, lineHeight: 170, weight: FontWeight.regular })}
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;

  ::placeholder {
    color: ${Colors.gray};
    ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.regular })}
  }
`;
