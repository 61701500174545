import React, { memo } from 'react';

import styled from 'styled-components';
import { Button, Input, TextArea } from 'atoms';
import { Colors, IExperienceProject, IResumeFields } from 'context';
import { PopupContainer, InputWithTags } from 'molecules';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, mobile, tablet } from 'services/ui';

export interface IAddProjectPopup {
  fields: IResumeFields;
  project: IExperienceProject;
  isEmptyProjectName: boolean;
  errorMessage?: string;
  onInputChange: (name: string, value: string | string[]) => void;
  isShowing: boolean;
  onClose: () => void;
  onAddProject: () => void;
}

export const AddProjectPopupComponent = memo(
  ({
    project,
    fields,
    isEmptyProjectName,
    errorMessage,
    onInputChange,
    isShowing,
    onAddProject,
    onClose,
  }: IAddProjectPopup) => (
    <PopupContainer
      title={fields.experience.project.addProjectTitle}
      isShowing={isShowing}
      onClose={onClose}
      hasMargin>
      <Wrapper>
        <Content>
          <Input
            value={project.name}
            type="text"
            onChange={({ target: { value } }) => onInputChange('name', value)}
            max={255}
            required
            label={fields.experience.project.projectName.label}
            placeholder={fields.experience.project.projectName.placeholder}
            hasError={isEmptyProjectName}
            message={errorMessage}
          />

          <InputWrapper>
            <Label>{fields.experience.project.technologies.label}</Label>
            <InputWithTags
              tagsList={project.technologies}
              max={10}
              placeholder={fields.experience.project.technologies.placeholder}
              onChange={(value) => onInputChange('technologies', value)}
            />
          </InputWrapper>

          <InputWrapper>
            <Label>{fields.experience.project.problem.label}</Label>
            <StyledTextArea
              value={project.problem}
              rows={4}
              onChange={({ target: { value } }) => onInputChange('problem', value)}
              placeholder={fields.experience.project.problem.placeholder}
              maxLength={1500}
              borderColor={Colors.gray}
            />
          </InputWrapper>

          <Input
            value={project.solution}
            type="text"
            onChange={({ target: { value } }) => onInputChange('solution', value)}
            max={300}
            label={fields.experience.project.solution.label}
            placeholder={fields.experience.project.solution.placeholder}
          />
        </Content>

        <ButtonsWrapper>
          <StyledCancelButton variant="text" onClick={onClose}>
            {fields.experience.project.cancel}
          </StyledCancelButton>
          <StyledButton onClick={onAddProject} type="submit">
            {fields.experience.project.addProjectButton}
          </StyledButton>
        </ButtonsWrapper>
      </Wrapper>
    </PopupContainer>
  ),
);

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
  padding: 0 20px 40px;

  ${tablet} {
    margin-top: 32px;
    padding: 0 40px 64px;
  }

  ${desktop} {
    margin: 0;
    padding: 0 60px 64px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  background-color: ${Colors.bg_gray};
  padding: 20px 20px 32px;
  border-radius: 0 0 24px 24px;

  ${tablet} {
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    padding: 12px 40px;
  }

  ${desktop} {
    padding: 16px 60px;
  }
`;

const StyledButton = styled(Button)`
  ${tablet} {
    max-width: max-content;
  }
`;

const StyledCancelButton = styled(StyledButton)`
  ${mobile} {
    border: 1px solid ${Colors.gray};
    border-radius: 8px;
  }
`;

const InputWrapper = styled.div``;

const Label = styled.p`
  color: ${Colors.black};
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.medium })};
  margin: 0 0 4px 0;
`;

const StyledTextArea = styled(TextArea)`
  padding: 14px 12px;
  border-radius: 8px;
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.regular })}
`;
