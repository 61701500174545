import { gql } from '@apollo/client';

export const FinishLesson = gql`
  mutation finishLesson(
    $groupLessonId: Int!
    $comment: String!
    $attendances: [LmsAttendanceType!]!
  ) {
    finishLmsLesson(groupLessonId: $groupLessonId, comment: $comment, attendances: $attendances) {
      success
      errors
      customErrors {
        code
        field
        message
      }
    }
  }
`;
