import i18n from '../../i18n';

export interface IResumeFields {
  personalInfo: {
    fieldsetTitle: string;
    photo: {
      title: string;
      info: string;
      uploadButton: string;
      clearButton: string;
    };
    firstName: {
      label: string;
      placeholder: string;
    };
    lastName: {
      label: string;
      placeholder: string;
    };
    phone: {
      label: string;
      placeholder: string;
    };
    email: {
      label: string;
      placeholder: string;
    };
    position: {
      label: string;
      placeholder: string;
    };
    socialMedia: {
      label: string;
      mediaPlaceholder: string;
      linkPlaceholder: string;
      addLinkButton: string;
    };
  };
  experience: {
    fieldsetTitle: string;
    company: {
      companyName: {
        label: string;
        placeholder: string;
      };
      position: {
        label: string;
        placeholder: string;
      };
    };
    project: {
      addProjectTitle: string;
      editProjectTitle: string;
      editButtonText: string;
      projectName: {
        label: string;
        placeholder: string;
      };
      technologies: {
        label: string;
        placeholder: string;
      };
      problem: {
        label: string;
        placeholder: string;
      };
      solution: {
        label: string;
        placeholder: string;
      };
      addProjectButton: string;
      saveProjectButton: string;
      cancel: string;
      addAnotherProjectButton: string;
    };
    addCompanyButton: string;
  };
  education: {
    fieldsetTitle: string;
    place: {
      label: string;
      placeholder: string;
    };
    degree: {
      label: string;
      placeholder: string;
    };
    specialization: {
      label: string;
      placeholder: string;
    };
    addEducationButton: string;
  };
  about: {
    fieldsetTitle: string;
    label: string;
    placeholder: string;
  };
  languages: {
    fieldsetTitle: string;
    language: {
      label: string;
      placeholder: string;
    };
    level: {
      placeholder: string;
    };
    addLanguageButton: string;
  };
  skills: {
    fieldsetTitle: string;
    label: string;
    placeholder: string;
  };
  tools: {
    fieldsetTitle: string;
    label: string;
    placeholder: string;
  };
  date: {
    startLabel: string;
    endLabel: string;
    year: string;
    month: string;
  };
}

export const RESUME_FIELDS_RU: IResumeFields = {
  personalInfo: {
    fieldsetTitle: `${i18n.t('resume.form.personalInfo.fieldsetTitle')}`,
    photo: {
      title: `${i18n.t('resume.form.personalInfo.photo.title')}`,
      info: `${i18n.t('resume.form.personalInfo.photo.info')}`,
      uploadButton: `${i18n.t('resume.form.personalInfo.photo.uploadButton')}`,
      clearButton: `${i18n.t('resume.form.personalInfo.photo.clearButton')}`,
    },
    firstName: {
      label: `${i18n.t('resume.form.personalInfo.firstName.label')}`,
      placeholder: `${i18n.t('resume.form.personalInfo.firstName.placeholder')}`,
    },
    lastName: {
      label: `${i18n.t('resume.form.personalInfo.lastName.label')}`,
      placeholder: `${i18n.t('resume.form.personalInfo.lastName.placeholder')}`,
    },
    phone: {
      label: `${i18n.t('resume.form.personalInfo.phone.label')}`,
      placeholder: `${i18n.t('resume.form.personalInfo.phone.placeholder')}`,
    },
    email: {
      label: `${i18n.t('resume.form.personalInfo.email.label')}`,
      placeholder: `${i18n.t('resume.form.personalInfo.email.placeholder')}`,
    },
    position: {
      label: `${i18n.t('resume.form.personalInfo.position.label')}`,
      placeholder: `${i18n.t('resume.form.personalInfo.position.placeholder')}`,
    },
    socialMedia: {
      label: `${i18n.t('resume.form.personalInfo.socialMedia.label')}`,
      mediaPlaceholder: `${i18n.t('resume.form.personalInfo.socialMedia.mediaPlaceholder')}`,
      linkPlaceholder: `${i18n.t('resume.form.personalInfo.socialMedia.linkPlaceholder')}`,
      addLinkButton: `${i18n.t('resume.form.personalInfo.socialMedia.addLinkButton')}`,
    },
  },
  experience: {
    fieldsetTitle: `${i18n.t('resume.form.experience.fieldsetTitle')}`,
    company: {
      companyName: {
        label: `${i18n.t('resume.form.experience.company.companyName.label')}`,
        placeholder: `${i18n.t('resume.form.experience.company.companyName.placeholder')}`,
      },
      position: {
        label: `${i18n.t('resume.form.experience.company.position.label')}`,
        placeholder: `${i18n.t('resume.form.experience.company.position.placeholder')}`,
      },
    },
    project: {
      addProjectTitle: `${i18n.t('resume.form.experience.project.addProjectTitle')}`,
      editProjectTitle: `${i18n.t('resume.form.experience.project.editProjectTitle')}`,
      editButtonText: `${i18n.t('resume.form.experience.project.editButtonText')}`,
      projectName: {
        label: `${i18n.t('resume.form.experience.project.projectName.label')}`,
        placeholder: `${i18n.t('resume.form.experience.project.projectName.placeholder')}`,
      },
      technologies: {
        label: `${i18n.t('resume.form.experience.project.technologies.label')}`,
        placeholder: `${i18n.t('resume.form.experience.project.technologies.placeholder')}`,
      },
      problem: {
        label: `${i18n.t('resume.form.experience.project.problem.label')}`,
        placeholder: `${i18n.t('resume.form.experience.project.problem.placeholder')}`,
      },
      solution: {
        label: `${i18n.t('resume.form.experience.project.solution.label')}`,
        placeholder: `${i18n.t('resume.form.experience.project.solution.placeholder')}`,
      },
      addProjectButton: `${i18n.t('resume.form.experience.project.addProjectButton')}`,
      saveProjectButton: `${i18n.t('resume.form.experience.project.saveProjectButton')}`,
      cancel: `${i18n.t('resume.form.experience.project.cancel')}`,
      addAnotherProjectButton: `${i18n.t(
        'resume.form.experience.project.addAnotherProjectButton',
      )}`,
    },
    addCompanyButton: `${i18n.t('resume.form.experience.addCompanyButton')}`,
  },
  education: {
    fieldsetTitle: `${i18n.t('resume.form.education.fieldsetTitle')}`,
    place: {
      label: `${i18n.t('resume.form.education.place.label')}`,
      placeholder: `${i18n.t('resume.form.education.place.placeholder')}`,
    },
    degree: {
      label: `${i18n.t('resume.form.education.degree.label')}`,
      placeholder: `${i18n.t('resume.form.education.degree.placeholder')}`,
    },
    specialization: {
      label: `${i18n.t('resume.form.education.specialization.label')}`,
      placeholder: `${i18n.t('resume.form.education.specialization.placeholder')}`,
    },
    addEducationButton: `${i18n.t('resume.form.education.addEducationButton')}`,
  },
  about: {
    fieldsetTitle: `${i18n.t('resume.form.about.fieldsetTitle')}`,
    label: `${i18n.t('resume.form.about.label')}`,
    placeholder: `${i18n.t('resume.form.about.placeholder')}`,
  },
  languages: {
    fieldsetTitle: `${i18n.t('resume.form.languages.fieldsetTitle')}`,
    language: {
      label: `${i18n.t('resume.form.languages.language.label')}`,
      placeholder: `${i18n.t('resume.form.languages.language.placeholder')}`,
    },
    level: {
      placeholder: `${i18n.t('resume.form.languages.level.placeholder')}`,
    },
    addLanguageButton: `${i18n.t('resume.form.languages.addLanguageButton')}`,
  },
  skills: {
    fieldsetTitle: `${i18n.t('resume.form.skills.fieldsetTitle')}`,
    label: `${i18n.t('resume.form.skills.label')}`,
    placeholder: `${i18n.t('resume.form.skills.placeholder')}`,
  },
  tools: {
    fieldsetTitle: `${i18n.t('resume.form.tools.fieldsetTitle')}`,
    label: `${i18n.t('resume.form.tools.label')}`,
    placeholder: `${i18n.t('resume.form.tools.placeholder')}`,
  },
  date: {
    startLabel: `${i18n.t('resume.form.date.startLabel')}`,
    endLabel: `${i18n.t('resume.form.date.endLabel')}`,
    year: `${i18n.t('resume.form.date.year')}`,
    month: `${i18n.t('resume.form.date.month')}`,
  },
};

export const RESUME_FIELDS_EN: IResumeFields = {
  personalInfo: {
    fieldsetTitle: 'Personal information',
    photo: {
      title: 'Photo',
      info: 'Format: up to 1240 x 1240px, up to 5mb, png or jpeg.',
      uploadButton: 'Upload',
      clearButton: 'Clear',
    },
    firstName: {
      label: 'First name',
      placeholder: 'First name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    phone: {
      label: 'Phone',
      placeholder: 'Phone',
    },
    email: {
      label: 'Email',
      placeholder: 'Email',
    },
    position: {
      label: 'Title',
      placeholder: 'Title',
    },
    socialMedia: {
      label: 'Links and social media',
      mediaPlaceholder: 'Select social media',
      linkPlaceholder: 'Profile link',
      addLinkButton: 'Add link',
    },
  },
  experience: {
    fieldsetTitle: 'Experience',
    company: {
      companyName: {
        label: 'Company',
        placeholder: 'Company',
      },
      position: {
        label: 'Title',
        placeholder: 'Title',
      },
    },
    project: {
      addProjectTitle: 'Add project',
      editProjectTitle: 'Edit project',
      editButtonText: 'Edit',
      projectName: {
        label: 'Project name',
        placeholder: 'Project name',
      },
      technologies: {
        label: 'Technologies',
        placeholder: 'Write technologies (up to 10)',
      },
      problem: {
        label: 'Task',
        placeholder: 'Write about a project task... (up to 1,500 symbols)',
      },
      solution: {
        label: 'Result',
        placeholder: 'Project link',
      },
      addProjectButton: 'Add project',
      saveProjectButton: 'Save project',
      cancel: 'Cancel',
      addAnotherProjectButton: 'Add project',
    },
    addCompanyButton: 'Add experience',
  },
  education: {
    fieldsetTitle: 'Education',
    place: {
      label: 'School',
      placeholder: 'Ex: BSU',
    },
    degree: {
      label: 'Degree',
      placeholder: 'Ex: Bachelor’s',
    },
    specialization: {
      label: 'Field of study',
      placeholder: 'Ex: Business',
    },
    addEducationButton: 'Add education',
  },
  about: {
    fieldsetTitle: 'About me',
    label: 'Description',
    placeholder: 'Write about your abilities, skills, interests… (up to 1,500 symbols)',
  },
  languages: {
    fieldsetTitle: 'Languages',
    language: {
      label: 'Language',
      placeholder: 'Language',
    },
    level: {
      placeholder: 'Proficiency',
    },
    addLanguageButton: 'Add language',
  },
  skills: {
    fieldsetTitle: 'Skills',
    label: 'Key skills',
    placeholder: 'Key skills',
  },
  tools: {
    fieldsetTitle: 'Tools',
    label: 'Used tools',
    placeholder: 'Used tools',
  },
  date: {
    startLabel: 'Start date',
    endLabel: 'End date',
    year: 'Year',
    month: 'Month',
  },
};
