import { LessonType } from 'types';
import { LessonForLmsQuery } from 'core';
import { isBeforeNow, parseLessonDay, TIME } from 'services/DateService';
import { LessonTypes } from 'components/types/common';

export const transformLesson = (data: LessonForLmsQuery | undefined): LessonType => {
  if (data?.lessonForLms) {
    const lessonType = data.lessonForLms.__typename;

    const time = `${parseLessonDay(data.lessonForLms.date)}, ${TIME[data.lessonForLms.startTime]}-${
      TIME[data.lessonForLms.endTime]
    }`;
    const isEnded = isBeforeNow(data.lessonForLms.date);

    return {
      id: data.lessonForLms.id,
      name: data.lessonForLms.name,
      index: data.lessonForLms.index || 0,
      isEnded,
      isActive: !isEnded,
      needToFinish:
        lessonType === LessonTypes.GroupLessonForLMSType ? data.lessonForLms?.needToFinish : null,
      time,
      date: data.lessonForLms.date,
      finished: data.lessonForLms.finished,
      lmsDescription: data.lessonForLms.lmsDescription || '',
      comment: data.lessonForLms.comment || '',
      video: data.lessonForLms.video,
      taskTypes:
        lessonType === LessonTypes.GroupLessonForLMSType ? data.lessonForLms.taskTypes : [],
      hasStudentMarks:
        lessonType === LessonTypes.StudentLessonType ? data.lessonForLms.hasStudentMarks : null,
      hasTeacherAttachment:
        lessonType === LessonTypes.GroupLessonForLMSType ||
        lessonType === LessonTypes.MethodistLessonType
          ? data.lessonForLms.hasTeacherAttachment
          : false,
      hasStudentAttachment:
        lessonType === LessonTypes.StudentLessonType ||
        lessonType === LessonTypes.MethodistLessonType
          ? data.lessonForLms.hasStudentAttachment
          : false,
    };
  }

  return {
    id: '',
    name: '',
    index: 0,
    isEnded: false,
    time: '',
    date: '',
    finished: false,
    lmsDescription: '',
    comment: '',
    taskTypes: [],
  };
};
