import React, { memo, useEffect } from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';
import { Colors } from 'context';
import { desktop } from 'services/ui';

export interface IModal {
  isShowing: boolean;
  modalHeight?: string;
  hasMargin?: boolean;
  align?: string;
  children: React.ReactNode;
}

export const Modal = memo(
  ({ isShowing, modalHeight, hasMargin = false, align, children }: IModal) => {
    useEffect(() => {
      if (isShowing) {
        return document.body.classList.add('no--scroll');
      }
      return document.body.classList.remove('no--scroll');
    }, [isShowing]);

    return isShowing
      ? ReactDOM.createPortal(
          <ModalWrapper>
            <Inner align={align}>
              <ModalContent height={modalHeight} hasMargin={hasMargin}>
                {children}
              </ModalContent>
            </Inner>
          </ModalWrapper>,
          document.body,
        )
      : null;
  },
);

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 700;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(7, 7, 7, 0.6);
  overflow-x: hidden;
  overflow-y: auto;
`;

const Inner = styled.div<{ align?: string }>`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: ${({ align }) => align || 'center'};
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
`;

const ModalContent = styled.div<{ height?: string; hasMargin?: boolean }>`
  z-index: 100;
  width: 90%;
  height: ${({ height }) => height || 'max-content'};
  margin: ${({ hasMargin }) => hasMargin && '0 0 60px 0'};
  border-radius: 16px;
  border: 1px solid #e7ebef;
  background-color: ${Colors.white};

  ${desktop} {
    width: 1110px;
    border-radius: 24px;
  }
`;
