import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import fontService, { TextType } from 'services/FontService';

import { Toaster } from 'components/molecules';
import { ToastTypes } from 'atoms';
import { authState, useRefreshToken, useToaster } from 'core';
import { Splash } from 'pages';
import { useReactiveVar } from '@apollo/client';
import { AppRouter } from './router';

const App = () => {
  const { t } = useTranslation();
  const { addToast, toasts } = useToaster();

  const onConnectionChange = useCallback(() => {
    const isOnline = navigator.onLine;

    if (isOnline) {
      addToast('internet_connection', {
        text: `${t('connectionEstablished')}`,
        type: ToastTypes.warning,
      });
      return;
    }

    addToast('internet_connection', {
      text: `${t('connectionUnavailable')}`,
      type: ToastTypes.warning,
    });
  }, [addToast, t]);

  useEffect(() => {
    window.addEventListener('offline', onConnectionChange);
    window.addEventListener('online', onConnectionChange);

    return () => {
      window.removeEventListener('offline', onConnectionChange);
      window.removeEventListener('online', onConnectionChange);
    };
  }, [onConnectionChange]);

  const { refresh_token, isTokenRefreshed, isLoggedIn } = useReactiveVar(authState);
  const { updateTokens, loading } = useRefreshToken();

  useEffect(() => {
    if (refresh_token && !isTokenRefreshed) {
      updateTokens(refresh_token);
    }
  }, [refresh_token, isTokenRefreshed, updateTokens]);

  if ((loading || !isTokenRefreshed) && isLoggedIn) {
    return <Splash />;
  }

  return (
    <Wrapper>
      <AppRouter />
      <Toaster toasts={toasts} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${fontService.text()};

  caption {
    ${fontService.text({ type: TextType.caption })};
  }

  body {
    margin: 0;
  }
`;

export default App;
