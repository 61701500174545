import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { Icon, IconNames } from 'atoms';

interface ILoadingIndicator {
  icon?: IconNames.Loading | IconNames.LoadingGreen;
  className?: string;
}

export const LoadingIndicator = memo(
  ({ className, icon = IconNames.Loading }: ILoadingIndicator) => (
    <Wrapper className={className}>
      <IconStyled name={icon} />
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  height: 100%;
  width: 100%;
  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: rotating 0.75s linear 0s infinite normal none;
`;
