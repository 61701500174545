import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Icon, IconNames } from 'components/atoms';
import { Colors } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { mobile, tablet } from 'services/ui';
import { PATHS } from 'router/config';
import { AttachmentType } from 'components/types';

export interface IProps {
  title: string;
  id: string;
  type: AttachmentType;
  link: string;
  className?: string;
}

export const LessonAttachment = ({ title, id, type, link, className }: IProps) => {
  const { t } = useTranslation();
  const DownloadBtn = useMemo(() => {
    if (link.includes('.pdf')) {
      return (
        <PDFLink to={`${PATHS.attachments}/${type}/${id}`} target="_blank">
          <DownloadButton variant="outline">{t('lesson.attachment')}</DownloadButton>
        </PDFLink>
      );
    }

    return (
      <DownloadLink href={link} download>
        <DownloadButton variant="outline">{t('lesson.attachment')}</DownloadButton>
      </DownloadLink>
    );
  }, [link, id, type, t]);
  return (
    <Wrapper className={className}>
      <AttachmentIconWrapper>
        <AttachmentIcon name={IconNames.Note} />
      </AttachmentIconWrapper>
      <AttachmentTitle>{title}</AttachmentTitle>
      {DownloadBtn}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${tablet} {
    flex-direction: row;
  }
`;

const AttachmentIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 78px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${Colors.black};

  ${mobile} {
    margin-bottom: 10px;
  }
`;

const AttachmentIcon = styled(Icon)`
  height: 32px;
  width: 32px;
`;

const AttachmentTitle = styled.p`
  ${fontService.getFont({ size: 16, weight: FontWeight.medium, lineHeight: 105 })};
  margin-bottom: 24px;
  text-align: center;

  ${tablet} {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 0;
  }
`;

const PDFLink = styled(Link)`
  display: block;
  margin: 0;
  width: 100%;

  ${tablet} {
    width: unset;
    margin-right: 12px;
    margin-left: auto;
  }
`;

const DownloadLink = styled.a`
  display: block;
  margin: 0;
  width: 100%;

  ${tablet} {
    margin-right: 12px;
    margin-left: auto;
  }
`;

const DownloadButton = styled(Button)`
  min-height: 48px;
  width: 100%;
  ${fontService.getFont({ size: 16, weight: FontWeight.medium, lineHeight: 150 })};
  border-radius: 8px;

  ${tablet} {
    min-height: 32px;
    max-width: 154px;
    ${fontService.getFont({ size: 14, weight: FontWeight.medium, lineHeight: 150 })};
    border-radius: 4px;
    margin-left: auto;
  }
`;
