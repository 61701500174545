import * as React from 'react';
import { memo } from 'react';

import ContentLoader from 'react-content-loader';
import { useWindowSize } from 'services/hooks';
import { CLIENT_WIDTH } from 'services/ui';

export const JournalPreloader = memo((props) => {
  const { width } = useWindowSize();
  return width && width >= CLIENT_WIDTH.DESKTOP ? (
    <ContentLoader
      speed={2}
      width={700}
      height={666}
      viewBox="0 0 700 666"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <path d="M0 0H700V44H0V0Z" fill="#F4F5F7" />
      <path d="M0 60H75V84H0V60Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M0 642H320V666H0V642Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M0 359H640V380H0V359Z" fill="#F4F5F7" />
      <path d="M0 396H700V414H0V396Z" fill="#F4F5F7" />
      <path d="M0 420H700V438H0V420Z" fill="#F4F5F7" />
      <path d="M0 444H700V462H0V444Z" fill="#F4F5F7" />
      <path d="M0 478H700V496H0V478Z" fill="#F4F5F7" />
      <path d="M0 502H700V520H0V502Z" fill="#F4F5F7" />
      <path d="M0 526H700V544H0V526Z" fill="#F4F5F7" />
      <path d="M0 133H230V163H0V133Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M0 604H280V634H0V604Z" fill="#F4F5F7" />
      <path d="M103 60H311V84H103V60Z" fill="#F4F5F7" />
      <path d="M0 179H269V197H0V179Z" fill="#F4F5F7" />
      <path d="M0 205H230V223H0V205Z" fill="#F4F5F7" />
      <path d="M0 231H269V249H0V231Z" fill="#F4F5F7" />
      <path d="M0 257H230V275H0V257Z" fill="#F4F5F7" />
      <path d="M0 283H269V301H0V283Z" fill="#F4F5F7" />
      <path d="M0 309H230V327H0V309Z" fill="#F4F5F7" />
      <rect y="96" width="700" height="1" fill="#F4F5F7" />
    </ContentLoader>
  ) : width && width >= CLIENT_WIDTH.TABLET ? (
    <ContentLoader
      speed={2}
      width="100%"
      height={650}
      viewBox="0 0 100% 650"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <path d="M0 0H480V36H0V0Z" fill="#F4F5F7" />
      <path d="M0.173828 60H72.1738V84H0.173828V60Z" fill="#F4F5F7" />
      <path d="M100.174 60H300.174V84H100.174V60Z" fill="#F4F5F7" />
      <path d="M328.174 60H400.174V84H328.174V60Z" fill="#F4F5F7" />
      <path d="M428.174 60H588.174V84H428.174V60Z" fill="#F4F5F7" />
      <rect y="95" width="664" height="1" fill="#F4F5F7" />
      <path d="M0 128H230V158H0V128Z" fill="#F4F5F7" />
      <path d="M0 174H269V192H0V174Z" fill="#F4F5F7" />
      <path d="M0 200H230V218H0V200Z" fill="#F4F5F7" />
      <path d="M0 226H269V244H0V226Z" fill="#F4F5F7" />
      <path d="M0 252H230V270H0V252Z" fill="#F4F5F7" />
      <path d="M0 278H269V296H0V278Z" fill="#F4F5F7" />
      <path d="M0 304H230V322H0V304Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M0 362H480V383H0V362Z" fill="#F4F5F7" />
      <path d="M0 399H664V417H0V399Z" fill="#F4F5F7" />
      <path d="M0 423H664V441H0V423Z" fill="#F4F5F7" />
      <path d="M0 447H664V465H0V447Z" fill="#F4F5F7" />
      <path d="M0 481H664V499H0V481Z" fill="#F4F5F7" />
      <path d="M0 505H664V523H0V505Z" fill="#F4F5F7" />
      <path d="M0 529H664V547H0V529Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M0 625H320V649H0V625Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M0 587H280V617H0V587Z" fill="#F4F5F7" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width="100%"
      height={822}
      viewBox="0 0 100% 822"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <path d="M16 0H291V24H16V0Z" fill="#F4F5F7" />
      <path d="M16 40H76V60H16V40Z" fill="#F4F5F7" />
      <path d="M92 40H152V60H92V40Z" fill="#F4F5F7" />
      <path d="M168 40H228V60H168V40Z" fill="#F4F5F7" />
      <path d="M244 40H304V60H244V40Z" fill="#F4F5F7" />
      <rect x="16" y="67" width="335" height="1" fill="#CFD6DC" />
      <path
        d="M16 100C16 93.3726 21.3726 88 28 88H339C345.627 88 351 93.3726 351 100V296C351 302.627 345.627 308 339 308H28C21.3726 308 16 302.627 16 296V100Z"
        fill="#F4F5F7"
      />
      <path opacity="0.75" d="M16 332H246V362H16V332Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 378H285V396H16V378Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 404H246V422H16V404Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 430H285V448H16V430Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 456H246V474H16V456Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 482H285V500H16V482Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 508H246V526H16V508Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 542H246V572H16V542Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 588H285V606H16V588Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 614H246V632H16V614Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 640H285V658H16V640Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 666H246V684H16V666Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 692H285V710H16V692Z" fill="#F4F5F7" />
      <path opacity="0.75" d="M16 718H246V736H16V718Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M16 798H336V822H16V798Z" fill="#F4F5F7" />
      <path opacity="0.5" d="M16 760H296V790H16V760Z" fill="#F4F5F7" />
    </ContentLoader>
  );
});
