import { ICourseCard } from 'components/molecules';
import { parseDate } from 'services/DateService';

import { GroupEdge } from 'core/graphql';

export const transformCoursesNode = (edge: GroupEdge): ICourseCard | undefined => {
  const { node } = edge;
  if (node) {
    return {
      id: node.id,
      courseId: node.course.id,
      courseName: node.course.name,
      groupName: node.name,
      studentsCount: node.studentsCount || 0,
      courseImageURL: node.course.courseIcon || '',
      activeLessonNumber: node.passedLessonsCount || 0,
      lessonsCount: node.lessonsCount || 0,
      nextLesson: parseDate(node.datetimeNextLesson),
      educationForm: node.course.educationForm || '',
      statusFinished: node.statusFinished || false,
    };
  }
};
