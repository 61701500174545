import { useMemo } from 'react';

import { useGroupLessonAttendanceForLmsQuery } from 'core';
import { AttendanceType } from 'types';
import { transformAttendances } from './transform';

type attendanceType = {
  attendance: AttendanceType[];
  loading?: boolean;
  error?: string;
};

export const useGetLessonAttendance = (groupLessonId: string): attendanceType => {
  const { data, loading, error } = useGroupLessonAttendanceForLmsQuery({
    variables: { groupLessonId },
  });

  const attendance = useMemo(() => {
    if (data?.groupLessonAttendanceForLms) {
      return transformAttendances(data.groupLessonAttendanceForLms);
    }

    return [];
  }, [data?.groupLessonAttendanceForLms]);

  return {
    attendance,
    loading,
    error: error?.message,
  };
};
