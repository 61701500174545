import React, { memo, useMemo } from 'react';

import styled, { css } from 'styled-components';
import { Icon, IconNames } from 'atoms';
import { HomeworkGradesType } from 'types';
import fontService, { FontWeight } from 'services/FontService';
import { Colors, GradeNames } from 'context';
import { getTaskGradesTranslated } from 'services/TranslationService';
import { tablet } from 'services/ui';

export enum GradeSize {
  sm = 'sm',
  lg = 'lg',
}

interface IGradeStyles {
  width?: number;
  height?: number;
}

export interface IGrade extends IGradeStyles {
  gradeType: HomeworkGradesType;
  size?: GradeSize;
  className?: string;
}

export const Grade = memo(
  ({ gradeType, width, height, size = GradeSize.sm, className }: IGrade) => {
    const iconName: IconNames = useMemo(() => {
      switch (gradeType) {
        case HomeworkGradesType.DONE:
          return IconNames.Completed;
        case HomeworkGradesType.CONTAINS_ERRORS:
          return IconNames.Waiting;
        case HomeworkGradesType.NOT_DONE:
          return IconNames.Rejected;
        default:
          return IconNames.Waiting;
      }
    }, [gradeType]);
    return (
      <Wrapper className={className}>
        <Icon name={iconName} width={width || 12} height={height || 12} />
        <Text gradeType={gradeType} size={size}>
          {getTaskGradesTranslated(GradeNames[gradeType])}
        </Text>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  min-width: 128px;
  display: flex;
  align-items: center;
  padding: 4px 8px;

  ${tablet} {
    min-width: 140px;
  }
`;

const Text = styled.p<IGrade>`
  margin-left: 6px;
  ${({ size }) => {
    if (size === GradeSize.lg) {
      return css`
        margin-left: 4px;
        letter-spacing: -0.02em;
        ${fontService.text({ size: 14, weight: FontWeight.bold, lineHeight: 142 })};

        ${tablet} {
          margin-left: 6px;
          letter-spacing: 0;
          ${fontService.text({ size: 24, weight: FontWeight.bold, lineHeight: 125 })}
        }
      `;
    }
    if (size === GradeSize.sm) {
      return fontService.text({ size: 12, weight: FontWeight.regular, lineHeight: 150 });
    }
  }};
  ${({ gradeType }) => {
    switch (gradeType) {
      case HomeworkGradesType.DONE:
        return css`
          color: ${Colors.marker_green};
        `;
      case HomeworkGradesType.CONTAINS_ERRORS:
        return css`
          color: ${Colors.gray_dark};
        `;
      case HomeworkGradesType.NOT_DONE:
        return css`
          color: ${Colors.signal_red};
        `;
      default:
        return css``;
    }
  }};
`;
