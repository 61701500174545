import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { HomeworkGradesType, StudentMarks } from 'types';
import { useStudentMarksQuery } from 'core';
import { parseDateFromDatePicker } from 'services/DateService';
import { TaskTypes } from 'context';

type StudentMarksType = {
  marks?: StudentMarks[];
  loading?: boolean;
  error?: string;
};

export const useGetStudentMarks = (lessonsId: string): StudentMarksType => {
  const { data, loading, error } = useStudentMarksQuery({ variables: { lessonsId } });
  const marks = useMemo(() => {
    if (data?.studentMarks?.edges) {
      return data.studentMarks.edges.reduce((marksArray: StudentMarks[], edge) => {
        if (edge?.node?.taskType) {
          return [
            ...marksArray,
            {
              mark: Number(edge?.node?.mark) >= 0 ? edge?.node?.mark : null,
              hwMark: (edge?.node?.hwMark && HomeworkGradesType[edge.node.hwMark]) || null,
              date: parseDateFromDatePicker(DateTime.fromISO(edge?.node?.date).toJSDate()),
              taskType: TaskTypes[edge.node.taskType.toLowerCase() as keyof typeof TaskTypes],
            },
          ];
        }

        return marksArray;
      }, []);
    }

    return [];
  }, [data?.studentMarks?.edges]);

  return {
    marks,
    loading,
    error: error?.message,
  };
};
