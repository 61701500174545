import React, { useEffect } from 'react';

import { useGetAllAttachments, useGoToErrorPage } from 'core';
import { AttachmentType } from 'types';
import { JournalPreloader } from 'atoms';
import { NetworkErrors } from 'context';
import { LessonAttachmentsComponent } from './LessonAttachments';

interface ILessonAttachments {
  lessonId: string;
  type: AttachmentType;
  showAttachments: boolean;
}

export const LessonAttachments = ({ lessonId, type, showAttachments }: ILessonAttachments) => {
  const goToErrorPage = useGoToErrorPage();
  const { attachments, error, loading } = useGetAllAttachments(lessonId, type);

  useEffect(() => {
    if (error) {
      console.log({ attachmentsError: error });
      goToErrorPage();
    }
  }, [error, goToErrorPage]);

  useEffect(() => {
    if (!showAttachments) {
      goToErrorPage(NetworkErrors.pageIsNotAvailable);
    }
  }, [goToErrorPage, showAttachments]);

  return !loading && attachments ? (
    <LessonAttachmentsComponent attachments={attachments} type={type} />
  ) : (
    <JournalPreloader />
  );
};
