export enum ColorsTMS {
  main = '#FDD32A',
  main_light = '#FFE880',
  main_dark = '#F0BE0C',
  system_gray = '#CFD6DC',
  white = '#FFFFFF',
  bg_gray = '#F4F5F7',
  additional_gray = '#E9EAEE',
  gray = '#ACB1B6',
  gray_dark = '#76777C',
  black = '#070707',
  btn_text = '#070707',
  btn_hover_text = '#070707',
  signal_red = '#E82B27',
  signal_green = '#37AB34',
  marker_green = '#0E6245',
}

export enum ColorsLearnIT {
  main = '#FFDF17',
  main_light = '#FFE880',
  main_dark = '#F0C015',
  system_gray = '#CFD6DC',
  white = '#FFFFFF',
  bg_gray = '#F4F5F7',
  additional_gray = '#E9EAEE',
  gray = '#ACB1B6',
  gray_dark = '#76777C',
  black = '#222222',
  btn_text = '#222222',
  btn_hover_text = '#222222',
  signal_red = '#E82B27',
  signal_green = '#37AB34',
  marker_green = '#0E6245',
}
