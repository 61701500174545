import React, { memo } from 'react';

import styled from 'styled-components';
import { desktop } from 'services/ui';

import { Select } from 'atoms';
import { SelectInput } from 'atoms/Select/Select';

import { ISelectOptions, Languages } from 'context';
import { getMonthsOptions, getYearsOptions } from './utils';

export interface IInputYearMonth {
  language: Languages;
  value: {
    year: ISelectOptions;
    month: ISelectOptions;
  };
  yearPlaceholder: string;
  monthsPlaceholder: string;
  onSelectYear: (option: ISelectOptions) => void;
  onSelectMonth: (option: ISelectOptions) => void;
  isYearDisabled: boolean;
  isMonthDisabled: boolean;
}

export const InputYearMonthComponent = memo(
  ({
    language,
    value,
    onSelectMonth,
    onSelectYear,
    yearPlaceholder,
    monthsPlaceholder,
    isYearDisabled,
    isMonthDisabled,
  }: IInputYearMonth) => (
    <Wrapper>
      <StyledSelectYear
        preselectedOption={value.year}
        options={getYearsOptions(yearPlaceholder)}
        placeholder={yearPlaceholder}
        disabled={isYearDisabled}
        onSelect={onSelectYear}
      />
      <StyledSelectMonth
        preselectedOption={value.month}
        options={getMonthsOptions(language, monthsPlaceholder)}
        placeholder={monthsPlaceholder}
        disabled={isMonthDisabled}
        onSelect={onSelectMonth}
      />
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
`;

const StyledSelectYear = styled(Select)`
  ${SelectInput} {
    width: 84px;
    border-radius: 8px 0 0 8px;
    z-index: 2;
  }
`;

const StyledSelectMonth = styled(Select)`
  flex-grow: 1;

  ${desktop} {
    flex-grow: 0;
  }

  ${SelectInput} {
    border-radius: 0 8px 8px 0;
    margin-left: -1px;

    ${desktop} {
      width: 131px;
    }
  }
`;
