import React, { memo, useCallback } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import { IExperienceProject, IResumeFields } from 'context';
import { AddProjectPopupComponent } from './AddProjectPopup';

interface IAddProjectPopupContainer {
  fields: IResumeFields;
  isShowing: boolean;
  onClose: () => void;
  onAddProject: (project: IExperienceProject) => void;
}

export const AddProjectPopup = memo(
  ({ fields, isShowing, onAddProject, onClose }: IAddProjectPopupContainer) => {
    const { t } = useTranslation();
    const { values, errors, handleSubmit, setFieldValue, resetForm } =
      useFormik<IExperienceProject>({
        initialValues: {
          id: nanoid(),
          name: '',
          problem: '',
          solution: '',
          technologies: [],
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object().shape({
          name: Yup.string().required(`${t('input.validation.requiredField')}`),
        }),
        onSubmit: (project, actions) => {
          onAddProject(project);
          actions.resetForm({
            values: {
              id: nanoid(),
              name: '',
              problem: '',
              solution: '',
              technologies: [],
            },
          });
        },
      });

    const onCloseClick = useCallback(() => {
      resetForm();
      onClose();
    }, [resetForm, onClose]);

    return (
      <AddProjectPopupComponent
        fields={fields}
        project={values}
        isEmptyProjectName={Boolean(errors.name)}
        errorMessage={errors.name}
        onInputChange={setFieldValue}
        isShowing={isShowing}
        onClose={onCloseClick}
        onAddProject={handleSubmit}
      />
    );
  },
);
