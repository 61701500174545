import { useEffect, useCallback, useMemo } from 'react';
import { authState, useDemoLoginMutation } from 'core';
import { saveToken } from 'services/AuthService';
import { UserRole } from 'context';

type DemoLoginType = [
  () => void,
  {
    success: boolean;
    loading: boolean;
    error: boolean;
  },
];

export const useDemoLogin = (): DemoLoginType => {
  const [demoLoginMutation, { data, loading, error }] = useDemoLoginMutation({});

  const success = useMemo(
    () => Boolean(data?.demoCustomLogin?.token),
    [data?.demoCustomLogin?.token],
  );

  useEffect(() => {
    if (success && data?.demoCustomLogin?.refreshToken && data.demoCustomLogin.token) {
      authState({
        isLoggedIn: true,
        access_token: data.demoCustomLogin.token,
        refresh_token: data.demoCustomLogin.refreshToken,
        isTokenRefreshed: true,
        active_role: UserRole.STUDENT,
        isDemoUser: true,
      });
      saveToken(data.demoCustomLogin.refreshToken);
    }
  }, [data, success]);

  const demoLogin = useCallback(
    () =>
      demoLoginMutation({
        variables: {
          email: process.env.REACT_APP_DEMO_USER || '',
          scope: process.env.REACT_APP_SCOPE || 'LMS',
        },
      }),
    [demoLoginMutation],
  );

  return [demoLogin, { success, loading, error: error || data?.demoCustomLogin?.errors }];
};
