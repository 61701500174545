import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSendAuthCodeMutation } from 'core';
import { getNextCodeTime, setNextCodeTime as setNextCode } from 'services/AuthService';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

type emailErrorType = { email?: string; ipUsed?: boolean; isServiceUnavailable?: boolean } | null;

type sendAuthCodeType = [
  (email: string) => void,
  {
    success: boolean;
    loading: boolean;
    emailError: emailErrorType;
    time: string;
    isOutOfTime: boolean;
    waitingTimeForNextCode: number | null;
  },
];

const getTimerMessage = (t: TFunction): { [key: string]: string } => ({
  5: `${t('login.timer.5min')}`,
  4: `${t('login.timer.4min')}`,
  3: `${t('login.timer.3min')}`,
  2: `${t('login.timer.2min')}`,
  1: `${t('login.timer.1min')}`,
});

const FIVE_MINUTES = 300000;

export const useSendAuthCode = (): sendAuthCodeType => {
  const { t } = useTranslation();
  const [sendAuthCodeMutation, { data, loading }] = useSendAuthCodeMutation({});
  const [emailError, setEmailError] = useState<emailErrorType>(null);
  const [time, changeTime] = useState('');
  const [isOutOfTime, setOutOfTime] = useState(false);
  const [nextCodeTime, setNextCodeTime] = useState(getNextCodeTime());
  const [waitingTimeForNextCode, setWaitingForNextCode] = useState<number | null>(null);
  const success = useMemo(
    () => Boolean(data?.sendLmsAuthEmail?.success),
    [data?.sendLmsAuthEmail?.success],
  );

  const createdAt = useMemo(() => {
    if (data?.sendLmsAuthEmail?.createdAt) {
      return new Date(data?.sendLmsAuthEmail?.createdAt);
    }

    return null;
  }, [data?.sendLmsAuthEmail?.createdAt]);

  const sendAuthCode = useCallback(
    (email) =>
      sendAuthCodeMutation({ variables: { email, scope: process.env.REACT_APP_SCOPE || 'LMS' } }),
    [sendAuthCodeMutation],
  );

  const updateNextCodeTime = useCallback(() => {
    const MINUTE = 60000;
    const dateNow = new Date();
    setNextCodeTime(dateNow.getTime() + MINUTE);
    setNextCode(dateNow.getTime() + MINUTE);
  }, []);

  useEffect(() => {
    if (data?.sendLmsAuthEmail?.customErrors?.[0]?.code === 'invalid_email') {
      setEmailError({ email: `${t('login.validation.invalidEmail')}` });
    }

    if (data?.sendLmsAuthEmail?.customErrors?.[0]?.code === 'email_fail') {
      setEmailError({ isServiceUnavailable: true });
    }

    if (data?.sendLmsAuthEmail?.customErrors?.[0]?.code === 'ip_used') {
      setEmailError({ email: `${t('login.validation.ipUsed')}`, ipUsed: true });

      if (!nextCodeTime) {
        updateNextCodeTime();
      }
    }

    if (!data?.sendLmsAuthEmail?.customErrors) {
      setEmailError(null);
    }
  }, [data?.sendLmsAuthEmail?.customErrors, nextCodeTime, updateNextCodeTime, t]);

  useEffect(() => {
    setOutOfTime(false);

    const timer = setInterval(() => {
      if (createdAt) {
        const dateNow = new Date();
        const difference = Math.floor(
          (createdAt.getTime() + FIVE_MINUTES - dateNow.getTime()) / 1000,
        );
        const minutes = Math.floor(difference / 60);
        const seconds = difference % 60;

        if (minutes > 0) {
          changeTime(`${getTimerMessage(t)[minutes.toString()]} ${seconds} ${t('common.seconds')}`);
        }

        if (minutes === 0) {
          changeTime(`${seconds} ${t('common.seconds')}`);
        }

        if (difference < 1) {
          clearInterval(timer);
          setOutOfTime(true);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [createdAt, t]);

  useEffect(() => {
    if (success && !loading) {
      updateNextCodeTime();
    }

    if (loading) {
      setOutOfTime(false);
      changeTime('');
    }
  }, [loading, success, updateNextCodeTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (nextCodeTime) {
        const dateNow = new Date();
        const difference = Math.floor((nextCodeTime - dateNow.getTime()) / 1000);

        setWaitingForNextCode(difference);

        if (difference < 1) {
          clearInterval(timer);
        }
      }
    }, 1000);

    if (!nextCodeTime) {
      setWaitingForNextCode(0);
    }

    return () => clearInterval(timer);
  }, [nextCodeTime]);

  return [
    sendAuthCode,
    { success, time, isOutOfTime, waitingTimeForNextCode, loading, emailError },
  ];
};
