import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { ResumeDownloadQuery } from 'core/operations';
import { useUploadResume } from 'core/hooks';
import { Languages, SocialMediaType } from 'context';
import { IResumeFormValues } from 'organisms';

type DownloadResumeFileType = [
  (language: Languages) => void,
  {
    data: SocialMediaType[];
    loading?: boolean;
    error?: boolean;
  },
];

export const useDownloadResumeFile = (): DownloadResumeFileType => {
  const [downloadResume, { loading, error, data }] = useLazyQuery(ResumeDownloadQuery);

  const downloadResumeFile = useCallback(
    (language: Languages) =>
      downloadResume({
        variables: { language },
      }),
    [downloadResume],
  );

  return [
    downloadResumeFile,
    {
      data,
      loading,
      error: Boolean(error),
    },
  ];
};

interface IDownloadResumeArguments {
  language: Languages;
  values: IResumeFormValues;
  isDataDirty: boolean;
  closeModalCallback: () => void;
}

type DownloadResumeType = [
  (args: IDownloadResumeArguments) => void,
  {
    error?: boolean;
    loading?: boolean;
  },
];

export const useDownloadResume = (resumeLanguage: Languages): DownloadResumeType => {
  const [uploadResume, { error }] = useUploadResume(resumeLanguage);

  const [downloadResumeFile, { error: downloadFileError, loading: downloadFileLoading }] =
    useDownloadResumeFile();

  const downloadResume = useCallback(
    async ({ isDataDirty, values, language, closeModalCallback }: IDownloadResumeArguments) => {
      if (isDataDirty) {
        await uploadResume(values);
      }

      if (!error) {
        await downloadResumeFile(language);
        closeModalCallback();
      }
    },
    [uploadResume, downloadResumeFile, error],
  );

  return [
    downloadResume,
    {
      error: downloadFileError,
      loading: downloadFileLoading,
    },
  ];
};
