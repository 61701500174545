import { useMemo } from 'react';
import { useStudentsMarksQuery } from 'core/graphql';

import { TaskTypes } from 'context';
import { HomeworkGradesType, HomeworksType } from 'types';
import { DateTime } from 'luxon';

type StudentsMarksType = {
  marksDate: Date | null;
  studentsMarks?: HomeworksType[];
  loading?: boolean;
  error?: string;
};

export const useGetStudentsMarks = (lessonsId: string, taskType: TaskTypes): StudentsMarksType => {
  const { data, loading, error } = useStudentsMarksQuery({
    variables: {
      lessonsId,
      lessonIdForMarks: lessonsId,
      taskType,
    },
  });

  const studentsMarks = useMemo(() => {
    if (data?.studentsMarks) {
      const studentsMarksArray: HomeworksType[] = data.studentsMarks.edges.map((item) => {
        const marksNode = item?.node?.marks.edges[0]?.node;
        return {
          studentId: item?.node?.user?.student?.id || '',
          studentName: `${item?.node?.user?.firstName} ${item?.node?.user?.lastName}` || '',
          gradeType: (marksNode?.hwMark && HomeworkGradesType[marksNode.hwMark]) || null,
          mark: Number(marksNode?.mark) >= 0 ? marksNode?.mark : null,
        };
      });

      return studentsMarksArray.sort((studentA, studentB) =>
        studentA.studentName.localeCompare(studentB.studentName),
      );
    }

    return [];
  }, [data?.studentsMarks]);

  const parsedDate = useMemo(() => {
    const firstEdge = data?.studentsMarks?.edges[0];
    const date = firstEdge?.node?.marks?.edges[0]?.node?.date;
    if (date) {
      return DateTime.fromISO(date).toJSDate();
    }

    return null;
  }, [data?.studentsMarks]);

  return {
    marksDate: parsedDate,
    studentsMarks,
    loading,
    error: error?.message,
  };
};
