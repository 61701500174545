import * as React from 'react';
import { memo } from 'react';

import ContentLoader from 'react-content-loader';
import { useWindowSize } from 'services/hooks';
import { CLIENT_WIDTH } from 'services/ui';

export const FilterPreloader = memo((props) => {
  const { width } = useWindowSize();
  return width && width >= CLIENT_WIDTH.DESKTOP ? (
    <ContentLoader
      speed={2}
      width={460}
      height={76}
      viewBox="0 0 460 76"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <rect width="280" height="44" fill="#F4F5F7" />
      <rect y="52" width="460" height="24" fill="#F4F5F7" />
    </ContentLoader>
  ) : width && width >= CLIENT_WIDTH.TABLET ? (
    <ContentLoader
      speed={2}
      width={348}
      height={56}
      viewBox="0 0 348 56"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <rect width="206" height="36" fill="#F4F5F7" />
      <rect y="40" width="348" height="16" fill="#F4F5F7" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width={296}
      height={44}
      viewBox="0 0 296 44"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <rect width="206" height="24" fill="#F4F5F7" />
      <rect y="26" width="296" height="16" fill="#F4F5F7" />
    </ContentLoader>
  );
});
