import React, { memo, useCallback, useEffect, useState } from 'react';

import { InputWithTagsComponent } from './InputWithTags';

interface IInputWithTagsContainer {
  tagsList: string[];
  placeholder: string;
  max?: number;
  onChange: (value: string[]) => void;
}

export const InputWithTags = memo(
  ({ tagsList, placeholder, max, onChange }: IInputWithTagsContainer) => {
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [inputSize, setInputSize] = useState(1);

    useEffect(() => {
      setTags(tagsList);
    }, [tagsList]);

    const onChangeValue = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (max && tags.length >= max) {
          return;
        }

        setInputValue(event.target.value);
        setInputSize(event.target.value.length);
      },
      [max, tags.length],
    );

    const onRemoveTag = useCallback(
      (tagIndex) => {
        setTags((prevState) => prevState.filter((tag, index) => index !== tagIndex));
        onChange(tags.filter((tag, index) => index !== tagIndex));
      },
      [onChange, tags],
    );

    const onEnter = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && inputValue.length) {
          setTags((prevState) => [...prevState, inputValue]);
          setInputValue('');
          setInputSize(1);
          onChange([...tags, inputValue]);
        }
      },
      [inputValue, onChange, tags],
    );

    return (
      <InputWithTagsComponent
        tags={tags}
        inputValue={inputValue}
        inputSize={inputSize}
        placeholder={placeholder}
        onChange={onChangeValue}
        onInputEnter={onEnter}
        onRemoveTag={onRemoveTag}
      />
    );
  },
);
