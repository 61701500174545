import React, { memo } from 'react';
import styled from 'styled-components';

import fontService, { TextType } from 'services/FontService';
import { tablet } from 'services/ui';
import { Colors } from 'context';
import { Icon, IconNames } from '../Icon';

export interface IInfo {
  text: string;
}

export const Info = memo(({ text }: IInfo) => (
  <InfoContainer>
    <IconContainer>
      <StyledIcon name={IconNames.Warning} />
    </IconContainer>
    {text}
  </InfoContainer>
));

const InfoContainer = styled.div`
  display: flex;
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid ${Colors.signal_red};
  color: ${Colors.signal_red};
  background: rgba(232, 43, 39, 0.08);
  ${fontService.text({ type: TextType.small })};

  ${tablet} {
    padding: 5px 12px;
    ${fontService.text({ type: TextType.normal })};
  }
`;

const IconContainer = styled.div`
  display: inline-block;
  margin-right: 4px;
`;

const StyledIcon = styled((props) => <Icon {...props} />)`
  width: 16px;
  height: 16px;

  ${tablet} {
    width: 20px;
    height: 20px;
  }
`;
