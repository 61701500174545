import { useCallback, useMemo } from 'react';
import { useResumeSocialMediaLazyQuery } from 'core';
import { SocialMediaType } from 'context';

type socialMediaType = [
  () => void,
  {
    data: SocialMediaType[];
    loading?: boolean;
  },
];

export const useGetSocialMedia = (): socialMediaType => {
  const [loadSocialMedia, { data, loading }] = useResumeSocialMediaLazyQuery();

  const loadMedia = useCallback(() => loadSocialMedia(), [loadSocialMedia]);

  const socialMediaData = useMemo(
    () =>
      data?.allCvSocialMedias?.edges.map((item) => ({
        id: item?.node?.id || '',
        name: item?.node?.mediaName || '',
      })),
    [data?.allCvSocialMedias?.edges],
  );

  return [
    loadMedia,
    {
      data: socialMediaData || [],
      loading,
    },
  ];
};
