import { DateTime } from 'luxon';
import { Months } from 'context';
import { getMonthsTranslated } from 'services/TranslationService';

export const TIME: { [key: string]: string } = {
  '21:00:00': '00:00',
  '22:00:00': '01:00',
  '23:00:00': '02:00',
  '00:00:00': '03:00',
  '01:00:00': '04:00',
  '02:00:00': '05:00',
  '03:00:00': '06:00',
  '04:00:00': '07:00',
  '05:00:00': '08:00',
  '06:00:00': '09:00',
  '07:00:00': '10:00',
  '08:00:00': '11:00',
  '09:00:00': '12:00',
  '10:00:00': '13:00',
  '11:00:00': '14:00',
  '12:00:00': '15:00',
  '13:00:00': '16:00',
  '14:00:00': '17:00',
  '15:00:00': '18:00',
  '16:00:00': '19:00',
  '17:00:00': '20:00',
  '18:00:00': '21:00',
  '19:00:00': '22:00',
  '20:00:00': '23:00',
};

export const parseDate = (date: string) =>
  `${DateTime.fromISO(date).day} ${getMonthsTranslated(
    Months[DateTime.fromISO(date, { locale: 'eng' }).monthLong as keyof typeof Months],
  )} ${DateTime.fromISO(date).year}, ${DateTime.fromISO(date).toLocaleString(
    DateTime.TIME_24_SIMPLE,
  )}`;

export const parseDateFromDatePicker = (date: Date) =>
  `${DateTime.fromJSDate(date).day} ${getMonthsTranslated(
    Months[
      DateTime.fromISO(DateTime.fromJSDate(date).toISODate(), { locale: 'eng' })
        .monthLong as keyof typeof Months
    ],
  )} ${DateTime.fromJSDate(date).year}`;

export const isBeforeNow = (date: string) =>
  DateTime.fromISO(date).startOf('day') < DateTime.now().startOf('day');

export const parseLessonDay = (date: string) => {
  const parsedDate = DateTime.fromISO(date)
    .setLocale(process.env.REACT_APP_LANG || 'ru')
    .toFormat('EEEE, d');
  return `${parsedDate.charAt(0).toUpperCase() + parsedDate.slice(1)} ${getMonthsTranslated(
    Months[DateTime.fromISO(date, { locale: 'eng' }).monthLong as keyof typeof Months],
  )}`;
};

export const isNow = (date: string) => DateTime.now().toISODate() === date;

const date = new Date();

export default date;
