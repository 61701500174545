import { useCallback, useState } from 'react';

import { LessonQuery } from 'core/operations';
import { useCancelUploadVideoMutation, useUploadLessonVideoMutation } from 'core';

type UploadVideoType = [
  (lessonId: string, videoLink: string) => void,
  (lessonId: string) => void,
  {
    success: boolean;
    loading: boolean;
    error?: string | null;
    customError?: string | null;
    cancelError?: string | null;
  },
];

export const useUploadVideo = (groupLessonId = ''): UploadVideoType => {
  const [abortRef, setAbortRef] = useState(new AbortController());

  const [uploadVideoMutation, { data, loading, error }] = useUploadLessonVideoMutation({
    refetchQueries: [{ query: LessonQuery, variables: { groupLessonId } }],
    context: {
      fetchOptions: {
        signal: abortRef.signal,
      },
    },
  });
  const [cancelUploadVideoMutation, { data: dataCancel }] = useCancelUploadVideoMutation();

  const uploadVideo = useCallback(
    (lessonId: string, videoLink: string) =>
      uploadVideoMutation({
        variables: {
          lessonId: Number(lessonId),
          videoUrl: videoLink,
        },
      }),
    [uploadVideoMutation],
  );

  const cancelUpload = useCallback(
    (lessonId: string) => {
      abortRef.abort();
      setAbortRef(new AbortController());
      cancelUploadVideoMutation({
        variables: {
          lessonId: Number(lessonId),
        },
      });
    },
    [abortRef, cancelUploadVideoMutation],
  );

  return [
    uploadVideo,
    cancelUpload,
    {
      success: Boolean(data?.uploadLessonVideo?.success),
      loading,
      error: error?.message,
      customError: data?.uploadLessonVideo?.customErrors?.[0]?.code,
      cancelError: dataCancel?.cancelVideoUpload?.customErrors?.[0]?.code,
    },
  ];
};
