import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconNames, Modal } from 'atoms';
import styled, { css } from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet } from 'services/ui';
import { Colors } from 'context';

export interface IResumeGenerationPopup {
  isShowing: boolean;
  isSuccessful: boolean;
  onClose: () => void;
  onDownload: () => void;
}

export const ResumeGenerationPopup = memo(
  ({ isShowing, isSuccessful, onClose, onDownload }: IResumeGenerationPopup) => {
    const { t } = useTranslation();
    const popupText = useMemo(
      () => (isSuccessful ? `${t('resume.inProcess.success')}` : `${t('resume.inProcess.error')}`),
      [isSuccessful, t],
    );

    return (
      <Modal isShowing={isShowing}>
        <Header>
          <Title>{t('resume.inProcess.creation')}</Title>
          <CloseButton onClick={onClose}>
            <Icon name={IconNames.Close} width={20} height={20} />
          </CloseButton>
        </Header>

        <Content>
          <TextWrapper>
            <Text>{popupText}</Text>
            <DownloadButton onClick={onDownload}>{t('resume.inProcess.tryAgain')}</DownloadButton>
          </TextWrapper>
          <StyledIcon name={isSuccessful ? IconNames.GreenCheck : IconNames.RedError} />
        </Content>
      </Modal>
    );
  },
);

const Header = styled.div`
  position: relative;
  padding: 84px 20px 0;
  margin-bottom: 20px;

  ${tablet} {
    padding: 40px 40px 0;
    margin-bottom: 32px;
  }

  ${desktop} {
    padding: 48px 60px 0;
  }
`;

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
`;

const Title = styled.h2`
  ${fontService.h3()};

  ${tablet} {
    ${fontService.h2()};
    margin: 0;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 0 20px 40px;

  ${tablet} {
    padding: 0 40px 64px;
  }

  ${desktop} {
    padding: 0 60px 64px;
  }
`;

const TextWrapper = styled.div`
  ${tablet} {
    width: 78%;
  }

  ${desktop} {
    width: 100%;
    display: flex;
    gap: 3px;
  }
`;

const Text = styled.p`
  ${fontService.text({ size: 14, lineHeight: 150, weight: FontWeight.regular })}

  ${desktop} {
    ${fontService.text({ size: 16, lineHeight: 150, weight: FontWeight.regular })}
  }
`;

const buttonsReset = css`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const DownloadButton = styled.button`
  color: ${Colors.main_dark};
  ${buttonsReset};
  text-decoration: underline;
  ${fontService.text({ size: 14, lineHeight: 150, weight: FontWeight.regular })}

  ${tablet} {
    ${fontService.text({ size: 16, lineHeight: 150, weight: FontWeight.regular })}
  }

  &:hover {
    transition: 0.5s;
    background-color: transparent;
    color: ${Colors.main_light};
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: -118px;
  left: 20px;
  width: 40px;
  height: 40px;

  ${tablet} {
    top: unset;
    left: unset;
    bottom: 66px;
    right: 92px;
    width: 80px;
    height: 80px;
  }

  ${desktop} {
    bottom: 36px;
    right: 92px;
    width: 130px;
    height: 130px;
  }
`;
