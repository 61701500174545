import React, { memo } from 'react';

import styled from 'styled-components';
import { Icon, IconNames } from 'atoms';
import { HomeworkGradesType } from 'types';
import { Colors, GradeNames } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { getTaskGradesTranslated } from 'services/TranslationService';

export interface ICheckHomeworkButton {
  gradeType: HomeworkGradesType;
  isActive: boolean;
  onClick: (value: HomeworkGradesType) => void;
}

export const CheckHomeworkButton = memo(
  ({ gradeType, isActive, onClick }: ICheckHomeworkButton) => (
    <Wrapper isActive={isActive} onClick={() => onClick(gradeType)}>
      {isActive && <Icon name={IconNames.CloseSm} width={12} height={12} />}
      <Text>{getTaskGradesTranslated(GradeNames[gradeType])}</Text>
    </Wrapper>
  ),
);

const Wrapper = styled.div<{ isActive: boolean }>`
  width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  height: 26px;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: ${({ isActive }) => (isActive ? Colors.main : Colors.bg_gray)};
  color: ${({ isActive }) => (isActive ? Colors.btn_text : Colors.black)};
`;

const Text = styled.p`
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })};
  margin-left: 4px;
`;
