import React, { memo } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import fontService, { FontWeight, TextType } from 'services/FontService';
import styled, { css } from 'styled-components';
import { Colors } from 'context';
import { desktop, tablet } from 'services/ui';

export interface ITab {
  children: React.ReactText | React.ReactElement;
  to: string;
  isActive?: boolean;
  isEnabled?: boolean;
  enabledText?: string;
}

export const Tab = memo(({ children, to, isActive, isEnabled = true }: ITab) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Wrapper to={to} $isActive={isActive || Boolean(match)} $isEnabled={isEnabled}>
      {children}
    </Wrapper>
  );
});

const Wrapper = styled(Link)<{ $isActive: boolean; $isEnabled: boolean }>`
  display: block;
  color: ${Colors.black};
  white-space: nowrap;
  ${fontService.text({ type: TextType.normal, weight: FontWeight.medium })}

  ${tablet} {
    ${fontService.text({ type: TextType.medium })}
  }

  ${({ $isEnabled }) =>
    !$isEnabled &&
    css`
      pointer-events: none;
      color: ${Colors.gray};
    `}

  &::after {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    margin-top: 8px;
    background-color: ${Colors.main};
    visibility: hidden;
  }

  ${({ $isActive }) => {
    if ($isActive) {
      return css`
        font-weight: 700;
        &::after {
          visibility: visible;
        }

        ${tablet} {
          font-weight: 700;
        }

        ${desktop} {
          font-weight: 500;
        }
      `;
    }
  }};
`;
