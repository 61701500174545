import React, { memo, useCallback } from 'react';

import { nanoid } from 'nanoid';

import { IResumeFields, ILanguages, LanguageLevel } from 'context';
import { LanguageBlockComponent } from './LanguageBlock';

interface ILanguageBlockContainer {
  fields: IResumeFields;
  languagesList: ILanguages[];
  onInputChange: (name: string, value: string | LanguageLevel) => void;
  onChangeLanguages: (name: string, value: ILanguages[]) => void;
}

export const LanguageBlock = memo(
  ({ fields, languagesList, onInputChange, onChangeLanguages }: ILanguageBlockContainer) => {
    const onRemoveLanguage = useCallback(
      (id: string) => {
        onChangeLanguages(
          'languages',
          languagesList.filter((language) => language.id !== id),
        );
      },
      [onChangeLanguages, languagesList],
    );

    const onAddLanguage = useCallback(() => {
      onChangeLanguages('languages', [...languagesList, { id: nanoid(), language: '', level: '' }]);
    }, [onChangeLanguages, languagesList]);

    return (
      <LanguageBlockComponent
        fields={fields}
        languagesList={languagesList}
        onInputChange={onInputChange}
        onRemoveLanguage={onRemoveLanguage}
        onAddLanguage={onAddLanguage}
      />
    );
  },
);
