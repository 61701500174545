import React, { memo, useCallback, useEffect, useState } from 'react';

import { IResumeDate, ISelectOptions, Languages } from 'context';
import { InputYearMonthComponent } from './InputYearMonth';

export interface IInputYearMonthContainer {
  language: Languages;
  value: IResumeDate;
  yearPlaceholder: string;
  monthsPlaceholder: string;
  onSelect: (value: IResumeDate) => void;
  isDisabled: boolean;
}

export const InputYearMonth = memo(
  ({
    language,
    value,
    onSelect,
    isDisabled,
    yearPlaceholder,
    monthsPlaceholder,
  }: IInputYearMonthContainer) => {
    const [year, setYear] = useState<string>(value.year || '');
    const [month, setMonth] = useState<string>(value.month || '');
    const [isMonthsDisabled, setIsMonthDisabled] = useState<boolean>(true);

    useEffect(() => {
      setYear(value.year);
      setMonth(value.month);
    }, [value.year, value.month]);

    const onSelectYear = useCallback(
      (option: ISelectOptions) => {
        setYear(option.value);
        onSelect({
          year: option.value,
          month,
        });
      },
      [onSelect, month],
    );

    const onSelectMonth = useCallback(
      (option: ISelectOptions) => {
        setMonth(option.value);
        onSelect({
          year,
          month: option.value,
        });
      },
      [onSelect, year],
    );

    useEffect(() => {
      if (year === '') {
        setMonth('');
        setIsMonthDisabled(true);
      } else setIsMonthDisabled(false);
    }, [year]);

    return (
      <InputYearMonthComponent
        language={language}
        value={{
          year: {
            label: year,
            value: year,
          },
          month: {
            label: month,
            value: month,
          },
        }}
        yearPlaceholder={yearPlaceholder}
        monthsPlaceholder={monthsPlaceholder}
        onSelectYear={onSelectYear}
        onSelectMonth={onSelectMonth}
        isMonthDisabled={isMonthsDisabled}
        isYearDisabled={isDisabled}
      />
    );
  },
);
