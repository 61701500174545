export const saveToken = (refresh_token: string) => {
  localStorage.setItem('refresh_token', refresh_token);
};

export const getToken = () => localStorage.getItem('refresh_token');

export const removeToken = () => {
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
};

export const setNextCodeTime = (nextCodeTime: number) =>
  localStorage.setItem('nextCodeTime', nextCodeTime.toString());

export const getNextCodeTime = () => Number(localStorage.getItem('nextCodeTime') || 0);
