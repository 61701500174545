import React, { memo, useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  LessonTaskTypeEnum,
  LessonTaskTypes,
  StudentHwTypeEnum,
  useAddStudentsMarks,
  useGetStudentsMarks,
  useLessonHomework,
  useToaster,
} from 'core';
import { HomeworkCheckComponent } from 'organisms';
import { TaskTypes } from 'context';
import { JournalPreloader, ToastTypes } from 'atoms';

interface IHomeworkCheckContainer {
  lessonId: string;
  taskType?: TaskTypes;
  lessonDate: Date;
}

export const HomeworkCheck = memo(
  ({ lessonId, lessonDate, taskType = TaskTypes.homework }: IHomeworkCheckContainer) => {
    const { t } = useTranslation();
    const [isHomeworkChecked, setIsHomeworkChecked] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [checkDate, setCheckDate] = useState<Date>(lessonDate);
    const [hasError, setHasError] = useState<boolean>(false);

    const { addToast } = useToaster();

    const { marksDate, studentsMarks, loading } = useGetStudentsMarks(lessonId, taskType);

    useEffect(() => {
      if (marksDate) {
        setIsHomeworkChecked(true);
        setCheckDate(marksDate);
      }
    }, [marksDate]);

    const {
      studentsGrades,
      onCheckAllHomeworksHaveEdits,
      onCheckAllHomeworksDone,
      onUncheckAllHomeworks,
      onChangeGrade,
      allHomeworksDone,
      allHomeworksHaveEdits,
      onCancel,
    } = useLessonHomework(studentsMarks);

    const [addStudentsMarks, { success, loading: addMarksLoading, error }] = useAddStudentsMarks(
      lessonId,
      taskType,
    );

    const onEditHomeworkGrades = useCallback(() => {
      setIsHomeworkChecked(false);
      setIsEditMode(true);
    }, []);

    const onSubmit = useCallback(() => {
      const hasEmptyGrades = studentsGrades.find(({ gradeType }) => !gradeType);
      if (hasEmptyGrades) {
        setHasError(true);
        return;
      }

      addStudentsMarks({
        lessonId: Number(lessonId),
        taskType: LessonTaskTypeEnum[LessonTaskTypes[taskType]],
        date: DateTime.fromJSDate(checkDate).toISODate(),
        studentsMarks: studentsGrades.map((student) => ({
          student_id: Number(student.studentId),
          hwMark: student.gradeType?.toString() as StudentHwTypeEnum,
        })),
      });
      setHasError(false);
    }, [studentsGrades, addStudentsMarks, lessonId, taskType, checkDate]);

    const onChangeDate = useCallback((date: Date | null) => {
      if (date) {
        setCheckDate(date);
      }
    }, []);

    const onCancelChanges = useCallback(() => {
      onCancel();
      setIsHomeworkChecked(true);
      setIsEditMode(false);
      setHasError(false);
    }, [onCancel]);

    useEffect(() => {
      if (!success && error) {
        addToast('save_marks', {
          type: ToastTypes.danger,
          text: `${t('tasks.homework.saveError')}`,
        });
      }

      if (success) {
        setIsEditMode(false);
        setIsHomeworkChecked(true);
        addToast('save_marks', {
          type: ToastTypes.success,
          text: `${t('tasks.homework.saveSuccess')}`,
        });
      }
    }, [addToast, error, success, t]);

    return loading ? (
      <JournalPreloader />
    ) : (
      <HomeworkCheckComponent
        students={studentsGrades}
        lessonDate={lessonDate}
        checkDate={checkDate}
        isHomeworkChecked={isHomeworkChecked}
        allHomeworksDone={allHomeworksDone}
        allHomeworksHaveEdits={allHomeworksHaveEdits}
        onEditHomeworkGrades={onEditHomeworkGrades}
        onCheckAllHomeworksDone={onCheckAllHomeworksDone}
        onCheckAllHomeworksHaveEdits={onCheckAllHomeworksHaveEdits}
        onUncheckAllHomeworks={onUncheckAllHomeworks}
        onChangeGrade={onChangeGrade}
        onChangeDate={onChangeDate}
        onSave={onSubmit}
        onCancel={onCancelChanges}
        hasError={hasError}
        isEditMode={isEditMode}
        addMarksLoading={addMarksLoading}
      />
    );
  },
);
