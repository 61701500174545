import { isTMSTheme } from 'services/ui';

enum ExternalPathsTMS {
  mailto_info = 'mailto:info@teachmeskills.com',
  blog = 'https://teachmeskills.by/blog',
  contact_us = 'https://teachmeskills.by/contact-us',
  facebook = 'https://www.facebook.com/teachmeskills',
  vk = 'https://vk.com/teachmeskills',
  linkedIn = 'https://www.linkedin.com/company/teachmeskills/',
  instagram = 'https://www.instagram.com/teachmeskills/',
  telegram = 'https://t.me/TMS_news',
  twitter = 'https://twitter.com/teach_me_skills',
  youTube = 'https://www.youtube.com/channel/UCrpbZ8VZjn8FtMv0-I1VJ2A',
  whatsApp = '',
}

enum ExternalPathsLearnIT {
  mailto_info = 'mailto:info@learnit.com.pl',
  blog = 'https://learnit.com.pl/blog',
  contact_us = 'https://learnit.com.pl/contacts',
  facebook = 'https://www.facebook.com/',
  linkedIn = 'https://www.linkedin.com/',
  instagram = 'https://www.instagram.com/',
  whatsApp = 'https://www.whatsapp.com/',
  vk = '',
  telegram = '',
  twitter = '',
  youTube = '',
}

export const ExternalPaths = isTMSTheme() ? ExternalPathsTMS : ExternalPathsLearnIT;
