import React, { memo } from 'react';

import styled from 'styled-components';
import { StudentMarks } from 'types';
import { TaskResultCard } from 'molecules';

export interface ITaskResultsComponent {
  studentMarks: StudentMarks[];
}

export const TaskResultsComponent = memo(({ studentMarks }: ITaskResultsComponent) => (
  <Wrapper>
    {studentMarks.map(({ taskType, mark, hwMark, date }) => (
      <TaskResultCard key={taskType} taskType={taskType} mark={mark} grade={hwMark} date={date} />
    ))}
  </Wrapper>
));

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
