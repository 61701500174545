import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { LessonAttachment } from 'molecules/LessonAttachment/LessonAttachment';
import { AttachmentType, LessonAttachmentType } from 'types';
import fontService, { FontWeight } from 'services/FontService';
import { tablet } from 'services/ui';
import { Colors } from 'context';

export interface IProps {
  attachments: LessonAttachmentType[];
  type: AttachmentType;
}

export const LessonAttachmentsComponent = ({ attachments, type }: IProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <AttachmentsTitle>{t('lesson.attachments.title')}</AttachmentsTitle>
      <AttachmentsItems>
        {attachments.map((attachment, index) => (
          <AttachmentStyled
            key={index}
            title={attachment.title}
            id={attachment.id}
            type={type}
            link={attachment.link}
          />
        ))}
      </AttachmentsItems>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const AttachmentsTitle = styled.h3`
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}
  margin-bottom: 16px;

  ${tablet} {
    ${fontService.h3()};
    margin-bottom: 24px;
  }
`;

const AttachmentsItems = styled.div``;

const AttachmentStyled = styled(LessonAttachment)`
  padding: 32px 20px 24px;
  border: 1px solid ${Colors.system_gray};
  border-radius: 12px;

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  ${tablet} {
    padding: 0 0 26px;
    border: 0;
    border-bottom: 1px solid ${Colors.gray};
    border-radius: 0;
  }
`;
