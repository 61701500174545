import React, { memo, useCallback, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';

import fontService, { TextType } from 'services/FontService';
import { Colors } from 'context';
import { useToaster } from 'core';
import { Icon, IconNames } from '../Icon';

const enterAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const leaveAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export enum ToastTypes {
  success = 'success',
  danger = 'danger',
  warning = 'warning',
}

export interface IToast {
  text: string;
  type: ToastTypes;
  toastKey: string;
}

export const Toast = memo(({ toastKey, text, type }: IToast) => {
  const { removeToast } = useToaster();
  const iconName = useMemo(
    () => (type === ToastTypes.success ? IconNames.WhiteCheck : IconNames.WhiteWarning),
    [type],
  );

  const onAnimationEnd = useCallback(() => {
    removeToast(toastKey);
  }, [toastKey, removeToast]);

  const onClose = useCallback(() => removeToast(toastKey), [removeToast, toastKey]);

  return (
    <ToastContainer
      type={type}
      onAnimationEnd={(e) =>
        Boolean(e.animationName === leaveAnimation.getName()) && onAnimationEnd()
      }>
      <IconContainer>
        <Icon name={iconName} width={20} height={20} />
      </IconContainer>
      {text}
      <CloseIconWrapper onClick={onClose}>
        <Icon name={IconNames.WhiteClose} width={20} height={20} />
      </CloseIconWrapper>
    </ToastContainer>
  );
});

const ToastContainer = styled.div<{ type: ToastTypes }>`
  display: flex;
  padding: 5px 12px;
  border-radius: 4px;
  pointer-events: auto;
  ${fontService.text({ type: TextType.normal })};

  animation: ${leaveAnimation} 0.6s ease-in 3.4s, ${enterAnimation} 0.4s ease-out forwards;

  ${({ type }) => {
    switch (type) {
      case ToastTypes.success:
        return css`
          background: #11bf6a;
          box-shadow: 0px 12px 24px rgba(17, 191, 106, 0.4);
          color: ${Colors.white};
        `;

      case ToastTypes.danger:
        return css`
          background: ${Colors.signal_red};
          box-shadow: 0px 12px 24px rgba(232, 43, 39, 0.4);
          color: ${Colors.white};
        `;

      case ToastTypes.warning:
        return css`
          background: ${Colors.main_dark};
          box-shadow: 0px 12px 24px rgba(240, 190, 12, 0.4);
          color: ${Colors.white};
        `;

      default:
        return css`
          border: 1px solid ${Colors.signal_red};
          color: ${Colors.signal_red};
          background: rgba(232, 43, 39, 0.08);
        `;
    }
  }}
`;

const IconContainer = styled.div`
  display: inline-block;
  margin-right: 4px;
`;

const CloseIconWrapper = styled.div`
  display: inline-block;
  margin-left: 12px;
  cursor: pointer;
`;
