import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import fontService, { FontWeight, TextType } from 'services/FontService';
import { tablet } from 'services/ui';

import { Button, ButtonSizes, Icon, IconNames } from 'atoms';
import { Colors } from 'context';

export interface IPhotoUpload {
  titleText: string;
  infoText: string;
  uploadBtnText: string;
  clearBtnText: string;
  imageUrl: string | null;
  hiddenInputRef: React.Ref<HTMLInputElement>;
  uploadClick: () => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  error: boolean;
}

export const PhotoUploadComponent = memo(
  ({
    titleText,
    infoText,
    uploadBtnText,
    clearBtnText,
    imageUrl,
    onUpload,
    onClear,
    hiddenInputRef,
    uploadClick,
    error,
  }: IPhotoUpload) => {
    const { t } = useTranslation();
    return (
      <Wrapper>
        <Image>
          {imageUrl ? (
            <AvatarImage src={imageUrl} alt="Фото" />
          ) : (
            <Icon name={IconNames.Photo} width="100%" height="100%" />
          )}
        </Image>
        <Info>
          <WrapperInfo>
            <Title>{titleText}</Title>
            <SubText>{infoText}</SubText>
            {error && <ErrorText>{t('input.validation.photoWrongFormat')}</ErrorText>}
          </WrapperInfo>

          <ButtonsWrapper>
            <UploadInput type="file" ref={hiddenInputRef} onChange={onUpload} />

            <StyledButton
              variant="outline"
              size={ButtonSizes.sm}
              colors={{ borderColor: Colors.gray }}
              onClick={uploadClick}>
              {uploadBtnText}
            </StyledButton>

            {imageUrl && (
              <StyledButton
                variant="outline"
                size={ButtonSizes.sm}
                colors={{ borderColor: Colors.gray }}
                onClick={onClear}>
                {clearBtnText}
              </StyledButton>
            )}
          </ButtonsWrapper>
        </Info>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  background-color: #f9fafb;
  padding: 24px 42px 24px 20px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;

  ${tablet} {
    padding: 32px;
  }
`;

const Image = styled.div`
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;

  ${tablet} {
    margin-right: 24px;
  }
`;

const AvatarImage = styled.img`
  width: 96px;
  height: 96px;
  object-fit: cover;
  object-position: center;
`;

const Info = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WrapperInfo = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.p`
  margin: 0 0 4px 0;
  ${fontService.text({ type: TextType.normal, weight: FontWeight.medium })}
`;

const SubText = styled.p`
  color: ${Colors.gray};
  ${fontService.text({ type: TextType.small, weight: FontWeight.regular })}

  ${tablet} {
    ${fontService.text({ type: TextType.normal, weight: FontWeight.regular })}
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  ${tablet} {
    flex-direction: row;
  }
`;

const UploadInput = styled.input`
  display: none;
`;

const StyledButton = styled(Button)`
  width: 100px;

  ${tablet} {
    width: unset;
    max-width: max-content;
  }
`;

const ErrorText = styled.p`
  margin-top: 4px;
  color: ${Colors.signal_red};
  ${fontService.text({ type: TextType.small, weight: FontWeight.regular })}
`;
