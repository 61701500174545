import React, { memo, useCallback } from 'react';

import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import fontService, { FontWeight } from 'services/FontService';
import { tablet } from 'services/ui';

import { CheckBox, RadioButton } from 'components/atoms';
import { Colors } from 'context';
import { ILessonJournal } from './LessonJournal';

interface IStudentsAttendance
  extends Pick<
    ILessonJournal,
    | 'isFinished'
    | 'students'
    | 'onCheckAllStudents'
    | 'onCheckStudent'
    | 'onUncheckAllStudents'
    | 'isAllStudentsChecked'
  > {}

export const StudentsAttendance = memo(
  ({
    students,
    isFinished,
    isAllStudentsChecked,
    onCheckAllStudents,
    onUncheckAllStudents,
    onCheckStudent,
  }: IStudentsAttendance) => {
    const { t } = useTranslation();
    const showStudents = useCallback(
      () =>
        students.map(({ attendanceId, studentName, checked }, index) => (
          <CheckBoxStyled
            key={attendanceId}
            checked={Boolean(checked)}
            text={studentName}
            onClick={() => onCheckStudent(index)}
            disabled={isFinished}
          />
        )),
      [students, isFinished, onCheckStudent],
    );

    return (
      <StudentsAttendanceContainer>
        <StudentsAttendanceHeader>{t('lesson.journal.checkAttendances')}</StudentsAttendanceHeader>
        <RadioButtonWrapper>
          <RadioButtonStyled
            checked={isAllStudentsChecked}
            text={t('lesson.journal.checkOptions.allChecked')}
            disabled={isFinished}
            onClick={onCheckAllStudents}
          />
          <RadioButtonStyled
            checked={!isAllStudentsChecked}
            text={t('lesson.journal.checkOptions.partial')}
            disabled={isFinished}
            onClick={onUncheckAllStudents}
          />
        </RadioButtonWrapper>

        <Separator />

        <StudentsContainer studentsCount={students.length}>{showStudents()}</StudentsContainer>
      </StudentsAttendanceContainer>
    );
  },
);

const StudentsAttendanceContainer = styled.div`
  ${tablet} {
    width: 564px;
  }
`;

const StudentsAttendanceHeader = styled.h3`
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}
  margin: 0;

  ${tablet} {
    ${fontService.h3()}
  }
`;

const RadioButtonStyled = styled(RadioButton)`
  width: fit-content;
`;

const RadioButtonWrapper = styled.div`
  margin-top: 20px;

  ${RadioButtonStyled}:not(:first-of-type) {
    margin-top: 8px;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.additional_gray};
  margin-top: 14px;
`;

const StudentsContainer = styled.div<{ studentsCount: number }>`
  display: flex;
  margin-top: 18px;
  flex-direction: column;
  flex-wrap: wrap;

  ${tablet} {
    ${({ studentsCount }) => {
      if (studentsCount < 8) {
        return css`
          height: calc(28px * ${studentsCount});
        `;
      }
      return css`
        height: 224px;
      `;
    }}
  }
`;

const CheckBoxStyled = styled(CheckBox)`
  margin-bottom: 10px;
  width: fit-content;
`;
