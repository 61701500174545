import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';

import styled, { css } from 'styled-components';
import fontService, { FontWeight, TextType } from 'services/FontService';
import { tablet } from 'services/ui';

import { LessonsType } from 'molecules/CourseProgram/Lessons';
import { PopupContainer } from 'molecules';
import { authState, useModal } from 'core';
import { Colors, UserRole } from 'context';
import { Icon, IconNames, Info } from 'atoms';

export const isScrollEnd = (ref: React.RefObject<any>) =>
  ref.current.scrollHeight === ref.current.scrollTop + ref.current.clientHeight;

export const MobileLessons = memo(({ lessons }: LessonsType) => {
  const { t } = useTranslation();
  const { isShowing, toggle } = useModal();

  const { groupId, lessonId } = useParams();
  const { active_role } = useReactiveVar(authState);

  const [scrollEnd, setScrollEnd] = useState(false);

  const activeLesson = useMemo(
    () => lessons.find(({ id }) => id === lessonId) || null,
    [lessons, lessonId],
  );

  const lessonsRef = useRef<HTMLAnchorElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    if (scrollContainerRef.current) {
      setScrollEnd(isScrollEnd(scrollContainerRef));
    }
  };

  useEffect(() => {
    if (isShowing) {
      setTimeout(() => {
        if (scrollContainerRef.current && lessonsRef.current) {
          const scrollContainerHalfHeight = scrollContainerRef.current.offsetHeight / 2;
          scrollContainerRef.current.scrollTop =
            lessonsRef.current.offsetTop - scrollContainerHalfHeight;
          setScrollEnd(isScrollEnd(scrollContainerRef));
        }
      }, 0);
    }
  }, [isShowing]);

  if (!lessons.length) {
    return (
      <Wrapper>
        <Title>{t('courseInfo.program')}</Title>
        <InfoContainer>
          <Info text={t('courseInfo.noAvailableLessons')} />
        </InfoContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>{t('courseInfo.program')}</Title>
      <LessonSelector onClick={toggle} hasWarning={activeLesson?.needToFinish}>
        {(activeLesson && `${activeLesson.index}. ${activeLesson.name}`) || ''}
        {active_role === UserRole.TEACHER && activeLesson && activeLesson.needToFinish && (
          <WarningIconStyled name={IconNames.WarningYellow} width={16} height={16} />
        )}
        <StyledSelectIcon name={IconNames.UnfoldMore} />
      </LessonSelector>
      <PopupContainer
        title={t('courseInfo.program')}
        isShowing={isShowing}
        modalHeight="calc(100dvh - 42px)"
        align="flex-start"
        onClose={toggle}>
        <LessonsWrapper>
          <LessonsPlaceholder>
            <LessonsOverflow ref={scrollContainerRef} onScroll={onScroll}>
              <LessonsContainer>
                {lessons.map(({ id, name, index, isEnded, needToFinish }) => (
                  <StyledLink
                    to={`/courses/${groupId}/${id}/lessonDescription`}
                    key={id}
                    ref={lessonId === id ? lessonsRef : null}
                    onClick={toggle}>
                    <Lesson isEnded={isEnded} isActive={lessonId === id}>
                      <LessonNumber>{index}.</LessonNumber>
                      <LessonName>
                        <span>
                          {name}
                          <WarningWrapper>
                            {active_role === UserRole.TEACHER && needToFinish && (
                              <IconStyled name={IconNames.WarningYellow} width={16} height={16} />
                            )}
                            <TitleStyled text={t('courseInfo.needToFinish')} position="right" />
                          </WarningWrapper>
                        </span>
                      </LessonName>
                    </Lesson>
                  </StyledLink>
                ))}
              </LessonsContainer>
            </LessonsOverflow>
          </LessonsPlaceholder>
          <LessonsShadow scrollEnd={scrollEnd} />
        </LessonsWrapper>
      </PopupContainer>
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const Title = styled.p`
  ${fontService.text({ type: TextType.normal, weight: FontWeight.bold })};
  margin-bottom: 4px;
`;

const InfoContainer = styled.div`
  margin: 16px 0 12px;
  width: max-content;
`;

const LessonSelector = styled.div<{ hasWarning?: boolean | null }>`
  position: relative;
  height: 48px;
  padding: ${({ hasWarning }) => `14px ${hasWarning ? '66px' : '43px'} 14px 12px`};
  box-sizing: border-box;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.gray};
  border-radius: 8px;
  color: ${({ color }) => color || Colors.black};
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.medium })}
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledSelectIcon = styled((props) => <Icon {...props} />)`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const LessonsWrapper = styled.div`
  position: relative;
  height: calc(100dvh - 140px);
  margin: 20px 0 24px;
  padding: 0 20px;

  ${tablet} {
    height: calc(100dvh - 184px);
    margin-bottom: 40px;
    padding: 0 40px;
  }
`;

const LessonsPlaceholder = styled.div`
  height: 100%;
  position: relative;
`;

const LessonsOverflow = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
  scrollbar-width: none;
`;

const LessonsContainer = styled.div``;

const LessonsShadow = styled.div<{ scrollEnd: boolean }>`
  display: ${({ scrollEnd }) => scrollEnd && 'none'};
  position: absolute;
  bottom: 0;
  left: 20px;
  height: 44px;
  width: calc(100% - 40px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  border-bottom: 1px solid ${Colors.additional_gray};
  pointer-events: none;

  ${tablet} {
    left: 40px;
    width: calc(100% - 80px);
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 4px 0;
  border-bottom: 1px solid ${Colors.system_gray};

  ${tablet} {
    padding: 8px 0;
  }
`;

const Lesson = styled.div<{ isEnded: boolean; isActive: boolean }>`
  display: flex;
  align-items: baseline;
  padding: 4px 8px;
  color: ${({ isEnded }) => (isEnded ? Colors.marker_green : Colors.black)};
  ${fontService.getFont({ size: 14, lineHeight: 142 })}
  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 500;
      background: ${Colors.additional_gray};
      border-radius: 4px;
    `}

  ${tablet} {
    padding: 4px 12px;
    ${fontService.getFont({ size: 16, lineHeight: 150 })}
    ${({ isActive }) =>
      isActive &&
      css`
        font-weight: 500;
      `}
  }
`;

const LessonNumber = styled.span`
  margin: 0 4px 0 4px;
`;

const LessonName = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TitleStyled = styled((props) => <Title {...props} />)`
  display: none;
  z-index: 10;
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  margin-left: 4px;
`;

const WarningIconStyled = styled((props) => <Icon {...props} />)`
  position: absolute;
  right: 40px;
  bottom: 14px;
`;

const WarningWrapper = styled.span`
  display: inline-block;
  position: absolute;
  margin-left: 4px;

  ${tablet} {
    bottom: 4px;
  }
`;
