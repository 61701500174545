import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TextArea } from 'components/atoms';
import { ILessonJournal } from './LessonJournal';

interface IJournalCommentProps
  extends Pick<ILessonJournal, 'comment' | 'onChangeComment' | 'isFinished' | 'className'> {}

export const JournalComment = memo(
  ({ comment, isFinished, onChangeComment, className }: IJournalCommentProps) => {
    const { t } = useTranslation();
    return (
      <CommentContainer className={className}>
        <TextArea
          value={comment}
          onChange={onChangeComment}
          rows={5}
          disabled={isFinished}
          title={t('lesson.comment')}
        />
      </CommentContainer>
    );
  },
);

const CommentContainer = styled.div``;
