import { ILanguages, ISocialMedia, SocialMediaType } from 'context';
import {
  EducationInput,
  LanguagesInput,
  LanguagesLevelEnum,
  SocialMediaInput,
  WorkExperienceInput,
} from 'core';
import { IEducationValues, IExperienceValues } from 'organisms';

const getFields = <InputType extends EducationInput | WorkExperienceInput>(
  fields: InputType,
  inputValues: IEducationValues | IExperienceValues,
): InputType => {
  if (inputValues.startDate?.year !== '') {
    return inputValues.endDate?.year !== ''
      ? {
          ...fields,
          startDate: {
            year: inputValues.startDate?.year,
            month: inputValues.startDate?.month,
          },
          endDate: {
            year: inputValues.endDate?.year,
            month: inputValues.endDate?.month,
          },
        }
      : {
          ...fields,
          startDate: {
            year: inputValues.startDate?.year,
            month: inputValues.startDate?.month,
          },
        };
  }

  return fields;
};

export const transformPhoto = (image: File | string): File | string => {
  if (typeof image === 'string') {
    return image.split('/').pop()?.toString() || '';
  }
  return image;
};

export const transformSocialMedia = (
  socialMedia: ISocialMedia[],
  apiMedia: SocialMediaType[],
): SocialMediaInput[] =>
  socialMedia
    .filter(({ name, link }) => name !== '' && link !== '')
    .map(({ name, link }) => ({
      media: apiMedia.find((item) => item.name === name)?.id || '',
      socialLink: link,
    }));

export const transformLanguages = (cvLanguages: ILanguages[]): LanguagesInput[] =>
  cvLanguages
    .filter(({ language, level }) => language !== '' && level !== '')
    .map(({ language, level }) => ({ language, languageLevel: level as LanguagesLevelEnum }));

export const transformEducation = (education: IEducationValues[]): EducationInput[] =>
  education
    .filter(
      ({ degree, institution, specialization, startDate }) =>
        degree !== '' || institution !== '' || specialization !== '' || startDate.year !== '',
    )
    .map((educationElement) => {
      const mainFields = {
        degree: educationElement.degree,
        institution: educationElement.institution,
        specialization: educationElement.specialization,
      };

      return getFields(mainFields, educationElement);
    });

export const transformExperience = (experience: IExperienceValues[]): WorkExperienceInput[] =>
  experience
    .filter(
      ({ company, position, projects, startDate }) =>
        company !== '' || position !== '' || !!projects.length || startDate.year !== '',
    )
    .map((experienceElement) => {
      const fields = {
        company: experienceElement.company,
        jobTitle: experienceElement.position,
        projects: experienceElement.projects.map((project) => ({
          projectName: project.name,
          description: project.problem,
          summary: project.solution,
          technologies: project.technologies,
        })),
      };

      return getFields(fields, experienceElement);
    });
