import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';

import { Modal, DownloadPreloader } from 'atoms';
import { desktop, tablet } from 'services/ui';

export const ResumeDownloadModal = memo(() => {
  const { t } = useTranslation();
  return (
    <Modal isShowing>
      <Wrapper>
        <Title>{t('resume.inProcess.creation')}</Title>
        <Info>{t('resume.inProcess.generation')}</Info>
        <DownloadPreloader />
      </Wrapper>
    </Modal>
  );
});

const Wrapper = styled.div`
  padding: 24px 20px 40px;

  ${tablet} {
    padding: 40px 40px 64px;
  }

  ${desktop} {
    padding: 48px 60px 64px;
  }
`;

const Title = styled.h2`
  ${fontService.h3()};
  margin: 0 0 20px 0;

  ${tablet} {
    ${fontService.h2()};
    margin: 0 0 32px 0;
  }
`;

const Info = styled.p`
  ${fontService.text({ size: 14, lineHeight: 150, weight: FontWeight.regular })}

  ${desktop} {
    ${fontService.text({ size: 16, lineHeight: 150, weight: FontWeight.regular })}
  }
`;
