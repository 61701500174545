import React, { memo, useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { JournalPreloader, ToastTypes } from 'atoms';
import {
  LessonTaskTypeEnum,
  LessonTaskTypes,
  useAddStudentsMarks,
  useGetStudentsMarks,
  useLessonCoursework,
  useToaster,
} from 'core';
import { TaskTypes } from 'context';
import { CourseworkCheckComponent } from './CourseworkCheck';

interface ICourseworkCheckContainer {
  lessonId: string;
  lessonDate: Date;
  taskType: TaskTypes;
}

export const CourseworkCheck = memo(
  ({ lessonId, lessonDate, taskType }: ICourseworkCheckContainer) => {
    const { t } = useTranslation();
    const [isCourseworkChecked, setIsCourseworkChecked] = useState<boolean>(false);
    const [checkDate, setCheckDate] = useState<Date>(lessonDate);
    const [isEditMode, setIsEditMode] = useState(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const { addToast } = useToaster();

    const { marksDate, studentsMarks, loading } = useGetStudentsMarks(lessonId, taskType);

    useEffect(() => {
      if (marksDate) {
        setIsCourseworkChecked(true);
        setCheckDate(marksDate);
      }
    }, [marksDate]);

    const { studentsGrades, onChangeGrade, onCancel } = useLessonCoursework(studentsMarks);

    const [addStudentsMarks, { success, loading: addMarksLoading, error }] = useAddStudentsMarks(
      lessonId,
      taskType,
    );

    const onEditCourseworkGrades = useCallback(() => {
      setIsCourseworkChecked(false);
      setIsEditMode(true);
    }, []);

    const onChangeDate = useCallback((date: Date | null) => {
      if (date) {
        setCheckDate(date);
      }
    }, []);

    const onCancelChanges = useCallback(() => {
      onCancel();
      setIsCourseworkChecked(true);
      setIsEditMode(false);
      setHasError(false);
    }, [onCancel]);

    const onSubmit = useCallback(() => {
      const hasEmptyGrades = studentsGrades.find(({ mark }) => mark === null);
      if (hasEmptyGrades) {
        setHasError(true);
        return;
      }

      addStudentsMarks({
        lessonId: Number(lessonId),
        taskType: LessonTaskTypeEnum[LessonTaskTypes[taskType]],
        date: DateTime.fromJSDate(checkDate).toISODate(),
        studentsMarks: studentsGrades.map((student) => ({
          student_id: Number(student.studentId),
          mark: student.mark,
        })),
      });
      setHasError(false);
    }, [studentsGrades, addStudentsMarks, lessonId, taskType, checkDate]);

    useEffect(() => {
      if (!success && error) {
        addToast('save_marks', {
          type: ToastTypes.danger,
          text: `${t('tasks.coursework.saveError')}`,
        });
      }

      if (success) {
        setIsEditMode(false);
        setIsCourseworkChecked(true);
        addToast('save_marks', {
          type: ToastTypes.success,
          text: `${t('tasks.coursework.saveSuccess')}`,
        });
      }
    }, [addToast, error, success, t]);

    return loading ? (
      <JournalPreloader />
    ) : (
      <CourseworkCheckComponent
        students={studentsGrades}
        lessonDate={lessonDate}
        checkDate={checkDate}
        isCourseworkChecked={isCourseworkChecked}
        onEditCourseworkGrades={onEditCourseworkGrades}
        onChangeDate={onChangeDate}
        onChangeGrade={onChangeGrade}
        onCancel={onCancelChanges}
        onSave={onSubmit}
        hasError={hasError}
        isEditMode={isEditMode}
        addMarksLoading={addMarksLoading}
      />
    );
  },
);
