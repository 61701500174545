import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LessonJournal } from 'molecules';
import { useFinishLesson, useGoToErrorPage, useLessonJournal, useToaster } from 'core';
import { useParams } from 'react-router-dom';
import { JournalPreloader, ToastTypes } from 'components/atoms';
import { NetworkErrors } from 'context';

interface ILessonJournalContainer {
  isFinished: boolean;
  comment: string;
  isEnded: boolean;
  hasLessonsToFinish: boolean;
  className?: string;
  showJournal: boolean;
}

export const LessonJournalContainer = memo(
  ({
    isFinished,
    isEnded,
    hasLessonsToFinish,
    comment: lessonComment,
    className,
    showJournal,
  }: ILessonJournalContainer) => {
    const { t } = useTranslation();
    const goToErrorPage = useGoToErrorPage();
    const { groupId, lessonId } = useParams();
    const { addToast } = useToaster();

    const {
      comment,
      attendances,
      loadingAttendances,
      errorAttendances,
      isAllStudentsChecked,
      onChangeComment,
      onCheckStudent,
      onCheckAllStudents,
      onUncheckAllStudents,
      onCancel,
    } = useLessonJournal({ lessonId: lessonId || '', lessonComment });

    const [finishLesson, { success, loading: finishLessonLoading, error }] = useFinishLesson(
      groupId,
      lessonId,
    );

    const onSubmit = () =>
      finishLesson({
        groupLessonId: Number(lessonId),
        comment,
        attendances: attendances.map((attendance) => ({
          id: Number(attendance.attendanceId),
          attendance: Boolean(attendance.checked),
        })),
      });

    useEffect(() => {
      if (errorAttendances) {
        console.log({ errorAttendances });
        goToErrorPage();
      }
    }, [errorAttendances, goToErrorPage]);

    useEffect(() => {
      if (!success && error) {
        addToast('save_journal', {
          type: ToastTypes.danger,
          text: `${t('lesson.journal.saveError')}`,
        });
      }

      if (success) {
        addToast('save_journal', {
          type: ToastTypes.success,
          text: `${t('lesson.journal.saveSuccess')}`,
        });
      }
    }, [addToast, error, success, t]);

    useEffect(() => {
      if (!showJournal) {
        goToErrorPage(NetworkErrors.pageIsNotAvailable);
      }
    }, [goToErrorPage, showJournal]);

    return !loadingAttendances ? (
      <LessonJournal
        finishLessonLoading={finishLessonLoading}
        isFinished={isFinished}
        isAllStudentsChecked={isAllStudentsChecked}
        students={attendances}
        onCheckStudent={onCheckStudent}
        onCheckAllStudents={onCheckAllStudents}
        onUncheckAllStudents={onUncheckAllStudents}
        comment={comment}
        onChangeComment={onChangeComment}
        onCancel={onCancel}
        onSubmit={onSubmit}
        className={className}
        success={success}
        isEnded={isEnded}
        hasLessonsToFinish={hasLessonsToFinish}
      />
    ) : (
      <JournalPreloader />
    );
  },
);
