import { useState, useCallback, useEffect } from 'react';
import { COURSES_OPTIONS, COURSES_OPTIONS_METHODIST, UserRole } from 'context';

export const useFilter = (role: UserRole) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(COURSES_OPTIONS[0]);

  useEffect(() => {
    setSelectedOption(
      role === UserRole.METHODIST ? COURSES_OPTIONS_METHODIST[0] : COURSES_OPTIONS[0],
    );
  }, [role]);

  const toggling = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const onOptionClicked = useCallback(
    (value) => () => {
      setSelectedOption(value);
      setIsOpen(false);
    },
    [],
  );

  return { isOpen, selectedOption, toggling, onOptionClicked };
};
