import { gql } from '@apollo/client';

export const USER_FIELDS = gql`
  fragment UserFields on UserNode {
    id
    activeRole
    username
    firstName
    middleName
    lastName
    email
    roles
    phone
    methodistGroupsCount
    student {
      groupsCount
    }
    teacher {
      photo
      groupsCount
    }
  }
`;
