import React, { memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import styled from 'styled-components';

import { LoginForm } from 'components/organisms';
import { authState, useAuthStep, useLogin, useSendAuthCode, useGoToMain, useToaster } from 'core';
import { Splash } from 'pages';
import { useReactiveVar } from '@apollo/client';
import { AUTHORIZATION_STEPS, STATE_ACTIONS_TYPE } from 'context';
import { useDemoLogin } from 'core/hooks/Auth/useDemoLogin';
import { ToastTypes } from 'atoms';
import { useTranslation } from 'react-i18next';

interface ILoginState {
  email: string;
  code: string;
  isEmailChanged: boolean;
}

const stateReducer = (
  state: ILoginState,
  action: { type: STATE_ACTIONS_TYPE; payload?: string },
) => {
  switch (action.type) {
    case STATE_ACTIONS_TYPE.changeEmail:
      return { ...state, email: action.payload || '', isEmailChanged: true };
    case STATE_ACTIONS_TYPE.changeCode:
      return { ...state, code: action.payload || '' };
    case STATE_ACTIONS_TYPE.setEmailNotChanged:
      return { ...state, isEmailChanged: false };
    default:
      throw new Error();
  }
};

export const Login = memo(() => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(stateReducer, {
    email: '',
    code: '',
    isEmailChanged: false,
  });

  const [showCodeError, setShowCodeError] = useState(false);
  const goToMain = useGoToMain();
  const { addToast } = useToaster();
  const { isLoggedIn } = useReactiveVar(authState);

  const [
    sendAuthCode,
    { success, time, isOutOfTime, waitingTimeForNextCode, loading: loadingCode, emailError },
  ] = useSendAuthCode();
  const [login, { success: successLogin, loading: loadingPass, codeError }] = useLogin();
  const [
    demoLogin,
    { success: demoLoginSuccess, loading: demoLoginLoading, error: demoLoginError },
  ] = useDemoLogin();
  const { step, isOpenInfo, setStep, onBackButtonClick, onHelpButtonClick } = useAuthStep();

  const errors = useMemo(() => {
    if (showCodeError && !state.code) {
      return { ...emailError, ...codeError };
    }

    if (!state.isEmailChanged) {
      return { ...emailError };
    }

    return {};
  }, [codeError, emailError, showCodeError, state.isEmailChanged, state.code]);

  const disableEmailSend = useMemo(() => {
    if (emailError && !state.isEmailChanged) {
      return true;
    }

    if (loadingCode) {
      return true;
    }

    if (!state.email) {
      return true;
    }

    return false;
  }, [emailError, state.isEmailChanged, state.email, loadingCode]);

  const onEmailChange = useCallback((event) => {
    dispatch({ type: STATE_ACTIONS_TYPE.changeEmail, payload: event.target.value });
  }, []);
  const onPassChange = useCallback((value) => {
    dispatch({ type: STATE_ACTIONS_TYPE.changeCode, payload: value });
  }, []);
  const onEmailSendClick = useCallback(() => {
    sendAuthCode(state.email);
    setShowCodeError(false);
    dispatch({ type: STATE_ACTIONS_TYPE.setEmailNotChanged });
    dispatch({ type: STATE_ACTIONS_TYPE.changeCode, payload: '' });
  }, [state.email, sendAuthCode]);
  const onPassSendClick = useCallback(() => {
    login({ email: state.email, authenticationCode: state.code });
  }, [state.email, login, state.code]);

  useEffect(() => {
    if ((success && !loadingCode) || emailError?.isServiceUnavailable) {
      setStep(AUTHORIZATION_STEPS.code);
    }
  }, [loadingCode, success, emailError?.isServiceUnavailable, setStep]);

  useEffect(() => {
    if (isLoggedIn) {
      goToMain();
    }
  }, [isLoggedIn, goToMain]);

  useEffect(() => {
    if (codeError || isOutOfTime) {
      dispatch({ type: STATE_ACTIONS_TYPE.changeCode, payload: '' });
    }
  }, [codeError, isOutOfTime]);

  useEffect(() => {
    if (!successLogin && codeError?.authenticationCode) {
      setShowCodeError(true);
    }
  }, [successLogin, codeError?.authenticationCode]);

  useEffect(() => {
    if (!demoLoginSuccess && demoLoginError) {
      addToast('demo_login', {
        type: ToastTypes.danger,
        text: `${t('error.demo')}`,
      });
    }
  }, [demoLoginError, demoLoginSuccess, addToast, t]);

  if (waitingTimeForNextCode === null) {
    return <Splash />;
  }

  return (
    <LoginContainer>
      <LoginForm
        values={{
          email: state.email,
          authenticationCode: state.code,
          time,
          waitingTimeForNextCode,
        }}
        onEmailChange={onEmailChange}
        onPassChange={onPassChange}
        onEmailSendClick={onEmailSendClick}
        onPassSendClick={onPassSendClick}
        onBackButtonClick={onBackButtonClick}
        onHelpButtonClick={onHelpButtonClick}
        isEmailSent={step === AUTHORIZATION_STEPS.code}
        disableEmailSend={disableEmailSend}
        disablePassSend={loadingPass}
        errors={errors}
        isOutOfTime={isOutOfTime}
        isOutOfAttempts={showCodeError && Boolean(codeError?.isOutOfAttempts)}
        isOpenInfo={isOpenInfo}
        isServiceUnavailable={Boolean(emailError?.isServiceUnavailable)}
        onSupportClick={demoLogin}
        isDemoLoading={demoLoginLoading}
      />
    </LoginContainer>
  );
});

const LoginContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0 100px 0;
  box-sizing: border-box;
`;
