import { useGroupLessonAttachmentForLmsQuery } from 'core/graphql';
import { LessonAttachmentType } from 'types';

type attachmentsType = {
  attachments: LessonAttachmentType[] | null;
  loading?: boolean;
  error?: string;
};

export const useGetAllAttachments = (
  groupLessonId: string,
  attachmentRole?: string,
): attachmentsType => {
  const { data, loading, error } = useGroupLessonAttachmentForLmsQuery({
    variables: { groupLessonId, attachmentRole },
  });

  return {
    attachments:
      data?.groupLessonAttachmentForLms?.reduce(
        (items: LessonAttachmentType[], attachmentData): LessonAttachmentType[] => {
          if (attachmentData) {
            return [
              ...items,
              {
                id: attachmentData.id,
                title: attachmentData.title,
                link: attachmentData.file,
              },
            ];
          }

          return items;
        },
        [],
      ) || null,
    loading,
    error: error?.message,
  };
};
