import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { Icon, IconNames } from 'atoms/Icon';
import fontService, { FontWeight } from 'services/FontService';
import { CLIENT_WIDTH, desktop, tablet, isLearnITTheme } from 'services/ui';
import { useWindowSize } from 'services/hooks';
import { Colors, UserRole } from 'context';
import { ExternalPaths } from 'router/external';

import { HeaderPreloader, IMenuItem, MenuItem } from 'components/atoms';

import { UserCard } from './UserCard';
import { MobileHeader } from './MobileHeader';

export interface IHeader {
  onExit: () => void;
  name: string;
  role: string;
  avatarImage?: string | null;
  menuItems: IMenuItem[];
  loading: boolean;
  hasRoles: UserRole[];
  onChangeRole: (role: UserRole) => void;
}

export const HeaderComponent = memo(
  ({ onExit, name, role, hasRoles, onChangeRole, avatarImage, menuItems, loading }: IHeader) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    return (
      <HeaderWrapper>
        {loading && <HeaderPreloader />}
        {!loading && width && width >= CLIENT_WIDTH.TABLET ? (
          <>
            <Container>
              <HeaderText>{t('header.text')}</HeaderText>
              <HeaderButton>
                <HeaderLink href={ExternalPaths.contact_us} target="_blank">
                  {t('header.contacts')}
                </HeaderLink>
                <HeaderExitButton onClick={onExit}>{t('header.exit')}</HeaderExitButton>
              </HeaderButton>
            </Container>
            <Separator />
            <Container>
              <Menu>
                <LogoWrapper>
                  <Icon name={IconNames.LogoHorisontal} width="100%" height="100%" />
                </LogoWrapper>
                <MenuItemList>
                  {menuItems.map((menuItem, index) => (
                    <MenuItem
                      key={index}
                      to={menuItem.to}
                      subMenuCount={menuItem.subMenuCount}
                      isActive={menuItem.isActive}
                      disabled={menuItem.disabled}>
                      {menuItem.children}
                    </MenuItem>
                  ))}
                </MenuItemList>
              </Menu>
              <UserCard
                name={name}
                role={role}
                hasRoles={hasRoles}
                avatarImage={avatarImage}
                onChangeRole={onChangeRole}
              />
            </Container>
          </>
        ) : (
          <MobileHeader
            name={name}
            activeRole={role}
            hasRoles={hasRoles}
            avatarImage={avatarImage}
            onChangeRole={onChangeRole}
            onExit={onExit}
            menuItems={menuItems}
          />
        )}
      </HeaderWrapper>
    );
  },
);

const HeaderWrapper = styled.div`
  max-width: 1110px;
  width: 100%;
  margin: 20px auto 0px;
  box-sizing: border-box;
  padding: 0 20px;

  ${tablet} {
    margin: 10px auto 0px;
  }

  ${desktop} {
    padding: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: inherit;
`;

const HeaderText = styled.p`
  margin: 0;
  padding: 0;
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })}
  color: ${Colors.gray};
`;

const HeaderButton = styled.div`
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })}
  color: ${Colors.black};
  display: flex;
`;
const HeaderLink = styled.a`
  margin-right: 24px;
  cursor: pointer;
  color: ${Colors.black};
  &:hover {
    transition: 0.5s;
    color: ${Colors.gray_dark};
  }
`;
const HeaderExitButton = styled.div`
  cursor: pointer;
  &:hover {
    transition: 0.5s;
    color: ${Colors.gray_dark};
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.system_gray};
  opacity: 0.5;
  margin: 10px 0 19px 0;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 268px;
  height: 28px;
  margin-right: 37px;

  ${desktop} {
    right: 20px;
  }
  ${() =>
    isLearnITTheme() &&
    css`
      ${tablet} {
        right: 0;
        width: 140px;
      }
      ${desktop} {
        width: 160px;
      }
    `}
`;

const Menu = styled.div`
  display: flex;
`;

const MenuItemList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0 0 0;
`;
