import { css } from 'styled-components';

export enum FontFamily {
  rubik = 'Rubik',
}

export enum FontWeight {
  bold = '700',
  medium = '500',
  regular = '400',
}

interface IFont {
  family?: FontFamily;
  size: number;
  lineHeight: number;
  weight?: FontWeight;
}

export enum TextType {
  extraSmall = 'extraSmall',
  small = 'small',
  normal = 'normal',
  medium = 'medium',
  large = 'large',
  caption = 'caption',
}

interface IText {
  type?: TextType;
  size?: number;
  weight?: FontWeight;
  lineHeight?: number;
}

class FontService {
  getFontFamily(family: FontFamily) {
    return css`
      font-family: ${family};
    `;
  }

  getFontSize(fontSize: number) {
    return css`
      font-size: ${fontSize}px;
    `;
  }

  getLineHeight(lineHeight: number) {
    return css`
      line-height: ${lineHeight}%;
    `;
  }

  getFontWeight(weight: FontWeight = FontWeight.regular) {
    return css`
      font-weight: ${weight};
    `;
  }

  getFont({ family = FontFamily.rubik, size, lineHeight, weight = FontWeight.regular }: IFont) {
    return css`
      ${this.getFontFamily(family)}
      ${this.getFontSize(size)}
      ${this.getLineHeight(lineHeight)}
      ${this.getFontWeight(weight)}
    `;
  }

  h1(lineHeight?: number) {
    return css`
      ${this.getFont({ size: 54, lineHeight: lineHeight || 115, weight: FontWeight.bold })}
    `;
  }

  h2(lineHeight?: number) {
    return css`
      ${this.getFont({ size: 32, lineHeight: lineHeight || 138, weight: FontWeight.bold })}
    `;
  }

  h3(lineHeight?: number) {
    return css`
      ${this.getFont({ size: 24, lineHeight: lineHeight || 125, weight: FontWeight.bold })}
    `;
  }

  text({ type, size, weight, lineHeight }: IText = {}) {
    switch (type) {
      case TextType.extraSmall:
        return css`
          ${this.getFont({ size: 11, lineHeight: lineHeight || 145, weight })}
        `;

      case TextType.small:
        return css`
          ${this.getFont({ size: 12, lineHeight: lineHeight || 150, weight })}
        `;

      case TextType.normal:
        return css`
          ${this.getFont({ size: 14, lineHeight: lineHeight || 150, weight })}
        `;

      case TextType.medium:
        return css`
          ${this.getFont({ size: 18, lineHeight: lineHeight || 133, weight: FontWeight.medium })}
        `;

      case TextType.large:
        return css`
          ${this.getFont({ size: 24, lineHeight: lineHeight || 150, weight })}
        `;

      case TextType.caption:
        return css`
          display: block;
          ${this.getFont({
            size: 14,
            lineHeight: lineHeight || 150,
            weight: weight || FontWeight.bold,
          })}
          text-align: left;
          text-transform: uppercase;
          letter-spacing: 0.08em;
        `;

      default:
        return css`
          ${this.getFont({ size: size || 18, lineHeight: lineHeight || 150, weight })}
        `;
    }
  }
}

const fontService = new FontService();
export default fontService;
