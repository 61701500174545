import React, { memo } from 'react';

import { Icon, IconNames, Modal } from 'atoms';
import styled from 'styled-components';
import fontService from 'services/FontService';
import { desktop, tablet } from 'services/ui';

export interface IPopupContainer {
  children: React.ReactNode;
  title: string;
  isShowing: boolean;
  modalHeight?: string;
  hasMargin?: boolean;
  align?: string;
  onClose: () => void;
}

export const PopupContainer = memo(
  ({ children, title, isShowing, modalHeight, hasMargin, align, onClose }: IPopupContainer) => (
    <Modal isShowing={isShowing} align={align} modalHeight={modalHeight} hasMargin={hasMargin}>
      <Header>
        <Title>{title}</Title>
        <CloseButton onClick={onClose}>
          <Icon name={IconNames.Close} width={20} height={20} />
        </CloseButton>
      </Header>

      <Content>{children}</Content>
    </Modal>
  ),
);

const Header = styled.div`
  position: relative;
  padding: 24px 20px 0;

  ${tablet} {
    padding: 40px 40px 0;
  }

  ${desktop} {
    padding: 48px 60px 0;
    margin-bottom: 32px;
  }
`;

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  ${tablet} {
    top: 40px;
    right: 40px;
  }
`;

const Content = styled.div``;

const Title = styled.h2`
  width: 92%;
  margin: 0;
  ${fontService.h3(133)};

  ${tablet} {
    ${fontService.h2()};
  }
`;
