import { GroupForLmsQuery, GroupLessonType } from 'core/graphql';
import { isBeforeNow } from 'services/DateService';
import { GroupTypes, LessonType } from 'components/types';
import { ICourseInfo } from 'molecules/CourseProgram/CourseInfo';
import { ProgramLessonType } from 'components/types/lesson';

export const transformCourse = (data: GroupForLmsQuery | undefined): ICourseInfo => {
  if (data?.groupForLms?.__typename) {
    const groupType: GroupTypes = data.groupForLms.__typename as GroupTypes;

    const result: ProgramLessonType[] =
      // @ts-ignore
      data.groupForLms.lessons?.reduce(
        (lessons: LessonType[], lessonData: GroupLessonType, index: number) => {
          const isEnded = isBeforeNow(lessonData.date);

          const accumulator: ProgramLessonType = {
            id: lessonData.id,
            name: lessonData.name,
            index: lessonData.index || index,
            isEnded,
            isActive: !isEnded,
            needToFinish: groupType === GroupTypes.GroupType ? lessonData.needToFinish : null,
          };

          return [...lessons, accumulator];
        },
        [],
      ) || [];

    return {
      courseImageURL: data.groupForLms.course.courseIcon || '',
      courseName: data.groupForLms.course.name || '',
      groupName: data.groupForLms.name || '',
      studentsCount: data.groupForLms.studentsCount || 0,
      passedLessonsCount: data.groupForLms.passedLessonsCount || 0,
      lessons: result,
      lessonsCount: data.groupForLms.lessonsCount || 0,
      isFinished: data.groupForLms.statusFinished,
    };
  }

  return {
    courseImageURL: '',
    courseName: '',
    groupName: '',
    studentsCount: 0,
    passedLessonsCount: 0,
    lessons: [],
    lessonsCount: 0,
  };
};
