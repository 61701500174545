import React, { memo } from 'react';

import styled from 'styled-components';
import { IResumeFields, Colors, ISocialMedia } from 'context';
import fontService, { FontWeight } from 'services/FontService';

import { Input, Button } from 'atoms';
import { InputLabel } from 'atoms/Input/Input';
import { PhotoUpload } from 'molecules';
import { FormFieldset } from 'organisms/ResumeForm/FormFieldset';
import { isPhoneInputValid } from 'organisms/PersonalInfoBlock/utils';
import { tablet } from 'services/ui';

export interface IPersonalInfoValues {
  image: File | string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  socialMedia: ISocialMedia[];
}

export interface IPersonalInfoBlock {
  values: IPersonalInfoValues;
  fields: IResumeFields;
  errors?: {
    [key in keyof IPersonalInfoValues]?: string;
  };
  removeImageError: boolean;
  showAddSocialMedia: boolean;
  onAddSocialMedia: () => void;
  onInputChange: (name: string, value: string | File) => void;
  children: React.ReactNode;
}

export const PersonalInfoBlockComponent = memo(
  ({
    fields,
    errors,
    removeImageError,
    values,
    showAddSocialMedia,
    onAddSocialMedia,
    onInputChange,
    children,
  }: IPersonalInfoBlock) => (
    <FormFieldset groupTitle={fields.personalInfo.fieldsetTitle}>
      <PhotoUpload
        image={values.image}
        removeError={removeImageError}
        titleText={fields.personalInfo.photo.title}
        infoText={fields.personalInfo.photo.info}
        uploadBtnText={fields.personalInfo.photo.uploadButton}
        clearBtnText={fields.personalInfo.photo.clearButton}
        onFileChange={(file) => onInputChange('image', file)}
      />

      <MainInfo>
        <MainInfoPart>
          <StyledInput
            type="text"
            value={values.firstName}
            onChange={({ target: { value } }) => onInputChange('firstName', value)}
            required
            max={50}
            label={fields.personalInfo.firstName.label}
            placeholder={fields.personalInfo.firstName.placeholder}
            hasError={Boolean(errors?.firstName)}
            message={errors?.firstName}
          />
          <StyledInput
            type="text"
            value={values.lastName}
            onChange={({ target: { value } }) => onInputChange('lastName', value)}
            required
            max={50}
            label={fields.personalInfo.lastName.label}
            placeholder={fields.personalInfo.lastName.placeholder}
            hasError={Boolean(errors?.lastName)}
            message={errors?.lastName}
          />
        </MainInfoPart>

        <MainInfoPart>
          <StyledInput
            type="text"
            value={values.phone}
            onChange={({ target: { value } }) =>
              isPhoneInputValid(value) && onInputChange('phone', value)
            }
            required
            max={30}
            label={fields.personalInfo.phone.label}
            placeholder={fields.personalInfo.phone.placeholder}
            hasError={Boolean(errors?.phone)}
            message={errors?.phone}
          />
          <StyledInput
            type="text"
            value={values.email}
            onChange={({ target: { value } }) => onInputChange('email', value)}
            required
            label={fields.personalInfo.email.label}
            placeholder={fields.personalInfo.email.placeholder}
            hasError={Boolean(errors?.email)}
            message={errors?.email}
          />
        </MainInfoPart>
      </MainInfo>
      <Position>
        <StyledInput
          type="text"
          value={values.position}
          onChange={({ target: { value } }) => onInputChange('position', value)}
          required
          max={100}
          label={fields.personalInfo.position.label}
          placeholder={fields.personalInfo.position.placeholder}
          hasError={Boolean(errors?.position)}
          message={errors?.position}
        />
      </Position>

      <Separator />

      <SocialMediaWrapper>
        <Label>{fields.personalInfo.socialMedia.label}</Label>
        <InputsWrapper>{children}</InputsWrapper>

        {showAddSocialMedia && (
          <StyledButton variant="underline" onClick={onAddSocialMedia} sidePaddings={0}>
            {fields.personalInfo.socialMedia.addLinkButton}
          </StyledButton>
        )}
      </SocialMediaWrapper>
    </FormFieldset>
  ),
);

const StyledInput = styled(Input)`
  ${InputLabel} {
    font-weight: ${FontWeight.medium};
  }
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

const MainInfoPart = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  ${tablet} {
    flex-direction: row;

    ${StyledInput} {
      width: 50%;
    }
  }
`;

const Position = styled.div`
  margin-bottom: 32px;
`;

const SocialMediaWrapper = styled.div``;

const Label = styled.p`
  color: ${Colors.black};
  background-color: transparent;
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.medium })};
  margin: 0 0 4px 0;
`;

const InputsWrapper = styled.div``;

const StyledButton = styled(Button)`
  max-width: max-content;
  margin-top: 24px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.system_gray};
  margin: 32px 0;

  ${tablet} {
    display: none;
  }
`;
