import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import styled from 'styled-components';
import fontService, { FontWeight, TextType } from 'services/FontService';
import { desktop, mobile, tablet } from 'services/ui';
import { getResumeErrorsTranslated } from 'services/TranslationService';
import { Languages, NetworkErrors, ResumeFieldErrors, UserRole } from 'context';
import { ToastTypes } from 'atoms';
import { ResumeDownloadModal, ResumeGenerationPopup, ResumeLanguage } from 'molecules';
import { IResumeFormValues, LeaveResumePagePopup, ResumeForm } from 'organisms';
import {
  appState,
  authState,
  useDownloadResume,
  useDownloadResumeFile,
  useGetResumeData,
  useModal,
  useToaster,
  useUploadResume,
  useUser,
} from 'core';
import {
  initialEducation,
  initialExperience,
  initialLanguage,
  initialSocialMedia,
} from './constants';

export const Resume = memo(() => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<Languages>(
    process.env.REACT_APP_LANG === 'pl' ? Languages.english : Languages.russian,
  );
  const [removeImageError, setRemoveImageError] = useState<boolean>(false);

  const { active_role, isDemoUser } = useReactiveVar(authState);
  const currentState = appState();
  const { data } = useGetResumeData(language);
  const { user } = useUser();
  const [uploadResume, { success, error }] = useUploadResume(language);
  const [downloadResumeFile, { error: downloadFileError, loading: downloadFileLoading }] =
    useDownloadResumeFile();
  const [downloadResume, { error: downloadError, loading: downloadLoading }] =
    useDownloadResume(language);

  const { addToast } = useToaster();
  const { isShowing, toggle } = useModal();
  const { isShowing: downloadIsShowing, toggle: downloadToggle } = useModal();

  const initialValues: IResumeFormValues = useMemo(
    () => ({
      image: data?.image || '',
      firstName: data?.firstName || user?.firstName || '',
      lastName: data?.lastName || user?.lastName || '',
      email: data?.email || user?.email || '',
      phone: data?.phone || user?.phone || '',
      position: data?.position || '',
      socialMedia: data?.socialMedia.length ? data.socialMedia : initialSocialMedia,
      experience: data?.experience.length ? data.experience : initialExperience,
      education: data?.education.length ? data.education : initialEducation,
      about: data?.about || '',
      languages: data?.languages.length ? data.languages : initialLanguage,
      skills: data?.skills || [],
      tools: data?.tools || [],
    }),
    [data, user],
  );

  const resumeValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(getResumeErrorsTranslated(ResumeFieldErrors.emptyField)),
    lastName: Yup.string().required(getResumeErrorsTranslated(ResumeFieldErrors.emptyField)),
    email: Yup.string().required(getResumeErrorsTranslated(ResumeFieldErrors.emptyField)),
    phone: Yup.string().required(getResumeErrorsTranslated(ResumeFieldErrors.emptyField)),
    position: Yup.string().required(getResumeErrorsTranslated(ResumeFieldErrors.emptyField)),
  });

  const onSubmitHandler = useCallback(
    (resumeData: IResumeFormValues) => {
      uploadResume(resumeData);
      setRemoveImageError(true);
    },
    [uploadResume],
  );

  const { values, errors, setFieldValue, handleSubmit, dirty, validateForm } =
    useFormik<IResumeFormValues>({
      initialValues,
      validateOnBlur: false,
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: resumeValidationSchema,
      onSubmit: onSubmitHandler,
    });

  useEffect(() => {
    if (!success && error) {
      addToast('save_resume', {
        type: ToastTypes.danger,
        text: `${t('resume.saveError')}`,
      });
    }

    if (success) {
      addToast('save_resume', {
        type: ToastTypes.success,
        text: `${t('resume.saveSuccess')}`,
      });
    }
  }, [addToast, error, success, t]);

  useEffect(() => {
    if (active_role !== UserRole.STUDENT || isDemoUser) {
      appState({
        ...currentState,
        networkError: NetworkErrors.pageIsNotAvailable,
      });
    }
  }, [active_role, currentState, isDemoUser]);

  const onContinueLeavePage = useCallback(() => {
    setLanguage((prevState) =>
      prevState === Languages.english ? Languages.russian : Languages.english,
    );
    toggle();
  }, [toggle]);

  const onChange = useCallback(
    (lang: Languages) => {
      if (dirty) {
        toggle();
        return;
      }

      setLanguage(lang);
    },
    [dirty, toggle],
  );

  const onDownloadHandler = useCallback(() => {
    setRemoveImageError(true);
    validateForm().then((validationErrors) => {
      if (Object.keys(validationErrors).length === 0) {
        downloadResume({
          isDataDirty: dirty,
          values,
          language,
          closeModalCallback: downloadToggle,
        });
      }
    });
  }, [downloadResume, values, dirty, language, downloadToggle, validateForm]);

  const onDownloadAgainClick = useCallback(() => {
    downloadToggle();
    downloadResumeFile(language);
  }, [downloadResumeFile, language, downloadToggle]);

  return (
    <ResumePageWrapper>
      <ResumeHeader>
        <HeaderText>
          <PageTitle>{t('resume.yourResume')}</PageTitle>
          <PageInfo>{t('resume.info')}</PageInfo>
        </HeaderText>

        <ResumeLanguage language={language} onLanguageChange={onChange} />
      </ResumeHeader>

      <ResumeForm
        language={language}
        values={values}
        errors={errors}
        removeImageError={removeImageError}
        onInputChange={setFieldValue}
        onSaveResume={handleSubmit}
        onDownloadResume={onDownloadHandler}
      />

      <LeaveResumePagePopup
        isShowing={isShowing}
        onClose={toggle}
        onContinue={onContinueLeavePage}
      />

      {(downloadFileLoading || downloadLoading) && <ResumeDownloadModal />}

      <ResumeGenerationPopup
        isShowing={downloadIsShowing}
        isSuccessful={!downloadFileError && !downloadError}
        onClose={downloadToggle}
        onDownload={onDownloadAgainClick}
      />
    </ResumePageWrapper>
  );
});

const ResumePageWrapper = styled.div`
  width: 100%;
  margin-bottom: 80px;

  ${desktop} {
    margin-bottom: 104px;
  }
`;

const ResumeHeader = styled.div`
  margin: 32px 0;
  display: flex;
  flex-direction: column;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 40px 0 32px 0;
  }

  ${desktop} {
    margin: 54px 0 32px 0;
  }
`;

const HeaderText = styled.div`
  ${mobile} {
    margin-bottom: 16px;
  }
`;

const PageTitle = styled.h2`
  margin: 0 0 4px 0;
  ${fontService.text({ size: 18, lineHeight: 133, weight: FontWeight.bold })}

  ${tablet} {
    margin: 0 0 8px 0;
    ${fontService.h3()}
  }

  ${desktop} {
    ${fontService.h2()}
  }
`;

const PageInfo = styled.p`
  ${fontService.text({ type: TextType.small, weight: FontWeight.regular })}

  ${tablet} {
    ${fontService.text({ type: TextType.normal, weight: FontWeight.regular })}
  }

  ${desktop} {
    ${fontService.text({ size: 18, lineHeight: 133, weight: FontWeight.regular })}
  }
`;
