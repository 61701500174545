import React, { memo, useCallback } from 'react';

import { nanoid } from 'nanoid';

import { IExperienceProject, IResumeDate, IResumeFields, Languages } from 'context';
import { IExperienceValues } from 'organisms';
import { ExperienceBlockComponent } from './ExperienceBlock';

interface IExperienceBlockContainer {
  language: Languages;
  fields: IResumeFields;
  values: IExperienceValues[];
  onExperienceChange: (name: string, value: IExperienceValues[]) => void;
  onInputChange: (name: string, value: string | IResumeDate | IExperienceValues[]) => void;
}

const initialExperience = {
  company: '',
  position: '',
  startDate: {
    year: '',
    month: '',
  },
  endDate: {
    year: '',
    month: '',
  },
  projects: [],
};

export const ExperienceBlock = memo(
  ({ language, fields, values, onInputChange, onExperienceChange }: IExperienceBlockContainer) => {
    const onChangeProjects = useCallback(
      (experienceId: string, projects: IExperienceProject[]) => {
        onExperienceChange(
          'experience',
          values.map((item) => (item.id === experienceId ? { ...item, projects } : item)),
        );
      },
      [values, onExperienceChange],
    );

    const onRemoveExperience = useCallback(
      (experienceId: string) => {
        onExperienceChange(
          'experience',
          values.filter(({ id }) => id !== experienceId),
        );
      },
      [values, onExperienceChange],
    );

    const onAddExperience = useCallback(() => {
      onExperienceChange('experience', [...values, { id: nanoid(), ...initialExperience }]);
    }, [values, onExperienceChange]);

    return (
      <ExperienceBlockComponent
        language={language}
        fields={fields}
        experience={values}
        onChangeProjects={onChangeProjects}
        onRemoveExperience={onRemoveExperience}
        onAddExperience={onAddExperience}
        onInputChange={onInputChange}
      />
    );
  },
);
