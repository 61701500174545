import React, { memo } from 'react';

import styled from 'styled-components';
import { CLIENT_WIDTH, desktop, tablet } from 'services/ui';
import { useWindowSize } from 'services/hooks';
import { Colors } from 'context';

import { CourseInfo, ICourseInfo } from './CourseInfo';
import { Lessons } from './Lessons';
import { MobileLessons } from './MobileLessons';

export interface ICourseProgram extends ICourseInfo {
  activeLessonID?: string;
  children?: React.ReactElement;
}

export const CourseProgram = memo(
  ({
    courseName,
    groupName,
    studentsCount,
    passedLessonsCount,
    courseImageURL,
    lessons,
    lessonsCount,
  }: ICourseProgram) => {
    const { width } = useWindowSize();
    return (
      <CourseProgramContainer>
        <CourseInfo
          courseImageURL={courseImageURL}
          courseName={courseName}
          groupName={groupName}
          studentsCount={studentsCount}
          passedLessonsCount={passedLessonsCount}
          lessons={lessons}
          lessonsCount={lessonsCount}
        />
        {width && width >= CLIENT_WIDTH.DESKTOP ? (
          <Lessons lessons={lessons} />
        ) : (
          <MobileLessons lessons={lessons} />
        )}
      </CourseProgramContainer>
    );
  },
);

const CourseProgramContainer = styled.div`
  background-color: ${Colors.bg_gray};
  border-radius: 16px;
  padding: 24px 20px;
  margin-bottom: 20px;

  ${tablet} {
    border-radius: 24px;
    padding: 32px;
  }

  ${desktop} {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
    width: 301px;
  }
`;
