import { isTMSTheme } from 'services/ui';

import { ReactComponent as LogoVerticalTMS } from 'assets/icons/logo-vertical.svg';
import { ReactComponent as LogoLearnIT } from 'assets/icons/logo_learnit.svg';
import { ReactComponent as LogoHorisontalTMS } from 'assets/icons/logo-horisontal.svg';
import { ReactComponent as LogoHorisontalMobileTMS } from 'assets/icons/logo-horisontal_mobile.svg';

import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import { ReactComponent as Error404 } from 'assets/icons/error404.svg';
import { ReactComponent as Error500 } from 'assets/icons/error500.svg';
import { ReactComponent as Error503 } from 'assets/icons/error503.svg';
import { ReactComponent as ErrorTMS } from 'assets/icons/error.svg';
import { ReactComponent as ErrorLearnIT } from 'assets/icons/error_learnit.svg';
import { ReactComponent as SplashTMS } from 'assets/icons/splash.svg';

const LogoVertical = isTMSTheme() ? LogoVerticalTMS : LogoLearnIT;
const LogoHorisontal = isTMSTheme() ? LogoHorisontalTMS : LogoLearnIT;
const LogoHorisontalMobile = isTMSTheme() ? LogoHorisontalMobileTMS : LogoLearnIT;
const Error = isTMSTheme() ? ErrorTMS : ErrorLearnIT;
const Splash = isTMSTheme() ? SplashTMS : LogoLearnIT;

export {
  LogoVertical,
  LogoHorisontal,
  LogoHorisontalMobile,
  Loading,
  Error,
  Error500,
  Error503,
  Error404,
  Splash,
};

export { ReactComponent as FB } from 'assets/icons/fb.svg';
export { ReactComponent as Google } from 'assets/icons/google.svg';
export { ReactComponent as OK } from 'assets/icons/ok.svg';
export { ReactComponent as VK } from 'assets/icons/vk.svg';
export { ReactComponent as Yandex } from 'assets/icons/yandex.svg';
export { ReactComponent as LinkedIn } from 'assets/icons/lkd.svg';
export { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
export { ReactComponent as Telegram } from 'assets/icons/tlg.svg';
export { ReactComponent as Twitter } from 'assets/icons/tw.svg';
export { ReactComponent as YT } from 'assets/icons/yt.svg';
export { ReactComponent as WhatsApp } from 'assets/icons/whatsapp.svg';
export { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
export { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
export { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
export { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
export { ReactComponent as Check } from 'assets/icons/check.svg';
export { ReactComponent as Close } from 'assets/icons/close.svg';
export { ReactComponent as Fire } from 'assets/icons/fire.svg';
export { ReactComponent as Phone } from 'assets/icons/phone.svg';
export { ReactComponent as Rectangle } from 'assets/icons/rectangle.svg';
export { ReactComponent as Support } from 'assets/icons/support.svg';
export { ReactComponent as Avatar } from 'assets/icons/avatar.svg';
export { ReactComponent as Timer } from 'assets/icons/timer.svg';
export { ReactComponent as TimerEnd } from 'assets/icons/timerend.svg';
export { ReactComponent as TimerInProcess } from 'assets/icons/timerinprocess.svg';
export { ReactComponent as ArrowLeftSimple } from 'assets/icons/arrow_left_simple.svg';
export { ReactComponent as Warning } from 'assets/icons/warning.svg';
export { ReactComponent as WhiteCheck } from 'assets/icons/white_check.svg';
export { ReactComponent as WhiteWarning } from 'assets/icons/white_warning.svg';
export { ReactComponent as WhiteClose } from 'assets/icons/white_close.svg';
export { ReactComponent as Folder } from 'assets/icons/folder.svg';
export { ReactComponent as SmallScreen } from 'assets/icons/smallScreen.svg';
export { ReactComponent as Note } from 'assets/icons/note.svg';
export { ReactComponent as TriangleDown } from 'assets/icons/triangle_down.svg';
export { ReactComponent as TriangleUp } from 'assets/icons/triangle_up.svg';
export { ReactComponent as Photo } from 'assets/icons/photo_default.svg';
export { ReactComponent as GrayCloseSm } from 'assets/icons/close_gray_sm.svg';
export { ReactComponent as CloseSm } from 'assets/icons/close_sm.svg';
export { ReactComponent as GreenCheck } from 'assets/icons/check_green.svg';
export { ReactComponent as RedError } from 'assets/icons/error_red.svg';
export { ReactComponent as WarningYellow } from 'assets/icons/warning_yellow.svg';
export { ReactComponent as LoadingGreen } from 'assets/icons/loading_green.svg';
export { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
export { ReactComponent as Completed } from 'assets/icons/status-completed.svg';
export { ReactComponent as Waiting } from 'assets/icons/status-waiting.svg';
export { ReactComponent as Rejected } from 'assets/icons/status-rejected.svg';
export { ReactComponent as BurgerMenu } from 'assets/icons/burger.svg';
export { ReactComponent as UnfoldMore } from 'assets/icons/unfold-more.svg';
export { ReactComponent as Create } from 'assets/icons/create.svg';
