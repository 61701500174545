import { useMemo } from 'react';

import { IResumeFormValues } from 'organisms';
import { Languages } from 'context';
import { useStudentResumeQuery } from 'core';
import { transformResumeData } from './transform';

type resumeType = {
  data: IResumeFormValues | null;
  loading?: boolean;
  error?: string;
};

export const useGetResumeData = (language: Languages): resumeType => {
  const { data, loading, error } = useStudentResumeQuery({
    variables: {
      language,
    },
  });

  const resumeData = useMemo(() => transformResumeData(data), [data]);

  return {
    data: resumeData,
    loading,
    error: error?.message,
  };
};
