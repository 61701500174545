import React, { memo, useEffect, useState } from 'react';

import { ResumeEducationCardComponent, IEducationValues } from 'organisms';
import { IResumeDate, IResumeFields, Languages } from 'context';

interface IResumeExperienceCardContainer {
  language: Languages;
  fields: IResumeFields;
  index: number;
  values: IEducationValues;
  onRemoveEducation: (id: string) => void;
  onInputChange: (name: string, value: string | IResumeDate) => void;
}

export const ResumeEducationCard = memo(
  ({
    language,
    fields,
    values,
    index,
    onInputChange,
    onRemoveEducation,
  }: IResumeExperienceCardContainer) => {
    const [startDate, setStartDate] = useState<boolean>(false);

    useEffect(() => {
      setStartDate(Boolean(values.startDate.year));
      if (!values.startDate.year) {
        onInputChange(`education[${index}].endDate`, { year: '', month: '' });
      }
    }, [values.startDate.year, index, onInputChange]);

    return (
      <ResumeEducationCardComponent
        language={language}
        fields={fields}
        values={values}
        index={index}
        onInputChange={onInputChange}
        isEndDateDisabled={!startDate}
        onRemoveEducation={onRemoveEducation}
      />
    );
  },
);
