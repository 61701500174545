import React, { memo } from 'react';

import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { CLIENT_WIDTH, tablet } from 'services/ui';

import { Colors, IExperienceProject, IResumeFields } from 'context';
import { Button, Icon, IconNames } from 'atoms';
import { useWindowSize } from 'services/hooks';

export interface IProjectCard {
  fields: IResumeFields;
  project: IExperienceProject;
  onRemove: (id: string) => void;
  onEditProject: (project: IExperienceProject) => void;
}

export const ProjectCard = memo(({ fields, project, onRemove, onEditProject }: IProjectCard) => {
  const { width } = useWindowSize();
  return (
    <Wrapper>
      <Header>
        <Title>
          <ProjectName>{project.name}</ProjectName>
          <EditButton
            variant="outline"
            colors={{ borderColor: Colors.gray }}
            onClick={() => onEditProject(project)}>
            {width && width >= CLIENT_WIDTH.TABLET ? (
              fields.experience.project.editButtonText
            ) : (
              <Icon name={IconNames.Create} width={12} height={12} />
            )}
          </EditButton>
        </Title>
        <RemoveButton onClick={() => onRemove(project.id)}>
          <Icon name={IconNames.GrayCloseSm} width={16} height={16} />
        </RemoveButton>
      </Header>

      <Content>
        <Main>
          <MainPart>
            <Label>{fields.experience.project.problem.label}</Label>
            <Description>{project.problem}</Description>
          </MainPart>
          <MainPart>
            <Label>{fields.experience.project.solution.label}</Label>
            <DescriptionLink>{project.solution}</DescriptionLink>
          </MainPart>
        </Main>
        <TagsContainer>
          {project.technologies.map((item) => (
            <Tag key={item}>{item}</Tag>
          ))}
        </TagsContainer>
      </Content>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 16px 16px 24px;
  border: 1px solid #e7ebef;
  border-radius: 8px;
  background-color: ${Colors.white};

  ${tablet} {
    padding: 24px 24px 32px;
  }
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const RemoveButton = styled.div`
  cursor: pointer;
  &:hover {
    & svg > * {
      transition: 0.5s;
      fill: ${Colors.black};
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${tablet} {
    gap: 16px;
  }
`;

const ProjectName = styled.h4`
  white-space: nowrap;
  margin: 0;
  ${fontService.text({ size: 16, weight: FontWeight.medium, lineHeight: 125 })};
`;

const EditButton = styled(Button)`
  min-height: 24px;
  padding: 5px;
  border-radius: 4px;

  ${tablet} {
    padding: 0 12px;
    border-radius: 8px;
    ${fontService.text({ size: 12, weight: FontWeight.medium, lineHeight: 133 })};
  }
`;

const Content = styled.div``;

const Main = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${tablet} {
    flex-direction: row;
    gap: 24px;
  }
`;

const TagsContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Tag = styled.li`
  margin: 0;
  max-height: 22px;
  padding: 4px 8px;
  background-color: ${Colors.main};
  ${fontService.text({ size: 14, lineHeight: 100, weight: FontWeight.regular })};
  border-radius: 4px;
  color: ${Colors.btn_text};
`;

const MainPart = styled.div`
  width: 100%;

  ${tablet} {
    width: 50%;
  }
`;

const Label = styled.p`
  ${fontService.text({ size: 14, weight: FontWeight.regular })};
  color: ${Colors.gray};
  margin-bottom: 4px;
`;

const Description = styled.p`
  ${fontService.text({ size: 14, weight: FontWeight.regular })};
`;

const DescriptionLink = styled.p`
  ${fontService.text({ size: 14, weight: FontWeight.regular })};
  overflow-wrap: break-word;
`;
