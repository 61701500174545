import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet, mobile } from 'services/ui';

import { Button, ButtonSizes, DatePicker, RadioButton } from 'atoms';
import { HomeworkGrades } from 'molecules';
import { Colors } from 'context';
import { HomeworkGradesType, HomeworksType } from 'types';
import { parseDateFromDatePicker } from 'services/DateService';

export interface IHomeworkCheck {
  students?: HomeworksType[];
  lessonDate: Date;
  checkDate: Date;
  isHomeworkChecked: boolean;
  allHomeworksDone: boolean;
  allHomeworksHaveEdits: boolean;
  onEditHomeworkGrades: () => void;
  onCheckAllHomeworksDone: () => void;
  onCheckAllHomeworksHaveEdits: () => void;
  onUncheckAllHomeworks: () => void;
  onChangeGrade: (id: string, grade: HomeworkGradesType) => void;
  onSave: () => void;
  onCancel: () => void;
  onChangeDate: (date: Date | null) => void;
  hasError: boolean;
  isEditMode: boolean;
  addMarksLoading: boolean;
}

export const HomeworkCheckComponent = memo(
  ({
    students,
    lessonDate,
    checkDate,
    isHomeworkChecked,
    allHomeworksDone,
    allHomeworksHaveEdits,
    onEditHomeworkGrades,
    onCheckAllHomeworksDone,
    onCheckAllHomeworksHaveEdits,
    onUncheckAllHomeworks,
    onChangeGrade,
    onChangeDate,
    onCancel,
    onSave,
    hasError,
    isEditMode,
    addMarksLoading,
  }: IHomeworkCheck) => {
    const { t } = useTranslation();
    const dateString = useMemo(() => parseDateFromDatePicker(checkDate), [checkDate]);
    return (
      <Wrapper>
        <HeaderWrapper>
          <Title>{t('tasks.homework.checkHomework')}</Title>
          {isHomeworkChecked && (
            <EditButton
              variant="outline"
              size={ButtonSizes.sm}
              colors={{ borderColor: Colors.gray }}
              sidePaddings={12}
              onClick={onEditHomeworkGrades}>
              {t('tasks.homework.button.edit')}
            </EditButton>
          )}
        </HeaderWrapper>
        <RadioButtonWrapper>
          <RadioButtonStyled
            checked={allHomeworksDone}
            text={t('tasks.homework.allHomeworksDone')}
            disabled={isHomeworkChecked}
            onClick={onCheckAllHomeworksDone}
          />
          <RadioButtonStyled
            checked={allHomeworksHaveEdits}
            text={t('tasks.homework.allHomeworksHaveEdits')}
            disabled={isHomeworkChecked}
            onClick={onCheckAllHomeworksHaveEdits}
          />
          <RadioButtonStyled
            checked={!allHomeworksDone && !allHomeworksHaveEdits}
            text={t('tasks.homework.partialHomeworksDone')}
            disabled={isHomeworkChecked}
            onClick={onUncheckAllHomeworks}
          />
        </RadioButtonWrapper>

        <StudentsContainer
          students={students}
          isHomeworkChecked={isHomeworkChecked}
          onChangeGrade={onChangeGrade}
        />

        <CheckDateWrapper>
          <Title>{t('tasks.homework.date')}</Title>
          <DateWrapper>
            {isHomeworkChecked ? (
              dateString
            ) : (
              <StyledDatePicker
                value={checkDate}
                onChange={onChangeDate}
                disabledDate={(date) => date < lessonDate}
              />
            )}
          </DateWrapper>
        </CheckDateWrapper>

        {!isHomeworkChecked && (
          <Footer>
            <ButtonsWrapper>
              {hasError && <ErrorText>{t('tasks.homework.validation')}</ErrorText>}
              <Buttons>
                {isEditMode && (
                  <StyledCancelButton onClick={onCancel} disabled={addMarksLoading} variant="text">
                    {t('tasks.homework.button.cancel')}
                  </StyledCancelButton>
                )}
                <Button onClick={onSave} disabled={addMarksLoading}>
                  {t('tasks.homework.button.save')}
                </Button>
              </Buttons>
            </ButtonsWrapper>
          </Footer>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  ${desktop} {
    width: 700px;
  }
`;

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${tablet} {
    flex-direction: row;
    gap: 12px;
  }
`;

const Title = styled.h3`
  margin: 0;
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}

  ${tablet} {
    ${fontService.h3()}
  }
`;

const EditButton = styled(Button)`
  width: 100%;
  min-height: 48px;
  ${fontService.text({ size: 16, weight: FontWeight.medium, lineHeight: 150 })};

  ${tablet} {
    min-height: 24px;
    width: max-content;
    ${fontService.text({ size: 12, weight: FontWeight.medium, lineHeight: 133 })};
  }
`;

const RadioButtonStyled = styled(RadioButton)`
  width: fit-content;
`;

const RadioButtonWrapper = styled.div`
  ${RadioButtonStyled}:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StudentsContainer = styled((props) => <HomeworkGrades {...props} />)`
  margin-bottom: 32px;

  ${tablet} {
    margin-bottom: 40px;
  }

  ${desktop} {
    margin-bottom: 68px;
  }
`;

const CheckDateWrapper = styled.div``;

const DateWrapper = styled.div`
  margin-top: 8px;
  ${fontService.text({ size: 16, weight: FontWeight.regular, lineHeight: 150 })};

  ${tablet} {
    margin-top: 24px;
    ${fontService.text({ size: 18, weight: FontWeight.regular, lineHeight: 133 })};
  }
`;

const Footer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: ${Colors.bg_gray};
  padding: 20px 20px 32px;

  ${tablet} {
    height: 76px;
    padding: 0 32px;
  }

  ${desktop} {
    padding: 0 0 0 60px;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  row-gap: 16px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }

  ${desktop} {
    width: 700px;
  }
`;

const ErrorText = styled.p`
  color: ${Colors.signal_red};
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })}
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  width: 100%;

  ${tablet} {
    margin-left: auto;
    flex-direction: row;
    gap: 16px;
    width: unset;
  }
`;

const StyledCancelButton = styled(Button)`
  ${mobile} {
    border: 1px solid ${Colors.gray};
    border-radius: 8px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  margin-bottom: 176px;

  ${tablet} {
    margin-bottom: 80px;
  }

  ${desktop} {
    margin: 0;
  }
`;
