import { UserRole } from 'context';

export interface IColors {
  defaultColor?: string;
  activeColor?: string;
  color?: string;
  backgroundColor?: string;
  bgDisabledColor?: string;
  disabledColor?: string;
  borderColor?: string;
}

export interface IUserData {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  name: string;
  role: string;
  hasRoles: UserRole[];
  avatarImage?: string | null;
  groupsCount: number;
}

export enum GroupTypes {
  GroupType = 'GroupType',
  StudentGroupType = 'StudentGroupType',
  MethodistGroupType = 'MethodistGroupType',
}

export enum LessonTypes {
  GroupLessonForLMSType = 'GroupLessonForLMSType',
  StudentLessonType = 'StudentLessonType',
  MethodistLessonType = 'MethodistLessonType',
}
