import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Icon, IconNames } from 'atoms';
import { PinInputLMS } from 'molecules';
import { Colors } from 'context';
import fontService, { TextType } from 'services/FontService';

interface IPassStep {
  pass: string;
  email: string;
  timeLeft: string;
  isOutOfTime: boolean;
  isOutOfAttempts: boolean;
  onPassChange: (value: string) => void;
  onPassSendClick: () => void;
  onEmailSendClick: () => void;
  buttonDisabled: boolean;
  errorMessage?: string;
  onBackButtonClick?: () => void;
  onHelpButtonClick?: () => void;
  isServiceUnavailable?: boolean;
  isOpenInfo: boolean;
  waitingTimeForNextCode: number;
}

const PASS_LENGTH = 6;

export const PassStep = memo(
  ({
    pass,
    email,
    timeLeft,
    isOutOfTime,
    isOutOfAttempts,
    onPassChange,
    onPassSendClick,
    buttonDisabled,
    errorMessage,
    onBackButtonClick,
    onHelpButtonClick,
    onEmailSendClick,
    isServiceUnavailable,
    isOpenInfo,
    waitingTimeForNextCode,
  }: IPassStep) => {
    const { t } = useTranslation();
    return (
      <Wrapper>
        <Title>{t('login.passStep.code')}</Title>

        {isServiceUnavailable && (
          <ServiceUnavailableWrapper>
            <ServiceErrorMessage>{t('login.passStep.serviceUnavailable')}</ServiceErrorMessage>
            <ServiceErrorText>
              {t('login.passStep.tryAgainLine1')}
              <br />
              {t('login.passStep.tryAgainLine2')}
            </ServiceErrorText>
          </ServiceUnavailableWrapper>
        )}

        {!isServiceUnavailable && (
          <ContentWrapper>
            <InfoSubtitle>{t('login.passStep.codeWasSent', { email })}</InfoSubtitle>
            {isOutOfTime || isOutOfAttempts ? (
              <OutOfTime>{t('login.passStep.codeIsExpired')}</OutOfTime>
            ) : (
              <TimLeftWrapper>
                <span>{t('login.passStep.codeIsValid')}</span>
                <TimerIcon name={IconNames.Timer} />
                <TimeLeftValue>{timeLeft}</TimeLeftValue>
              </TimLeftWrapper>
            )}
            <PinInputLMSStyled
              value={pass}
              length={PASS_LENGTH}
              onChange={onPassChange}
              disabled={isOutOfAttempts || isOutOfTime}
              errorMessage={errorMessage}
            />
            {isOutOfTime || isOutOfAttempts ? (
              <ButtonStyled
                onClick={onEmailSendClick}
                disabled={buttonDisabled || waitingTimeForNextCode > 0}>
                {waitingTimeForNextCode > 0
                  ? `${t('login.getCodeAfterTime', { waitingTimeForNextCode })}`
                  : `${t('login.getCode')}`}
              </ButtonStyled>
            ) : (
              <ButtonStyled
                onClick={onPassSendClick}
                disabled={buttonDisabled || pass.length < PASS_LENGTH}>
                {t('login.passStep.login')}
              </ButtonStyled>
            )}
          </ContentWrapper>
        )}

        <Bottom>
          <BackButton onClick={onBackButtonClick}>
            <BackIcon name={IconNames.ArrowLeftSimple} />
            <BackText>{t('login.passStep.changeEmail')}</BackText>
          </BackButton>

          <HelpButton onClick={onHelpButtonClick}>{t('login.passStep.didntGetCode')}</HelpButton>
        </Bottom>
        {isOpenInfo && (
          <Info>
            <InfoHead>{t('login.instruction')}</InfoHead>
            {t('login.ifYouDontGetCode')}
            <InfoVariants>
              <li>{t('login.checkEmail')}</li>
              <li>{t('login.checkSpam')}</li>
              <li>{t('login.possibleOverload')}</li>
            </InfoVariants>
            {t('login.ifYouDontGetCode30min')}
          </Info>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  ${fontService.text({ type: TextType.normal })};
`;

const Title = styled.p`
  ${fontService.h2()};
`;

const ServiceUnavailableWrapper = styled.div`
  margin: 33px 0 85px;
`;

const ServiceErrorMessage = styled.p`
  color: ${Colors.signal_red};
`;

const ServiceErrorText = styled.p`
  margin-top: 16px;
`;

const ContentWrapper = styled.div`
  margin-top: 8px;
`;

const InfoSubtitle = styled.p`
  overflow-wrap: break-word;
  color: ${Colors.gray_dark};
`;

const OutOfTime = styled.p`
  margin-top: 4px;
  color: ${Colors.signal_red};
`;

const TimLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  color: ${Colors.gray_dark};
`;

const TimerIcon = styled(Icon)`
  height: 14px;
  width: 14px;
  margin: 0 5px;
`;

const TimeLeftValue = styled.span`
  color: ${Colors.black};
`;

const PinInputLMSStyled = styled(PinInputLMS)`
  margin-top: 32px;
`;

const ButtonStyled = styled(Button)`
  margin-top: 24px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const buttonsReset = css`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  ${buttonsReset};
`;

const BackIcon = styled(Icon)`
  height: 10px;
  width: 6px;
`;

const BackText = styled.span`
  margin-left: 6px;
  color: ${Colors.black};

  &:hover {
    color: ${Colors.main};
  }
`;

const HelpButton = styled.button`
  ${buttonsReset};
  text-decoration: underline;
  color: ${Colors.black};

  &:hover {
    color: ${Colors.main};
  }
`;

const Info = styled.div`
  margin-top: 24px;
  ${fontService.text({ type: TextType.small })};
  color: ${Colors.gray_dark};
`;

const InfoHead = styled.div`
  margin-bottom: 18px;
`;

const InfoVariants = styled.ul`
  margin: 18px 0;
`;
