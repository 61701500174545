import * as React from 'react';
import { memo } from 'react';

import styled, { css } from 'styled-components';

import fontService, { FontWeight } from 'services/FontService';
import { Colors } from 'context';

export interface IRadioButton {
  name?: string;
  checked: boolean;
  className?: string;
  text?: string;
  disabled?: boolean;
  onClick?: () => void;
  hasError?: boolean;
}

export const RadioButton = memo(
  ({ name, className, text, checked, disabled = false, onClick, ...props }: IRadioButton) => (
    <RadioButtonWrapper onClick={disabled ? undefined : onClick} className={className}>
      <RadioButtonStyled name={name} checked={checked} disabled={disabled} readOnly {...props} />
      {text && <Text>{text}</Text>}
    </RadioButtonWrapper>
  ),
);

const RadioButtonStyled = styled.input.attrs({ type: 'radio' })<IRadioButton>`
  appearance: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 1px solid ${Colors.system_gray};
  background-color: ${({ disabled }) => (disabled ? Colors.bg_gray : Colors.white)};
  ${({ disabled, checked }) => {
    if (checked) {
      if (disabled) {
        return css`
          border: 3px solid ${Colors.system_gray};
        `;
      }

      return css`
        border: 3px solid ${Colors.main};
      `;
    }
  }}
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: 0.2s all linear;
  outline: none;
  position: relative;
  bottom: 2px;
`;
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.span`
  margin-left: 8px;
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.regular })}
  color: ${Colors.black};
`;
