import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Grade } from 'atoms';
import { TaskTypes, TaskTabsNames, Colors } from 'context';
import { HomeworkGradesType } from 'types';
import { GradeSize } from 'atoms/Grade/Grade';
import fontService, { FontWeight } from 'services/FontService';
import { getTaskTypesTranslated } from 'services/TranslationService';
import { CLIENT_WIDTH, tablet } from 'services/ui';
import { useWindowSize } from 'services/hooks';

export interface ITaskResultCard {
  taskType: TaskTypes;
  grade?: HomeworkGradesType | null;
  mark?: number | null;
  date: string;
}

export const TaskResultCard = memo(({ taskType, grade, mark, date }: ITaskResultCard) => {
  const { t } = useTranslation();
  const { width: clientWidth } = useWindowSize();
  return (
    <Wrapper>
      <HeaderWrapper>
        <TaskType>{getTaskTypesTranslated(TaskTabsNames[taskType])}:</TaskType>
        {grade && (
          <StyledGrade
            gradeType={grade}
            width={clientWidth && clientWidth >= CLIENT_WIDTH.TABLET ? 20 : 14}
            height={clientWidth && clientWidth >= CLIENT_WIDTH.TABLET ? 20 : 14}
            size={GradeSize.lg}
          />
        )}
        {Number.isInteger(mark) && <Mark>{mark}%</Mark>}
      </HeaderWrapper>
      <Text>
        {t('tasks.checkDate')}
        <DateText>{date}</DateText>
      </Text>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 17px;
  background-color: ${Colors.bg_gray};
  border: 1px solid ${Colors.system_gray};
  border-radius: 8px;

  ${tablet} {
    padding: 24px 32px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${tablet} {
    margin-bottom: 16px;
  }
`;

const TaskType = styled.p`
  ${fontService.text({ size: 14, weight: FontWeight.bold, lineHeight: 142 })};
  margin-right: 8px;

  ${tablet} {
    ${fontService.text({ size: 24, weight: FontWeight.bold, lineHeight: 125 })};
    margin-right: 12px;
  }
`;

const StyledGrade = styled(Grade)`
  padding: 0;
`;

const Mark = styled.p`
  ${fontService.text({ size: 14, weight: FontWeight.bold, lineHeight: 142 })};

  ${tablet} {
    ${fontService.text({ size: 24, weight: FontWeight.bold, lineHeight: 125 })};
  }
`;

const Text = styled.p`
  ${fontService.text({ size: 14, weight: FontWeight.regular, lineHeight: 150 })};
  color: ${Colors.gray_dark};
`;

const DateText = styled.span`
  color: ${Colors.black};
`;
