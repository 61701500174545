import { ISelectOptions, Languages } from 'context';
import i18n from '../../../../i18n';

const MONTHS = {
  RUS: [
    `${i18n.t('common.months.january')}`,
    `${i18n.t('common.months.february')}`,
    `${i18n.t('common.months.march')}`,
    `${i18n.t('common.months.april')}`,
    `${i18n.t('common.months.may')}`,
    `${i18n.t('common.months.june')}`,
    `${i18n.t('common.months.july')}`,
    `${i18n.t('common.months.august')}`,
    `${i18n.t('common.months.september')}`,
    `${i18n.t('common.months.october')}`,
    `${i18n.t('common.months.november')}`,
    `${i18n.t('common.months.december')}`,
  ],
  ENG: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export const getMonthsOptions = (lang: Languages, monthsPlaceholder: string): ISelectOptions[] => {
  const monthsArray = MONTHS[lang];
  return monthsArray.reduce(
    (acc: ISelectOptions[], item) => {
      const option: ISelectOptions = {
        label: item,
        value: item,
      };

      return [...acc, option];
    },
    [{ label: monthsPlaceholder, value: '' }],
  );
};
