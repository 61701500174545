import { useGroupForLmsQuery } from 'core/graphql';
import { transformCourse } from './transform';

export const useCourse = (groupId: string) => {
  const { data, loading, error } = useGroupForLmsQuery({
    fetchPolicy: 'network-only',
    variables: { groupId },
  });

  const courseData = transformCourse(data);

  return { courseData, loading, error };
};
