import { useCallback, useEffect, useMemo, useState } from 'react';

import { HomeworksType, HomeworkGradesType } from 'types';

type LessonHomeworkType = {
  studentsGrades: HomeworksType[];
  allHomeworksDone: boolean;
  allHomeworksHaveEdits: boolean;
  onChangeGrade: (id: string, gradeType: HomeworkGradesType) => void;
  onCheckAllHomeworksDone: () => void;
  onCheckAllHomeworksHaveEdits: () => void;
  onUncheckAllHomeworks: () => void;
  onCancel: () => void;
};

export const useLessonHomework = (students?: HomeworksType[]): LessonHomeworkType => {
  const [studentsGrades, setStudentsGrades] = useState<HomeworksType[]>([]);

  useEffect(() => {
    if (students) {
      setStudentsGrades(students);
    }
  }, [students]);

  const allHomeworksDone = useMemo(
    () => !studentsGrades.some(({ gradeType }) => gradeType !== HomeworkGradesType.DONE),
    [studentsGrades],
  );
  const allHomeworksHaveEdits = useMemo(
    () => !studentsGrades.some(({ gradeType }) => gradeType !== HomeworkGradesType.CONTAINS_ERRORS),
    [studentsGrades],
  );

  const onChangeGrade = useCallback(
    (id: string, gradeType: HomeworkGradesType) => {
      const newStudentsGrades = studentsGrades.map((item) => {
        if (item.studentId === id) {
          return {
            ...item,
            gradeType: item.gradeType === gradeType ? null : gradeType,
          };
        }

        return item;
      });
      setStudentsGrades(newStudentsGrades);
    },
    [studentsGrades],
  );

  const onCheckAllHomeworksDone = useCallback(() => {
    setStudentsGrades(
      studentsGrades.map((item) => ({
        ...item,
        gradeType: HomeworkGradesType.DONE,
      })),
    );
  }, [studentsGrades]);

  const onCheckAllHomeworksHaveEdits = useCallback(() => {
    setStudentsGrades(
      studentsGrades.map((item) => ({
        ...item,
        gradeType: HomeworkGradesType.CONTAINS_ERRORS,
      })),
    );
  }, [studentsGrades]);

  const onUncheckAllHomeworks = useCallback(() => {
    setStudentsGrades(studentsGrades.map((item) => ({ ...item, gradeType: null })));
  }, [studentsGrades]);

  const onCancel = useCallback(() => {
    setStudentsGrades(students || []);
  }, [students]);

  return {
    studentsGrades,
    allHomeworksDone,
    allHomeworksHaveEdits,
    onChangeGrade,
    onCheckAllHomeworksDone,
    onCheckAllHomeworksHaveEdits,
    onUncheckAllHomeworks,
    onCancel,
  };
};
