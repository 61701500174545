import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';
import { Button, ButtonSizes } from 'atoms';
import { Colors, Languages } from 'context';

export interface IResumeLanguage {
  language: Languages;
  onLanguageChange: (lang: Languages) => void;
}

export const ResumeLanguage = memo(({ language, onLanguageChange }: IResumeLanguage) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledButton
        isActive={language === Languages.russian}
        size={ButtonSizes.sm}
        sidePaddings={12}
        disabled={process.env.REACT_APP_LANG === 'pl'}
        onClick={() => onLanguageChange(Languages.russian)}>
        {t('resume.languages.rus')}
      </StyledButton>
      <StyledButton
        isActive={language === Languages.english}
        size={ButtonSizes.sm}
        sidePaddings={12}
        onClick={() => onLanguageChange(Languages.english)}>
        {t('resume.languages.eng')}
      </StyledButton>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  gap: 8px;

  ${() =>
    process.env.REACT_APP_LANG === 'pl' &&
    css`
      flex-direction: row-reverse;
    `}
`;

const StyledButton = styled(Button)<{ isActive: boolean }>`
  background-color: ${({ isActive }) => !isActive && Colors.additional_gray};
  color: ${({ isActive }) => !isActive && Colors.black};
`;
