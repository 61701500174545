import * as React from 'react';
import { memo } from 'react';

import ContentLoader from 'react-content-loader';
import { useWindowSize } from 'services/hooks';
import { CLIENT_WIDTH } from 'services/ui';

export const HeaderPreloader = memo((props) => {
  const { width } = useWindowSize();
  return width && width >= CLIENT_WIDTH.TABLET ? (
    <ContentLoader
      speed={2}
      width={1110}
      height={87}
      viewBox="0 0 1110 87"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <rect opacity="0.5" y="28" width="1110" height="1" fill="#CFD6DC" />
      <rect y="47" width="1110" height="40" fill="#F4F5F7" />
      <rect width="1110" height="18" fill="#F4F5F7" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width="100%"
      height={28}
      viewBox="0 0 100% 28"
      backgroundColor="rgba(244, 245, 247, 1)"
      foregroundColor="rgba(233, 234, 238, 1)"
      {...props}>
      <rect width="100%" height="28" fill="#F4F5F7" />
    </ContentLoader>
  );
});
