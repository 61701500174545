import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IExperienceProject, IResumeFields } from 'context';
import { EditProjectPopupComponent } from './EditProjectPopup';

interface IEditProjectPopupContainer {
  fields: IResumeFields;
  project: IExperienceProject;
  isShowing: boolean;
  onClose: () => void;
  onSaveProject: (project: IExperienceProject) => void;
}

export const EditProjectPopup = memo(
  ({ fields, project, isShowing, onSaveProject, onClose }: IEditProjectPopupContainer) => {
    const { t } = useTranslation();
    const { values, errors, handleSubmit, setFieldValue } = useFormik<IExperienceProject>({
      initialValues: {
        id: project.id,
        name: project.name,
        problem: project.problem,
        solution: project.solution,
        technologies: project.technologies,
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        name: Yup.string().required(`${t('input.validation.requiredField')}`),
      }),
      enableReinitialize: true,
      onSubmit: (data) => onSaveProject(data),
    });

    return (
      <EditProjectPopupComponent
        fields={fields}
        project={values}
        isEmptyProjectName={Boolean(errors.name)}
        errorMessage={errors.name}
        onInputChange={setFieldValue}
        isShowing={isShowing}
        onClose={onClose}
        onSaveProject={handleSubmit}
      />
    );
  },
);
