import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, ButtonSizes, IconNames, Input, LoadingIndicator } from 'atoms';
import { Colors } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, mobile, tablet } from 'services/ui';

export interface IVideoUpload {
  videoLink: string;
  onInputChange: (value: string) => void;
  errorMessage?: string;
  onSubmitClick: () => void;
  onCancelClick: () => void;
  isLoading: boolean;
  isSuccess: boolean;
}

export const VideoUploadComponent = memo(
  ({
    videoLink,
    onInputChange,
    errorMessage,
    onSubmitClick,
    onCancelClick,
    isLoading,
    isSuccess,
  }: IVideoUpload) => {
    const { t } = useTranslation();
    const inputValidation = useCallback((value: string) => /(^[0-9 ]+$|^$)/.test(value), []);

    return (
      <Wrapper>
        {isLoading ? (
          <LoadingScreen>
            <Preloader>
              <LoadingIndicatorWrapper>
                <LoadingIndicator icon={IconNames.LoadingGreen} />
              </LoadingIndicatorWrapper>
              {!isSuccess ? `${t('lesson.video.uploading')}` : `${t('lesson.video.preparing')}`}
            </Preloader>
            {!isSuccess && (
              <StyledButton
                variant="outline"
                size={ButtonSizes.sm}
                sidePaddings={12}
                colors={{ borderColor: Colors.gray }}
                onClick={onCancelClick}>
                {t('lesson.video.button.cancel')}
              </StyledButton>
            )}
          </LoadingScreen>
        ) : (
          <InputWrapper>
            <Label>{t('lesson.video.videoId.label')}</Label>
            <UploadInput>
              <StyledInput
                value={videoLink}
                type="text"
                onChange={({ target: { value } }) => inputValidation(value) && onInputChange(value)}
                hasError={Boolean(errorMessage)}
                message={errorMessage}
              />
              <SubmitButton onClick={onSubmitClick}>{t('lesson.video.button.upload')}</SubmitButton>
            </UploadInput>
          </InputWrapper>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 24px;

  ${tablet} {
    margin-bottom: 40px;
  }

  ${desktop} {
    margin-bottom: 60px;
  }
`;

const InputWrapper = styled.div``;

const UploadInput = styled.div`
  display: flex;
  gap: 8px;
`;

const Label = styled.p`
  color: ${Colors.black};
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.bold })};
  margin: 0 0 4px 0;
`;

const StyledInput = styled(Input)`
  flex: 1;
`;

const SubmitButton = styled(Button)`
  ${mobile} {
    padding: 0 24px;
  }

  width: max-content;
  height: 50px;
`;

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Preloader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 160px;
  border-radius: 16px;
  border: 1px dashed ${Colors.gray};
  color: ${Colors.gray};
  ${fontService.text({ size: 14, weight: FontWeight.regular })};

  ${tablet} {
    height: 240px;
  }
`;

const LoadingIndicatorWrapper = styled.div`
  height: 25px;
  width: 25px;
`;

const StyledButton = styled(Button)`
  ${fontService.text({ size: 16, lineHeight: 150, weight: FontWeight.medium })};
  padding: 12px;

  ${tablet} {
    width: max-content;
    ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.medium })};
    padding: 0 12px;
  }
`;
