import { Days, GradeNames, Months, ResumeFieldErrors, TaskTabsNames } from 'context';
import i18n from '../i18n';

export const getDaysTranslated = (day: Days): string =>
  ({
    [Days.Monday]: i18n.t('common.weekDaysShort.monday'),
    [Days.Tuesday]: i18n.t('common.weekDaysShort.tuesday'),
    [Days.Wednesday]: i18n.t('common.weekDaysShort.wednesday'),
    [Days.Thursday]: i18n.t('common.weekDaysShort.thursday'),
    [Days.Friday]: i18n.t('common.weekDaysShort.friday'),
    [Days.Saturday]: i18n.t('common.weekDaysShort.saturday'),
    [Days.Sunday]: i18n.t('common.weekDaysShort.sunday'),
  }[day]);

export const getMonthsTranslated = (month: Months): string =>
  ({
    [Months.January]: i18n.t('common.months.january'),
    [Months.February]: i18n.t('common.months.february'),
    [Months.March]: i18n.t('common.months.march'),
    [Months.April]: i18n.t('common.months.april'),
    [Months.May]: i18n.t('common.months.may'),
    [Months.June]: i18n.t('common.months.june'),
    [Months.July]: i18n.t('common.months.july'),
    [Months.August]: i18n.t('common.months.august'),
    [Months.September]: i18n.t('common.months.september'),
    [Months.October]: i18n.t('common.months.october'),
    [Months.November]: i18n.t('common.months.november'),
    [Months.December]: i18n.t('common.months.december'),
  }[month]);

export const getResumeErrorsTranslated = (error: ResumeFieldErrors): string =>
  ({
    [ResumeFieldErrors.emptyField]: i18n.t('input.validation.requiredField'),
  }[error]);

export const getTaskTypesTranslated = (type: TaskTabsNames): string =>
  ({
    [TaskTabsNames.homework]: i18n.t('tasks.tasksTypes.homework'),
    [TaskTabsNames.coursework]: i18n.t('tasks.tasksTypes.coursework'),
    [TaskTabsNames.graduation_project]: i18n.t('tasks.tasksTypes.graduationProject'),
  }[type]);

export const getTaskGradesTranslated = (grade: GradeNames): string =>
  ({
    [GradeNames.DONE]: i18n.t('tasks.grades.done'),
    [GradeNames.CONTAINS_ERRORS]: i18n.t('tasks.grades.containsErrors'),
    [GradeNames.NOT_DONE]: i18n.t('tasks.grades.notDone'),
  }[grade]);
