import { gql } from '@apollo/client';

export const UploadResume = gql`
  mutation uploadResume($photo: Upload, $resumeInput: ResumeInput!) {
    uploadResume(photo: $photo, resumeInput: $resumeInput) {
      success
      customErrors {
        code
        field
        message
      }
      resume {
        id
      }
    }
  }
`;
