import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Colors } from 'context';
import { Button } from 'components/atoms';
import fontService, { TextType } from 'services/FontService';
import { desktop, mobile, tablet } from 'services/ui';

type LessonJournalFooterType = {
  isFinished: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  finishLessonLoading: boolean;
  success: boolean;
  hasCheckedStudents: boolean;
  className?: string;
};

export const LessonJournalFooter = memo(
  ({
    isFinished,
    finishLessonLoading,
    onSubmit,
    onCancel,
    className,
    success,
    hasCheckedStudents,
  }: LessonJournalFooterType) => {
    const { t } = useTranslation();
    const showButtons = useCallback(() => {
      if (isFinished || success) {
        return null;
      }

      return (
        <>
          <CancelButtonWrapper>
            <StyledCancelButton onClick={onCancel} disabled={finishLessonLoading} variant="text">
              {t('lesson.journal.button.cancel')}
            </StyledCancelButton>
          </CancelButtonWrapper>

          <SubmitButtonWrapper>
            <Button onClick={onSubmit} disabled={finishLessonLoading || !hasCheckedStudents}>
              {t('lesson.journal.button.endLesson')}
            </Button>
          </SubmitButtonWrapper>
        </>
      );
    }, [finishLessonLoading, isFinished, onCancel, onSubmit, success, t, hasCheckedStudents]);

    return (
      <LessonJournalFooterContainer className={className}>
        <JournalFooterText>{t('lesson.journal.comment')}</JournalFooterText>
        {showButtons()}
      </LessonJournalFooterContainer>
    );
  },
);

const LessonJournalFooterContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 20px 20px 32px;
  background-color: ${Colors.bg_gray};

  ${tablet} {
    flex-direction: row;
    align-items: center;
    height: 76px;
    padding: 0 32px;
  }

  ${desktop} {
    padding: 0 0 0 54px;
  }
`;

const CancelButtonWrapper = styled.div`
  width: 100%;
  height: 48px;
  margin-bottom: 16px;

  ${tablet} {
    width: 77px;
    margin: 0 30px 0 auto;
  }

  ${desktop} {
    margin: 0 30px 0 116px;
  }
`;

const StyledCancelButton = styled(Button)`
  ${mobile} {
    border: 1px solid ${Colors.gray};
    border-radius: 8px;
  }
`;

const SubmitButtonWrapper = styled.div`
  width: 100%;
  height: 48px;
  margin-bottom: 8px;

  ${tablet} {
    width: 209px;
    margin-bottom: 0;
  }
`;

const JournalFooterText = styled.span`
  width: 296px;
  ${fontService.text({ type: TextType.small })};
  text-align: center;
  color: ${Colors.gray};

  ${tablet} {
    width: 272px;
    height: 32px;
    ${fontService.text({ type: TextType.extraSmall })};
    text-align: left;
  }
`;
