import { useLessonForLmsQuery } from 'core';
import { LessonType } from 'types';
import { transformLesson } from './transform';

type lessonType = {
  currentLesson: LessonType | null;
  loading?: boolean;
  error?: string;
};

export const useGetLesson = (lessonId: string): lessonType => {
  const { data, loading, error } = useLessonForLmsQuery({
    fetchPolicy: 'network-only',
    variables: {
      groupLessonId: lessonId,
    },
  });

  const currentLesson = transformLesson(data);

  return {
    currentLesson,
    loading,
    error: error?.message,
  };
};
