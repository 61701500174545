import { useLocation } from 'react-router-dom';
import { MenuItems } from 'context';

export const useActiveMenu = (): MenuItems | undefined => {
  const { pathname } = useLocation();

  const urlWithoutSlash = pathname.split('/:')[0].replace(`/`, '');

  const activeMenu = Object.values(MenuItems).find((item) => item === urlWithoutSlash);

  return activeMenu;
};
