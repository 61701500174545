import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// @ts-ignore
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';

import styled from 'styled-components';
import { useGoToErrorPage, useGetAttachment } from 'core';
import { CLIENT_WIDTH, mobile } from 'services/ui';
import { useWindowSize } from 'services/hooks';

const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const {
        CurrentPageInput,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots;
      return (
        <ToolbarWrapper>
          <ToolbarElement>
            <ShowSearchPopover />
          </ToolbarElement>

          <PagesWrapper>
            <ToolbarElement>
              <GoToPreviousPage />
            </ToolbarElement>

            <PagesNumberWrapper>
              <CurrentPageInput /> / <NumberOfPages />
            </PagesNumberWrapper>

            <ToolbarElement>
              <GoToNextPage />
            </ToolbarElement>
          </PagesWrapper>

          <ZoomWrapper>
            <ToolbarElement>
              <ZoomOut />
            </ToolbarElement>
            <ToolbarElement>
              <Zoom />
            </ToolbarElement>
            <ToolbarElement>
              <ZoomIn />
            </ToolbarElement>
          </ZoomWrapper>
        </ToolbarWrapper>
      );
    }}
  </Toolbar>
);

export const Attachments = memo(() => {
  const { width } = useWindowSize();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0], defaultTabs[1]],
    renderToolbar,
  });

  const handleDocumentLoad = useCallback(() => {
    const { activateTab } = defaultLayoutPluginInstance;

    if (width && width >= CLIENT_WIDTH.DESKTOP) {
      activateTab(0);
    }
  }, [defaultLayoutPluginInstance, width]);

  const goToErrorPage = useGoToErrorPage();
  const { type, fileId } = useParams();
  const { attachmentFile, loading, error } = useGetAttachment(fileId || '', type);
  const [fileUrl, setUrl] = useState<string>('');

  useEffect(() => {
    if (attachmentFile) {
      setUrl(attachmentFile);
    }
  }, [attachmentFile]);

  useEffect(() => {
    if (error) {
      console.log({ attachmentError: error });
      goToErrorPage();
    }
  }, [error, goToErrorPage]);

  const defaultScale = useMemo(() => {
    if (width) {
      switch (true) {
        case width >= CLIENT_WIDTH.DESKTOP:
          return 1.3;
        case width >= CLIENT_WIDTH.TABLET:
          return 1;
        default:
          return 0.8;
      }
    }
    return 1.3;
  }, [width]);

  return (
    <Worker workerUrl={pdfWorker}>
      {!loading && attachmentFile && (
        <ViewerWrapper>
          <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
            defaultScale={defaultScale}
          />
        </ViewerWrapper>
      )}
    </Worker>
  );
});

const ViewerWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;

  ${mobile} {
    width: 100%;
    justify-content: space-between;
  }
`;

const ToolbarElement = styled.div`
  padding: 0 2px;

  .rpv-core__tooltip-body {
    display: none;
  }
`;

const ZoomWrapper = styled.div`
  display: flex;
  margin-left: 20px;

  ${mobile} {
    margin-left: 0;
  }
`;

const PagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 268px;

  ${mobile} {
    min-width: 162px;
  }
`;

const PagesNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 0 2px;
`;
