import * as React from 'react';
import { memo } from 'react';

import styled, { css } from 'styled-components';

import { Icon, IconNames } from 'atoms/Icon';
import fontService, { FontWeight } from 'services/FontService';
import { Colors } from 'context';

export interface ICheckBox {
  name?: string;
  variant?: 'default';
  checked: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  hasError?: boolean;
  text?: string;
}

export const CheckBox = memo(
  ({
    name,
    className,
    hasError,
    checked,
    variant = 'default',
    disabled = false,
    text,
    onClick,
    ...props
  }: ICheckBox) => (
    <CheckBoxWrapper
      className={className}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      checked={checked}>
      <HiddenCheckbox checked={checked} {...props} readOnly />
      <StyledCheckBox name={name} variant={variant} checked={checked} hasError={hasError}>
        {checked && <Icon name={IconNames.Check} width="100%" height="100%" />}
      </StyledCheckBox>
      {text && <Text>{text}</Text>}
    </CheckBoxWrapper>
  ),
);

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckBoxWrapper = styled.div<{ disabled: boolean; checked: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ disabled, checked }) => {
    if (disabled) {
      if (checked) {
        return css`
          ${StyledCheckBox} {
            border-color: ${Colors.system_gray};
            background-color: ${Colors.system_gray};
          }
        `;
      }
      return css`
        ${StyledCheckBox} {
          border-color: ${Colors.system_gray};
        }
      `;
    }
  }}
`;

const StyledCheckBox = styled.div<ICheckBox>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 1px solid
    ${({ checked }) => {
      if (checked) {
        return Colors.main;
      }

      return Colors.system_gray;
    }};
  border-radius: 4px;
  background-color: ${({ checked }) => {
    if (checked) {
      return Colors.main;
    }

    return Colors.white;
  }};
  ${({ hasError }) => hasError && `border-color: ${Colors.signal_red}`};
`;

const Text = styled.span`
  margin-left: 8px;
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.regular })}
  color: ${Colors.black};
`;
