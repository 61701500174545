import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Toast } from 'components/atoms';
import { IToastState } from 'core';
import { tablet } from 'services/ui';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'router/config';

export interface IToaster {
  toasts: IToastState;
}

export const Toaster = memo(({ toasts }: IToaster) => {
  const { pathname } = useLocation();
  const isLoginPage = useMemo(() => pathname.includes(PATHS.login), [pathname]);
  const renderToasts = useCallback(
    () =>
      Object.keys(toasts).map((key) => {
        const ToastData = toasts[key];
        if (!ToastData) {
          return null;
        }

        return (
          <ToastContainer key={key}>
            <Toast toastKey={key} type={ToastData.type} text={ToastData.text} />
          </ToastContainer>
        );
      }),
    [toasts],
  );

  return <ToasterContainer isLogin={isLoginPage}>{renderToasts()}</ToasterContainer>;
});

const ToasterContainer = styled.div<{ isLogin: boolean }>`
  position: fixed;
  top: 20px;
  left: ${({ isLogin }) => !isLogin && '20px'};
  pointer-events: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  width: ${({ isLogin }) => isLogin && '100%'};

  ${tablet} {
    top: 60px;
    left: ${({ isLogin }) => !isLogin && '50px'};
    width: 100%;
  }
`;

const ToastContainer = styled.div`
  margin-bottom: 40px;
`;
