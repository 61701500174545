import { TaskTypes } from 'context';

export type AttendanceType = {
  attendanceId: string;
  studentName: string;
  checked?: boolean;
};

export type LessonAttachmentType = {
  id: string;
  title: string;
  link: string;
};

export enum AttachmentType {
  student = 'student',
  teacher = 'teacher',
}

export enum HomeworkGradesType {
  DONE = 'DONE',
  CONTAINS_ERRORS = 'CONTAINS_ERRORS',
  NOT_DONE = 'NOT_DONE',
}

export type HomeworksType = {
  studentId: string;
  studentName: string;
  gradeType?: HomeworkGradesType | null;
  mark?: number | null;
};

export type ProgramLessonType = {
  id: string;
  name: string;
  index: number;
  isEnded: boolean;
  isActive?: boolean;
  needToFinish?: boolean | null;
};

export type LessonType = ProgramLessonType & {
  time: string;
  date: string;
  finished: boolean;
  lmsDescription: string;
  comment: string;
  video?: string | null;
  taskTypes: string[];
  hasStudentMarks?: boolean | null;
  hasTeacherAttachment?: boolean | null;
  hasStudentAttachment?: boolean | null;
};

export type StudentMarks = {
  mark?: number | null;
  hwMark?: HomeworkGradesType | null;
  date: string;
  taskType: TaskTypes;
};
