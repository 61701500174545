import { useCallback } from 'react';

import { ToastTypes } from 'components/atoms/Toast';
import { IToastState, toastState } from 'core/state';
import { useReactiveVar } from '@apollo/client';

type ToasterType = {
  toasts: IToastState;
  addToast: (toastKey: string, toast: { type: ToastTypes; text: string }) => void;
  removeToast: (toastKey: string) => void;
};

export const useToaster = (): ToasterType => {
  const toasts = useReactiveVar(toastState);

  const addToast = useCallback((toastKey, toast) => {
    const previousToasts = toastState();

    toastState({
      ...previousToasts,
      [toastKey]: toast,
    });
  }, []);

  const removeToast = useCallback((toastKey: string) => {
    const previousToasts = toastState();

    toastState({
      ...previousToasts,
      [toastKey]: null,
    });
  }, []);

  return {
    toasts,
    addToast,
    removeToast,
  };
};
