import React, { memo } from 'react';

import styled from 'styled-components';
import { desktop, tablet } from 'services/ui';

import { Input, Select } from 'atoms';
import { Colors, ISelectOptions, LanguageLevel } from 'context';
import { SelectInput } from 'atoms/Select/Select';

export interface IInputLanguage {
  index: number;
  options: ISelectOptions[];
  preselectedOption?: ISelectOptions;
  levelPlaceholder: string;
  languagePlaceholder: string;
  languageValue: string;
  onInputChange: (name: string, value: string | LanguageLevel) => void;
}

export const InputLanguage = memo(
  ({
    index,
    options,
    preselectedOption,
    levelPlaceholder,
    languagePlaceholder,
    languageValue,
    onInputChange,
  }: IInputLanguage) => (
    <Wrapper>
      <StyledInput
        type="text"
        placeholder={languagePlaceholder}
        value={languageValue}
        onChange={({ target: { value } }) => onInputChange(`languages[${index}].language`, value)}
        max={80}
      />
      <StyledSelect
        preselectedOption={preselectedOption}
        options={options}
        placeholder={levelPlaceholder}
        disabled={false}
        onSelect={(option) =>
          onInputChange(`languages[${index}].level`, option.value as LanguageLevel)
        }
      />
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${tablet} {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  ${desktop} {
    width: unset;
  }
`;

const StyledSelect = styled(Select)`
  flex-grow: 1;

  ${desktop} {
    flex-grow: 0;
  }

  ${SelectInput} {
    ${tablet} {
      border-radius: 0 8px 8px 0;
    }

    ${desktop} {
      width: 292px;
    }
  }
`;

const StyledInput = styled(Input)`
  width: 88%;

  ${tablet} {
    width: 191px;
    input {
      border-right: none;
      border-radius: 8px 0 0 8px;

      :focus {
        position: relative;
        z-index: 10;
        width: 192px;
        border-right: 1px solid ${Colors.main};
      }
    }
  }
`;
