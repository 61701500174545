import React, { memo } from 'react';
import styled from 'styled-components';

import fontService, { FontWeight } from 'services/FontService';
import { CLIENT_WIDTH, desktop, tablet } from 'services/ui';
import { useWindowSize } from 'services/hooks';

import { Directions, Sizes } from 'context';

import { Arrow } from '../Arrow';
import { DropDownMenu, IDropDownMenu } from './DropDownMenu';

export interface IDropDown extends IDropDownMenu {
  toggling: () => void;
}

export const DropDown = memo(
  ({ options, isOpen, selectedOption, toggling, onOptionClicked }: IDropDown) => {
    const { width } = useWindowSize();
    return (
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          <SelectedOption>{selectedOption.label}</SelectedOption>
          <StyledArrow
            direction={isOpen ? Directions.up : Directions.down}
            size={width && width >= CLIENT_WIDTH.DESKTOP ? Sizes.lg : Sizes.md}
          />
        </DropDownHeader>
        <DropDownMenu
          isOpen={isOpen}
          options={options}
          selectedOption={selectedOption}
          onOptionClicked={onOptionClicked}
        />
        <DropDownFooter>{selectedOption?.description}</DropDownFooter>
      </DropDownContainer>
    );
  },
);

const DropDownContainer = styled.div`
  ${desktop} {
    width: 523px;
  }
`;
const DropDownHeader = styled.div`
  ${fontService.getFont({ size: 18, lineHeight: 150, weight: FontWeight.bold })};
  margin-bottom: 4px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${tablet} {
    ${fontService.getFont({ size: 24, lineHeight: 150, weight: FontWeight.bold })}
  }

  ${desktop} {
    ${fontService.h2()}
  }
`;

const SelectedOption = styled.span`
  margin-right: 11px;
`;

const DropDownFooter = styled.div`
  ${fontService.getFont({ size: 12, lineHeight: 133 })}
  margin-left: 0px;
  cursor: default;

  ${tablet} {
    ${fontService.getFont({ size: 18, lineHeight: 133 })}
  }
`;

const StyledArrow = styled(Arrow)`
  margin-top: -4px;

  ${tablet} {
    margin: 0;
  }
`;
