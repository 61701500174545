import { gql } from '@apollo/client';

export const UploadLessonVideo = gql`
  mutation uploadLessonVideo($lessonId: Int!, $videoUrl: String!) {
    uploadLessonVideo(lessonId: $lessonId, videoUrl: $videoUrl) {
      success
      customErrors {
        code
        field
        message
      }
    }
  }
`;

export const CancelUploadVideo = gql`
  mutation cancelUploadVideo($lessonId: Int!) {
    cancelVideoUpload(lessonId: $lessonId) {
      customErrors {
        field
        code
        message
      }
    }
  }
`;

export const AddStudentsMarks = gql`
  mutation addStudentsMarks(
    $lessonId: Int!
    $taskType: LessonTaskTypeEnum!
    $studentsMarks: [LessonMarkInput]!
    $date: Date!
  ) {
    setStudentsMarks(
      lessonId: $lessonId
      taskType: $taskType
      studentsMarks: $studentsMarks
      date: $date
    ) {
      success
      customErrors {
        field
        code
        message
      }
    }
  }
`;
