import React, { memo, useCallback } from 'react';

import styled from 'styled-components';
import { CheckHomeworkButton, Grade } from 'atoms';
import { HomeworkGradesType, HomeworksType } from 'types';
import { Colors } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { tablet } from 'services/ui';

export interface IHomeworkGrades {
  students: HomeworksType[];
  isHomeworkChecked: boolean;
  onChangeGrade: (id: string, grade: HomeworkGradesType) => void;
  className?: string;
}

export const HomeworkGrades = memo(
  ({ students, isHomeworkChecked, onChangeGrade, className }: IHomeworkGrades) => {
    const gradesButtons = useCallback(
      (student: HomeworksType) => {
        const homeworkGradesTypeKeys = Object.keys(HomeworkGradesType);

        return homeworkGradesTypeKeys.map((gradeTypeKey) => (
          <CheckHomeworkButton
            key={gradeTypeKey}
            isActive={student.gradeType === gradeTypeKey}
            onClick={(grade) => onChangeGrade(student.studentId, grade)}
            gradeType={HomeworkGradesType[gradeTypeKey as keyof typeof HomeworkGradesType]}
          />
        ));
      },
      [onChangeGrade],
    );

    const getStudentsList = useCallback(
      () =>
        students.map((student) => (
          <Student key={student.studentId} isHomeworkChecked={isHomeworkChecked}>
            <StudentName>{student.studentName}</StudentName>
            {isHomeworkChecked ? (
              student.gradeType && <Grade gradeType={student.gradeType} />
            ) : (
              <ChooseGradeWrapper>{gradesButtons(student)}</ChooseGradeWrapper>
            )}
          </Student>
        )),
      [students, isHomeworkChecked, gradesButtons],
    );

    return (
      <Wrapper className={className} $checked={isHomeworkChecked}>
        {getStudentsList()}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ $checked?: boolean }>`
  margin-top: 16px;
  width: 100%;

  ${tablet} {
    width: ${({ $checked }) => ($checked ? '530px' : '100%')};
  }
`;

const Student = styled.div<{ isHomeworkChecked: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isHomeworkChecked }) => (isHomeworkChecked ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid ${Colors.additional_gray};
  &:last-child {
    border-bottom: 1px solid ${Colors.additional_gray};
  }

  ${tablet} {
    flex-direction: row;
  }
`;

const ChooseGradeWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const StudentName = styled.p`
  ${fontService.text({ size: 14, weight: FontWeight.regular, lineHeight: 142 })};
  margin-bottom: 8px;

  ${tablet} {
    margin-bottom: 0;
  }
`;
