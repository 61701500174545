import React, { memo, useEffect } from 'react';

import { useGetStudentMarks, useGoToErrorPage } from 'core';
import { NetworkErrors, UserRole } from 'context';
import { JournalPreloader } from 'atoms';
import { TaskResultsComponent } from './TaskResults';

interface ITaskResults {
  lessonsId: string;
  activeRole: UserRole;
}

export const TaskResults = memo(({ lessonsId, activeRole }: ITaskResults) => {
  const { marks, loading } = useGetStudentMarks(lessonsId);
  const goToErrorPage = useGoToErrorPage();

  useEffect(() => {
    if (activeRole !== UserRole.STUDENT) {
      goToErrorPage(NetworkErrors.pageIsNotAvailable);
    }
  }, [goToErrorPage, marks, activeRole]);

  return !loading && marks?.length ? (
    <TaskResultsComponent studentMarks={marks} />
  ) : (
    <JournalPreloader />
  );
});
