import { IResumeFormValues } from 'organisms';
import { StudentResumeQuery } from 'core';
import { LanguageLevel, SocialMedia } from 'context';

export const transformResumeData = (
  data: StudentResumeQuery | undefined,
): IResumeFormValues | null => {
  if (data?.studentResume) {
    const {
      image,
      firstName,
      lastName,
      email,
      phone,
      jobTitle,
      additionalInfo,
      cvMedias,
      cvExperience,
      educations,
      cvLanguages,
      skills,
      tools,
    } = data.studentResume;

    return {
      image: image || '',
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phone: phone || '',
      position: jobTitle || '',
      about: additionalInfo || '',
      socialMedia: cvMedias.map(({ id, media, socialLink }) => ({
        id,
        name: (media?.mediaName as SocialMedia) || '',
        link: socialLink,
      })),
      experience: cvExperience.map((experience) => ({
        id: experience.id,
        company: experience.company || '',
        position: experience.jobTitle || '',
        startDate: {
          year: experience.startDateYear?.toString() || '',
          month: experience.startDateMonth || '',
        },
        endDate: {
          year: experience.endDateYear?.toString() || '',
          month: experience.endDateMonth || '',
        },
        projects: experience.projects.map(
          ({ id, projectName, description, summary, technologies }) => ({
            id,
            name: projectName || '',
            technologies: technologies || [],
            problem: description || '',
            solution: summary || '',
          }),
        ),
      })),
      education: educations.map((education) => ({
        id: education.id,
        institution: education.edInstitution || '',
        specialization: education.specialization || '',
        degree: education.degree || '',
        startDate: {
          year: education.startDateYear?.toString() || '',
          month: education.startDateMonth || '',
        },
        endDate: {
          year: education.endDateYear?.toString() || '',
          month: education.endDateMonth || '',
        },
      })),
      languages: cvLanguages.map(({ id, language, languageLevel }) => ({
        id,
        language: language?.language || '',
        level: languageLevel ? LanguageLevel[languageLevel] : '',
      })),
      skills: skills.map(({ skillName }) => skillName),
      tools: tools.map(({ toolsName }) => toolsName),
    };
  }

  return null;
};
