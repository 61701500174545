import * as React from 'react';
import { memo } from 'react';

import styled, { css } from 'styled-components';

import fontService, { FontWeight } from 'services/FontService';
import { Colors } from 'context';
import { IColors } from 'types';

import { LoadingIndicator } from '../LoadingIndicator';

export enum ButtonSizes {
  sm = 'sm',
  md = 'md',
}

export interface IButtonStyle {
  type?: 'submit' | 'reset' | 'button';
  small?: boolean;
  disabled?: boolean;
  colors?: IColors;
  variant?: 'primary' | 'text' | 'outline' | 'underline';
  sidePaddings?: number;
  size?: ButtonSizes;
}

export interface IButton extends IButtonStyle {
  children: React.ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  loadingSize?: number;
  className?: string;
}

export const Button = memo(
  ({
    children,
    small = false,
    onClick,
    colors,
    disabled = false,
    isLoading = false,
    type,
    variant = 'primary',
    sidePaddings = 32,
    className,
    size = ButtonSizes.md,
    ...rest
  }: IButton) => (
    <TitleContainer
      className={className}
      small={small}
      sidePaddings={sidePaddings}
      colors={colors}
      variant={variant}
      disabled={disabled}
      isLoading={isLoading}
      onClick={() => onClick?.()}
      type={type}
      size={size}
      {...rest}>
      {isLoading ? (
        <LoadingIndicatorWrapper>
          <LoadingIndicator />
        </LoadingIndicatorWrapper>
      ) : (
        children
      )}
    </TitleContainer>
  ),
);

const TitleContainer = styled.button<IButton>`
  position: relative;
  ${({ size }) => {
    if (size === ButtonSizes.md) {
      return fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.medium });
    }
    if (size === ButtonSizes.sm) {
      return fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.medium });
    }
  }};
  white-space: nowrap;
  box-sizing: border-box;
  min-height: ${({ size }) => (size === ButtonSizes.md ? '48px' : '32px')};
  border-radius: 8px;
  ${({ disabled, variant, colors, isLoading }) => {
    switch (variant) {
      case 'primary':
        if (disabled) {
          return css`
            background-color: ${colors?.bgDisabledColor || Colors.system_gray};
            border: none;
            color: ${colors?.color || Colors.black};
          `;
        }
        if (isLoading) {
          return css`
            background-color: ${colors?.backgroundColor || Colors.main_dark};
            border: none;
            color: ${colors?.color || Colors.btn_text};
          `;
        }
        return css`
          background-color: ${colors?.backgroundColor || Colors.main};
          border: none;
          color: ${colors?.color || Colors.btn_text};
          &:hover {
            transition: 0.5s;
            background-color: ${Colors.main_light};
            color: ${Colors.btn_hover_text};
          }
        `;
      case 'text':
        if (disabled) {
          return css`
            background-color: ${colors?.bgDisabledColor || 'transparent'};
            border: none;
            color: ${colors?.color || Colors.gray_dark};
          `;
        }
        return css`
          background-color: ${colors?.backgroundColor || 'transparent'};
          color: ${colors?.color || Colors.gray_dark};
          border: none;
          &:hover {
            transition: 0.5s;
            background-color: transparent;
            color: ${Colors.black};
          }
        `;
      case 'outline':
        if (disabled) {
          return css`
            background-color: ${colors?.bgDisabledColor || Colors.white};
            color: ${colors?.color || Colors.black};
            border: 1px solid ${Colors.gray_dark};
          `;
        }
        return css`
          background-color: ${colors?.backgroundColor || Colors.white};
          color: ${colors?.color || Colors.black};
          border: 1px solid ${colors?.borderColor || Colors.gray_dark};
          &:hover {
            transition: 0.5s;
            background-color: ${Colors.main_light};
            border: 1px solid ${Colors.main_light};
            color: ${Colors.btn_hover_text};
          }
        `;
      case 'underline':
        if (disabled) {
          return css`
            background-color: ${colors?.backgroundColor || 'transparent'};
            border: none;
            border-bottom: 1.2px dashed ${Colors.gray_dark};
            color: ${colors?.color || Colors.gray_dark};
          `;
        }
        return css`
          min-height: 20px;
          background-color: ${colors?.backgroundColor || 'transparent'};
          color: ${colors?.color || Colors.black};
          ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.regular })};
          border: none;
          border-radius: 0;
          border-bottom: 1.2px dashed ${Colors.black};
          &:hover {
            transition: 0.5s;
            background-color: transparent;
            color: ${Colors.main_dark};
            border-bottom-color: ${Colors.main_dark};
          }
        `;
      default:
        return css``;
    }
  }}
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  width: 100%;
  cursor: pointer;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding-right: ${({ sidePaddings }) => `${sidePaddings}px`};
  padding-left: ${({ sidePaddings }) => `${sidePaddings}px`};
  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const LoadingIndicatorWrapper = styled.div`
  height: 25px;
  width: 25px;
`;
