import React, { memo, useCallback, useEffect, useState } from 'react';

import {
  AddProjectPopup,
  ResumeExperienceCardComponent,
  IExperienceValues,
  EditProjectPopup,
} from 'organisms';
import { IExperienceProject, IResumeDate, IResumeFields, Languages } from 'context';
import { useModal } from 'core';

interface IResumeExperienceCardContainer {
  language: Languages;
  fields: IResumeFields;
  index: number;
  values: IExperienceValues;
  onChangeProjects: (projects: IExperienceProject[]) => void;
  onRemoveExperience: (id: string) => void;
  onInputChange: (name: string, value: string | IResumeDate) => void;
}

export const ResumeExperienceCard = memo(
  ({
    language,
    fields,
    values,
    index,
    onChangeProjects,
    onInputChange,
    onRemoveExperience,
  }: IResumeExperienceCardContainer) => {
    const { isShowing, toggle } = useModal();
    const { isShowing: isEditShowing, toggle: toggleEdit } = useModal();
    const [projects, setProjects] = useState<IExperienceProject[]>(
      values.projects.length ? values.projects : [],
    );
    const [projectToEdit, setProjectToEdit] = useState<IExperienceProject | null>(null);
    const [startDate, setStartDate] = useState<boolean>(false);

    useEffect(() => {
      setStartDate(Boolean(values.startDate.year));
      if (!values.startDate.year) {
        onInputChange(`experience[${index}].endDate`, { year: '', month: '' });
      }
    }, [values.startDate.year, onInputChange, index]);

    const onAddProject = useCallback(
      (project: IExperienceProject) => {
        setProjects((prevState) => [...prevState, project]);
        onChangeProjects([...projects, project]);
        toggle();
      },
      [onChangeProjects, projects, toggle],
    );

    const onRemoveProject = useCallback(
      (id: string) => {
        setProjects((prevState) => prevState.filter((project) => id !== project.id));
        onChangeProjects(projects.filter((project) => id !== project.id));
      },
      [onChangeProjects, projects],
    );

    const onEditProject = useCallback(
      (project: IExperienceProject) => {
        setProjectToEdit(project);
        toggleEdit();
      },
      [toggleEdit],
    );

    const onSaveProject = useCallback(
      (project: IExperienceProject) => {
        setProjects((prevState) =>
          prevState.map((item) => (item.id === project.id ? { ...project } : item)),
        );
        onChangeProjects([
          ...projects.map((item) => (item.id === project.id ? { ...project } : item)),
        ]);
        toggleEdit();
      },
      [onChangeProjects, projects, toggleEdit],
    );

    return (
      <>
        <ResumeExperienceCardComponent
          language={language}
          fields={fields}
          values={values}
          index={index}
          projects={projects}
          onInputChange={onInputChange}
          isEndDateDisabled={!startDate}
          onRemoveProject={onRemoveProject}
          onEditProject={onEditProject}
          onAddProject={toggle}
          onRemoveExperience={onRemoveExperience}
        />
        <AddProjectPopup
          fields={fields}
          isShowing={isShowing}
          onClose={toggle}
          onAddProject={onAddProject}
        />
        {projectToEdit && (
          <EditProjectPopup
            fields={fields}
            project={projectToEdit}
            isShowing={isEditShowing}
            onClose={toggleEdit}
            onSaveProject={onSaveProject}
          />
        )}
      </>
    );
  },
);
