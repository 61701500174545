import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { Colors, ICoursesOptions } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet } from 'services/ui';

import { Icon, IconNames } from '../Icon';

export interface IDropDownMenu {
  isOpen: boolean;
  options: ICoursesOptions[];
  selectedOption: ICoursesOptions;
  onOptionClicked: (value: ICoursesOptions) => () => void;
}

export const DropDownMenu = memo(
  ({ isOpen, options, selectedOption, onOptionClicked }: IDropDownMenu) => (
    <DropDownListStyled isOpen={isOpen}>
      {options.map((option) => (
        <ListItem
          onClick={onOptionClicked(option)}
          key={option.value}
          selected={option.value === selectedOption.value}>
          {option.label}
          {option.value === selectedOption.value && <Icon name={IconNames.Check} />}
        </ListItem>
      ))}
    </DropDownListStyled>
  ),
);

const DropDownListStyled = styled.ul<{ isOpen: boolean }>`
  width: 270px;
  ${fontService.getFont({ size: 18, lineHeight: 150, weight: FontWeight.bold })}
  box-shadow: 0px 8px 20px rgba(100, 100, 100, 0.4);
  background-color: ${Colors.white};
  border-radius: 4px;
  padding: 12px 0;
  margin: 0;
  z-index: 100;
  position: absolute;
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen
      ? `
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
          transition: all .3s ease-in-out;
        `
      : `
          transform: translateY(-50px);
          visibility: hidden;
          opacity: 0;
          transition: all .3s ease-in-out;
      `}

  ${tablet} {
    width: 398px;
    ${fontService.getFont({ size: 24, lineHeight: 150, weight: FontWeight.bold })}
  }

  ${desktop} {
    width: 523px;
    ${fontService.h2()}
    margin: 2px 0 0 -24px;
  }
`;

const ListItem = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  height: 32px;
  padding: 4px 16px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${Colors.additional_gray};
    `}

  ${tablet} {
    height: 36px;
  }

  ${desktop} {
    height: 52px;
    padding: 4px 24px;
  }

  &:hover {
    background-color: ${Colors.additional_gray};
  }
`;
