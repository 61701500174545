import * as React from 'react';
import { memo, useCallback } from 'react';

import styled, { css } from 'styled-components';

import { Colors } from 'context';
import fontService, { FontWeight, TextType } from 'services/FontService';
import { desktop } from 'services/ui';

export interface IInput extends IInputStyle {
  value: string;
  placeholder?: string;
  type: 'number' | 'text' | 'password' | 'email';
  label?: string;
  message?: string;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: (value: string) => void;
  max?: number;
  required?: boolean;
}

export interface IInputStyle {
  color?: string;
  disabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  className?: string;
}

export const Input = memo(
  ({
    value,
    name,
    label,
    message,
    onChange,
    onEnter,
    onFocus,
    onBlur,
    max,
    hasError,
    required,
    className,
    ...rest
  }: IInput) => {
    const onKeyDownHandler = useCallback(
      ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter') {
          if (onEnter) onEnter(value);
        }
      },
      [value, onEnter],
    );

    return (
      <Wrapper className={className}>
        <InputLabel show={Boolean(label)} required={required}>
          {label}
        </InputLabel>
        <InputStyled
          value={value}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={max}
          onKeyDown={onKeyDownHandler}
          {...rest}
        />
        <InputMessage show={Boolean(message)} hasError={Boolean(hasError)}>
          {message}
        </InputMessage>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
`;

export const InputLabel = styled.p<{ show: boolean; required?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  color: ${Colors.black};
  background-color: transparent;
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.bold })};
  margin: 0 0 4px 0;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '\\00a0*';
        color: ${Colors.signal_red};
      }
    `}
`;

const InputMessage = styled.p<{ show: boolean; hasError: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  margin-top: 4px;
  ${fontService.text({ type: TextType.small })};
  color: ${({ hasError }) => (hasError ? Colors.signal_red : Colors.gray)};

  ${desktop} {
    padding: 0 12px;
  }
`;

const InputStyled = styled.input<IInputStyle>`
  width: 100%;
  padding: 14px 12px;
  box-sizing: border-box;
  background-color: ${Colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${Colors.gray};
  border-radius: 8px;
  color: ${({ color }) => color || Colors.black};
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.regular })}
  cursor: pointer;
  outline: none;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        color: ${Colors.gray_dark};
        background-color: ${Colors.bg_gray};
        border-color: ${Colors.gray};
        user-select: none;
        cursor: default;
      `;
    }
  }}
  &:focus {
    border-color: ${Colors.main};
  }
  ::placeholder {
    color: ${Colors.gray};
    opacity: 1;
  }
`;
