import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { PhotoUploadComponent } from './PhotoUpload';

interface IPhotoUploadContainer {
  image: File | string;
  removeError: boolean;
  titleText: string;
  infoText: string;
  uploadBtnText: string;
  clearBtnText: string;
  onFileChange: (file: File | string) => void;
}

export const PhotoUpload = memo(
  ({
    image,
    removeError,
    titleText,
    infoText,
    uploadBtnText,
    clearBtnText,
    onFileChange,
  }: IPhotoUploadContainer) => {
    const [imageUrl, setImageUrl] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (typeof image === 'string') {
        setImageUrl(image);
      }
    }, [image]);

    useEffect(() => {
      if (removeError) {
        setError(false);
      }
    }, [removeError]);

    const handleClick = useCallback(() => {
      if (hiddenFileInput && hiddenFileInput.current) {
        hiddenFileInput.current.click();
      }
    }, []);

    const onUpload = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
          const fileUploaded = event.target.files[0];
          const src = URL.createObjectURL(fileUploaded);

          const img = new Image();
          img.src = src;

          const allowedExtensions = /(\.jpeg|\.png|\.jpg)$/i;
          const maxSize = 5242880;
          const maxResolution = 1240;

          if (!allowedExtensions.exec(fileUploaded.name) || fileUploaded.size > maxSize) {
            setError(true);
            return;
          }

          img.onload = () => {
            const { height, width } = img;
            if (height > maxResolution || width > maxResolution) {
              setError(true);
              return;
            }

            setImageUrl(src);
            onFileChange(fileUploaded);
            setError(false);
          };
        }
      },
      [onFileChange],
    );

    const onClear = useCallback(() => {
      setImageUrl('');
      onFileChange('');
    }, [onFileChange]);

    return (
      <PhotoUploadComponent
        titleText={titleText}
        infoText={infoText}
        uploadBtnText={uploadBtnText}
        clearBtnText={clearBtnText}
        onUpload={onUpload}
        onClear={onClear}
        imageUrl={imageUrl}
        hiddenInputRef={hiddenFileInput}
        uploadClick={handleClick}
        error={error}
      />
    );
  },
);
