import { useCallback, useEffect, useState } from 'react';

import { HomeworksType } from 'types';

type LessonCourseworkType = {
  studentsGrades: HomeworksType[];
  onChangeGrade: (id: string, grade: string) => void;
  onCancel: () => void;
};

export const useLessonCoursework = (students?: HomeworksType[]): LessonCourseworkType => {
  const [studentsGrades, setStudentsGrades] = useState<HomeworksType[]>([]);

  useEffect(() => {
    if (students) {
      setStudentsGrades(students);
    }
  }, [students]);

  const onChangeGrade = useCallback(
    (id: string, grade: string) => {
      const newStudentsGrades = studentsGrades.map((item) => {
        if (item.studentId === id) {
          return {
            ...item,
            mark: grade === '' ? null : Number(grade),
          };
        }

        return item;
      });
      setStudentsGrades(newStudentsGrades);
    },
    [studentsGrades],
  );

  const onCancel = useCallback(() => {
    setStudentsGrades(students || []);
  }, [students]);

  return {
    studentsGrades,
    onChangeGrade,
    onCancel,
  };
};
