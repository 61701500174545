import React, { memo } from 'react';

import styled from 'styled-components';
import { Colors } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet } from 'services/ui';

interface IFormGroup {
  groupTitle: string;
  children: React.ReactNode;
  last?: boolean;
}

export const FormFieldset = memo(({ groupTitle, children, last }: IFormGroup) =>
  !last ? (
    <FormFieldsetWrapper>
      <Title>{groupTitle}</Title>
      {children}
    </FormFieldsetWrapper>
  ) : (
    <FormFieldsetLastWrapper>
      <Title>{groupTitle}</Title>
      {children}
    </FormFieldsetLastWrapper>
  ),
);

const FormFieldsetWrapper = styled.div`
  padding: 24px 20px 40px;
  border: 1px solid ${Colors.system_gray};
  border-radius: 24px;
  background-color: ${Colors.white};
  margin-bottom: 32px;

  ${tablet} {
    padding: 32px 32px 48px;
  }

  ${desktop} {
    padding: 48px 60px 60px;
  }
`;

const FormFieldsetLastWrapper = styled(FormFieldsetWrapper)`
  margin-bottom: 0;
  border-bottom: none;
  border-radius: 24px 24px 0 0;
`;

const Title = styled.h3`
  ${fontService.text({ size: 18, lineHeight: 133, weight: FontWeight.bold })};
  margin: 0 0 20px;

  ${tablet} {
    ${fontService.h3()};
    margin: 0 0 32px;
  }
`;
