import React, { memo } from 'react';

import styled, { css } from 'styled-components';

import fontService, { FontWeight } from 'services/FontService';

export interface ITitle extends ITitleStyle {
  text: string;
  position?: 'left' | 'top' | 'right' | 'bottom';
}

export interface ITitleStyle {
  width?: string;
  className?: string;
}

export const Title = memo(({ text, position = 'top', width, className }: ITitle) => (
  <TitleContainer position={position} text={text} className={className} width={width}>
    <TextStyled>{text}</TextStyled>

    <Triangle />
  </TitleContainer>
));

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(7, 7, 7, 0.8);
  top: 100%;
  left: calc(50% - 5px);
  position: absolute;
`;

const TitleContainer = styled.div<ITitle>`
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: rgba(7, 7, 7, 0.8);
  border-radius: 4px;
  display: block;
  color: white;
  position: absolute;
  box-sizing: border-box;
  width: max-content;
  ${({ width }) => {
    if (width) {
      return css`
        max-width: ${width};
        white-space: initial;
      `;
    }

    return css`
      white-space: nowrap;
    `;
  }}
  ${({ position }) => {
    switch (position) {
      case 'top':
        return css`
          bottom: calc(100% + 16px);
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'left':
        return css`
          top: 50%;
          right: calc(100% + 16px);
          transform: translateY(-50%);

          & > ${Triangle} {
            top: 50%;
            left: calc(100%);
            transform: translateY(-50%);
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid rgba(7, 7, 7, 0.8);
          }
        `;
      case 'right':
        return css`
          top: 50%;
          left: calc(100% + 16px);
          transform: translateY(-50%);

          & > ${Triangle} {
            top: 50%;
            left: calc(-10px);
            transform: translateY(-50%) rotate(180deg);
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid rgba(7, 7, 7, 0.8);
          }
        `;
      case 'bottom':
        return css`
          top: calc(100% + 16px);
          left: 50%;
          transform: translateX(-50%);

          & > ${Triangle} {
            top: 0;
            left: calc(50% - 5px);
            transform: translateY(-100%) rotate(-90deg);
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid rgba(7, 7, 7, 0.8);
          }
        `;
      default:
        return css``;
    }
  }}
`;

const TextStyled = styled.span<ITitleStyle>`
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })}
  text-align: center;
`;
