/* eslint-disable no-restricted-syntax */
import { ApolloClient, ApolloLink, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { RestLink } from 'apollo-link-rest';

import { onError } from 'apollo-link-error';
import { authState, appState } from 'core/state';
import { NetworkErrors } from 'context';

import { cache } from '../cache';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_SCHEMA_PATH,
});

const restLink = new RestLink({
  uri: process.env.REACT_APP_REST_PATH,
  endpoints: {
    resume: {
      uri: `${process.env.REACT_APP_REST_PATH}/resume/download_resume`,
      responseTransformer: async (response: any) =>
        response
          .blob()
          .then((blob: Blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Resume.pdf';
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          })
          .then((error: any) => error),
    },
  },
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const { access_token } = authState();
  const oldHeaders = operation.getContext().headers;
  if (access_token) {
    operation.setContext({
      headers: {
        ...oldHeaders,
        Authorization: `JWT ${access_token}`,
      },
    });
  }
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const currentState = authState();

      // TODO: fix this solution for err.extensions.code

      const ErrorDecodingSignature = err.message === 'Error decoding signature';
      const AcessTokenIsExpired = err.message === 'Signature has expired';
      const AcessTokenNoValid = err.message === 'NOT_ACCESS_TO_LMS';
      if (AcessTokenIsExpired) {
        authState({ ...currentState, isTokenRefreshed: false });
      }
      if (ErrorDecodingSignature || AcessTokenNoValid) {
        authState({ ...currentState, isLoggedIn: null });
      }
    }
  }
  if (networkError) {
    if ('result' in networkError) {
      const { statusCode } = networkError;
      const currentState = appState();
      switch (statusCode) {
        case 500:
          appState({
            ...currentState,
            networkError: NetworkErrors.internalServerError,
          });
          break;
        case 503:
          appState({
            ...currentState,
            networkError: NetworkErrors.serviceUnavailable,
          });
          break;
        case 404:
          appState({
            ...currentState,
            networkError: NetworkErrors.pageIsNotAvailable,
          });
          break;
        default:
          console.log({ networkError });
          appState({
            ...currentState,
            networkError: NetworkErrors.otherError,
          });
          break;
      }
    }
  }
});

export const client = new ApolloClient({
  link: from([errorLink, middlewareLink, restLink, uploadLink as any]),
  cache,
  connectToDevTools: true,
});
