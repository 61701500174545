import React, { memo } from 'react';

import ContentLoader from 'react-content-loader';

export const DownloadPreloader = memo((props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={6}
    viewBox="0 0 100% 6"
    backgroundColor="rgba(233, 234, 238, 1)"
    foregroundColor="rgba(28, 214, 23, 1)"
    {...props}>
    <rect width="100%" height="6" rx="3" fill="#E9EAEE" />
    <rect width="259" height="6" rx="3" fill="url(#paint0_linear_3764_4580)" />
  </ContentLoader>
));
