import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { PinInput, PinInputField } from '@chakra-ui/pin-input';
import fontService, { FontWeight, TextType } from 'services/FontService';
import { Colors } from 'context';

export interface IPinInputLMS {
  value: string;
  length?: number;
  disabled?: boolean;
  onChange: (value: string) => void;
  errorMessage?: string;
  className?: string;
}

export const PinInputLMS = ({
  value,
  disabled = false,
  length = 5,
  onChange,
  errorMessage,
  className,
}: IPinInputLMS) => {
  const inputs = useMemo(
    () =>
      Array(length)
        .fill('')
        .map((item, index) => (
          <PinInputWrapper key={index}>
            <PinInputFieldStyled required />
            <PinInputFieldLabel>-</PinInputFieldLabel>
          </PinInputWrapper>
        )),
    [length],
  );

  return (
    <Wrapper className={className}>
      <InnerWrapper>
        <PinInput
          value={value}
          onChange={onChange}
          type="alphanumeric"
          placeholder=""
          isDisabled={disabled}>
          {inputs}
        </PinInput>
      </InnerWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

const fontStyles = css`
  ${fontService.getFont({ size: 24, weight: FontWeight.bold, lineHeight: 100 })}
  text-align: center;
`;

const PinInputFieldLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ${fontStyles};
  color: ${Colors.gray};
  pointer-events: none;
`;

const PinInputFieldStyled = styled(PinInputField)`
  box-sizing: border-box;
  height: 48px;
  width: 46px;
  padding: 0;
  border: 1px solid ${Colors.gray};
  border-radius: 8px;
  outline: none;
  ${fontStyles}
  background-color: ${Colors.white};
  caret-color: transparent;
  cursor: pointer;

  &:focus {
    border-color: ${Colors.main};
  }

  &:valid ~ ${PinInputFieldLabel}, &:disabled ~ ${PinInputFieldLabel} {
    visibility: hidden;
  }

  &:disabled {
    color: ${Colors.gray_dark};
    background-color: ${Colors.bg_gray};
    cursor: default;
  }
`;

const Wrapper = styled.div``;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PinInputWrapper = styled.div`
  position: relative;
`;

const ErrorMessage = styled.p`
  margin-top: 4px;
  padding: 0;
  ${fontService.text({ type: TextType.small })};
  color: ${Colors.signal_red};
`;
