import { useCallback, useState, useMemo, useEffect } from 'react';
import { useGetLessonAttendance } from 'core/hooks';
import { AttendanceType } from 'types';

type LessonJournalType = {
  lessonId: string;
  lessonComment: string;
};

export const useLessonJournal = ({ lessonId, lessonComment: comment }: LessonJournalType) => {
  const [attendances, setAttendances] = useState<AttendanceType[]>([]);
  const [lessonComment, setLessonComment] = useState(comment);

  const { attendance: students, loading, error } = useGetLessonAttendance(lessonId);

  useEffect(() => {
    setAttendances(students);
  }, [students]);

  const isAllStudentsChecked = useMemo(
    () => !attendances.some(({ checked }) => !checked),
    [attendances],
  );

  const onChangeComment = useCallback((event) => setLessonComment(event.target.value), []);

  const onCheckStudent = useCallback(
    (index: number) => {
      const attendancesArray = [...attendances];
      attendancesArray[index] = { ...attendances[index], checked: !attendances[index].checked };

      setAttendances(attendancesArray);
    },
    [attendances],
  );

  const onCheckAllStudents = useCallback(() => {
    const attendancesArray = [...attendances];
    attendancesArray.forEach((item, index) => {
      attendancesArray[index] = { ...item, checked: true };
    });

    setAttendances(attendancesArray);
  }, [attendances]);

  const onUncheckAllStudents = useCallback(() => {
    setAttendances(students);
  }, [students]);

  const onCancel = useCallback(() => {
    setLessonComment('');
    setAttendances(students);
  }, [students]);

  return {
    comment: lessonComment,
    attendances,
    loadingAttendances: loading,
    errorAttendances: error,
    isAllStudentsChecked,
    onChangeComment,
    onCheckStudent,
    onCheckAllStudents,
    onUncheckAllStudents,
    onCancel,
  };
};
