import React, { memo, useCallback, useMemo } from 'react';

import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { tablet } from 'services/ui';
import { Colors, IResumeFields, ISelectOptions, ISocialMedia, SocialMedia } from 'context';
import { Icon, IconNames } from 'atoms';
import { InputSocialMedia } from 'molecules';
import { IPersonalInfoValues, PersonalInfoBlockComponent } from './PersonalInfoBlock';
import { getSocialMediaOptions } from './utils';

interface IPersonalInfoBlockContainer {
  socialMediaOptions: ISelectOptions[];
  values: IPersonalInfoValues;
  errors?: {
    [key in keyof IPersonalInfoValues]?: string;
  };
  removeImageError: boolean;
  fields: IResumeFields;
  onInputChange: (name: string, value: string | File | SocialMedia) => void;
  onChangeSocialMedia: (name: string, value: ISocialMedia[]) => void;
}

export const PersonalInfoBlock = memo(
  ({
    socialMediaOptions,
    values,
    errors,
    removeImageError,
    fields,
    onInputChange,
    onChangeSocialMedia,
  }: IPersonalInfoBlockContainer) => {
    const onRemoveSocialMedia = useCallback(
      (id: string) => {
        onChangeSocialMedia(
          'socialMedia',
          values.socialMedia.filter((media) => media.id !== id),
        );
      },
      [onChangeSocialMedia, values.socialMedia],
    );

    const onAddSocialMediaClick = useCallback(() => {
      onChangeSocialMedia('socialMedia', [
        ...values.socialMedia,
        { id: nanoid(), name: '', link: '' },
      ]);
    }, [onChangeSocialMedia, values.socialMedia]);

    const mediaInputs: React.ReactNode = useMemo(
      () => (
        <Wrapper>
          {!!values.socialMedia.length &&
            values.socialMedia.map((item, index) => (
              <InputWrapper key={item.id}>
                <InputSocialMedia
                  index={index}
                  options={getSocialMediaOptions(socialMediaOptions, values.socialMedia, item)}
                  preselectedOption={socialMediaOptions.find(
                    (option) => option.value === item.name,
                  )}
                  linkValue={item.link}
                  namePlaceholder={fields.personalInfo.socialMedia.mediaPlaceholder}
                  linkPlaceholder={fields.personalInfo.socialMedia.linkPlaceholder}
                  onInputChange={onInputChange}
                />

                <RemoveButton onClick={() => onRemoveSocialMedia(item.id)}>
                  <Icon name={IconNames.GrayCloseSm} width={24} height={24} />
                </RemoveButton>
              </InputWrapper>
            ))}
        </Wrapper>
      ),
      [
        fields.personalInfo.socialMedia.mediaPlaceholder,
        fields.personalInfo.socialMedia.linkPlaceholder,
        socialMediaOptions,
        values.socialMedia,
        onInputChange,
        onRemoveSocialMedia,
      ],
    );

    const showAddSocialMedia = useMemo(
      () => socialMediaOptions.length > values.socialMedia.length,
      [socialMediaOptions.length, values.socialMedia.length],
    );

    return (
      <PersonalInfoBlockComponent
        values={values}
        fields={fields}
        errors={errors}
        removeImageError={removeImageError}
        showAddSocialMedia={showAddSocialMedia}
        onAddSocialMedia={onAddSocialMediaClick}
        onInputChange={onInputChange}>
        {mediaInputs}
      </PersonalInfoBlockComponent>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InputWrapper = styled.div`
  position: relative;

  ${tablet} {
    display: flex;
    align-items: center;
  }

  & > div:first-child {
    flex-grow: 1;
  }
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;

  ${tablet} {
    position: static;
  }

  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 16px;

  &:hover {
    & svg > * {
      transition: 0.5s;
      fill: ${Colors.black};
    }
  }
`;
