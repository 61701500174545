import { AllCoursesQueryVariables, GroupEdge, useAllCoursesQuery, PageInfo } from 'core/graphql';
import { ICourseCard } from 'components/molecules';

import { useCallback } from 'react';
import { transformCoursesNode } from './transform';

type coursesType = {
  data: ICourseCard[];
  loading?: boolean;
  error?: string;
  totalCount?: number | null;
  pageInfo?: Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>;
  operations: {
    fetchMoreData: (vars: AllCoursesQueryVariables) => void;
  };
};
const useGetCoursesFromApi = () => {
  const { data, loading, error, fetchMore } = useAllCoursesQuery({
    fetchPolicy: 'network-only',
    variables: {
      first: 5,
    },
    notifyOnNetworkStatusChange: true,
  });

  const result = useCallback((): ICourseCard[] => {
    const edges = data?.allGroupsForLms?.edges;
    if (!edges) {
      return [];
    }

    return edges.reduce((acc: ICourseCard[], course) => {
      if (course) {
        const coursesArray = transformCoursesNode(course as GroupEdge);
        if (coursesArray) {
          return [...acc, coursesArray];
        }
      }
      return acc;
    }, []);
  }, [data?.allGroupsForLms?.edges]);

  return {
    fetchMore,
    result: result(),
    error,
    loading,
    pageInfo: data?.allGroupsForLms?.pageInfo,
    totalCount: data?.allGroupsForLms?.totalCount,
  };
};

export const useCourses = (): coursesType => {
  const {
    result,
    error,
    loading,
    totalCount,
    pageInfo,
    fetchMore = () => null,
  } = useGetCoursesFromApi();

  const fetchMoreData = useCallback(
    ({ active, finished, after }: AllCoursesQueryVariables) => {
      fetchMore({
        variables: {
          active,
          finished,
          after,
        },
      });
    },
    [fetchMore],
  );

  return {
    data: result || [],
    loading,
    error: error?.message || '',
    totalCount,
    pageInfo,
    operations: {
      fetchMoreData,
    },
  };
};
