import React, { memo, useCallback } from 'react';

import { nanoid } from 'nanoid';

import { IResumeDate, IResumeFields, Languages } from 'context';
import { IEducationValues, EducationBlockComponent } from 'organisms';

interface IEducationBlockContainer {
  language: Languages;
  fields: IResumeFields;
  values: IEducationValues[];
  onChangeEducation: (name: string, value: IEducationValues[]) => void;
  onInputChange: (name: string, value: string | IResumeDate) => void;
}

const initialValues = {
  institution: '',
  degree: '',
  specialization: '',
  startDate: {
    year: '',
    month: '',
  },
  endDate: {
    year: '',
    month: '',
  },
};

export const EducationBlock = memo(
  ({ language, fields, values, onInputChange, onChangeEducation }: IEducationBlockContainer) => {
    const onRemoveEducation = useCallback(
      (educationId: string) => {
        onChangeEducation(
          'education',
          values.filter(({ id }) => id !== educationId),
        );
      },
      [values, onChangeEducation],
    );

    const onAddEducation = useCallback(() => {
      onChangeEducation('education', [...values, { id: nanoid(), ...initialValues }]);
    }, [values, onChangeEducation]);

    return (
      <EducationBlockComponent
        language={language}
        fields={fields}
        education={values}
        onRemoveEducation={onRemoveEducation}
        onAddEducation={onAddEducation}
        onInputChange={onInputChange}
      />
    );
  },
);
