import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import fontService, { FontWeight, TextType } from 'services/FontService';
import { Colors, UserRole } from 'context';
import { Icon, Info, IconNames, Title } from 'atoms';
import { ProgramLessonType } from 'components/types';
import { authState } from 'core';
import { isScrollEnd } from 'molecules/CourseProgram/MobileLessons';

export type LessonsType = { lessons: ProgramLessonType[] };

export const Lessons = memo(({ lessons }: LessonsType) => {
  const { t } = useTranslation();
  const { groupId, lessonId } = useParams();
  const { active_role } = useReactiveVar(authState);

  const [scrollEnd, setScrollEnd] = useState(false);

  const lessonsRef = useRef<HTMLAnchorElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    if (scrollContainerRef.current) {
      setScrollEnd(isScrollEnd(scrollContainerRef));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (scrollContainerRef.current && lessonsRef.current) {
        const scrollContainerHalfHeight = scrollContainerRef.current.offsetHeight / 2;
        scrollContainerRef.current.scrollTop =
          lessonsRef.current.offsetTop - scrollContainerHalfHeight;
        setScrollEnd(isScrollEnd(scrollContainerRef));
      }
    }, 0);
  }, []);

  const showLessons = useCallback(() => {
    if (!lessons.length) {
      return (
        <InfoContainer>
          <Info text={t('courseInfo.noAvailableLessons')} />
        </InfoContainer>
      );
    }
    return lessons.map(({ id, name, index, isEnded, needToFinish }) => (
      <Link
        to={`/courses/${groupId}/${id}/lessonDescription`}
        key={id}
        ref={lessonId === id ? lessonsRef : null}>
        <Lesson isEnded={isEnded} isActive={lessonId === id}>
          <LessonNumber>{index}.</LessonNumber>
          <LessonName>
            <span>
              {name}
              <WarningWrapper>
                {active_role === UserRole.TEACHER && needToFinish && (
                  <IconStyled name={IconNames.WarningYellow} width={16} height={16} />
                )}
                <TitleStyled text={t('courseInfo.needToFinish')} position="right" />
              </WarningWrapper>
            </span>
          </LessonName>
        </Lesson>
      </Link>
    ));
  }, [groupId, lessonId, lessons, active_role, t]);

  return (
    <ProgramContainer>
      <LessonsHeader>{t('courseInfo.program')}</LessonsHeader>
      <LessonsPlaceholder>
        <LessonsOverflow ref={scrollContainerRef} onScroll={onScroll}>
          <LessonsContainer>{showLessons()}</LessonsContainer>
        </LessonsOverflow>
      </LessonsPlaceholder>
      <LessonsShadow scrollEnd={scrollEnd} />
    </ProgramContainer>
  );
});

const InfoContainer = styled.div`
  margin-top: 16px;
  width: max-content;
`;

const ProgramContainer = styled.div`
  width: 285px;
  margin-bottom: 43px;
  margin-top: 32px;
  position: relative;
`;

const LessonsPlaceholder = styled.div`
  height: 584px;
  position: relative;
`;

const LessonsOverflow = styled.div`
  height: 100%;
  width: 420px;
  position: absolute;
  top: 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
  }
  scrollbar-width: none;
`;

const LessonsContainer = styled.div`
  height: 584px;
  width: 285px;
  position: absolute;
  top: 0;
`;

const LessonsShadow = styled.div<{ scrollEnd: boolean }>`
  display: ${({ scrollEnd }) => scrollEnd && 'none'};
  position: absolute;
  bottom: 0;
  left: 0;
  height: 44px;
  width: 301px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  border-bottom: 1px solid ${Colors.bg_gray};
  pointer-events: none;
`;

const LessonsHeader = styled.p`
  ${fontService.text({ type: TextType.normal, weight: FontWeight.bold })};
  margin-bottom: 12px;
`;

const Lesson = styled.div<{ isEnded: boolean; isActive: boolean }>`
  ${fontService.text({ type: TextType.small })};
  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 500;
      background: ${Colors.additional_gray};
      border-radius: 4px;
    `}
  display: flex;
  align-items: baseline;
  margin: 5px 0;
  padding: 7px 16px;
  color: ${({ isEnded }) => (isEnded ? Colors.marker_green : Colors.black)};
`;

const LessonNumber = styled.span`
  margin: 0 4px 0 4px;
`;

const LessonName = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TitleStyled = styled((props) => <Title {...props} />)`
  display: none;
  z-index: 10;
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  margin-left: 4px;
`;

const WarningWrapper = styled.span`
  display: inline-block;
  position: relative;
  bottom: -3px;
  &:hover {
    ${TitleStyled} {
      display: flex;
    }
  }
`;
