import { ISelectOptions, ISocialMedia } from 'context';

export const getSocialMediaOptions = (
  options: ISelectOptions[],
  socialMedia: ISocialMedia[],
  currentSocialMedia: ISocialMedia,
): ISelectOptions[] =>
  options.filter(
    (option) =>
      !socialMedia.find(({ name }) => name === option.value && name !== currentSocialMedia.name),
  );
