import React, { ChangeEvent, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'atoms';
import { Colors } from 'context';
import fontService, { TextType } from 'services/FontService';

interface IEmailStep {
  email: string;
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onEmailSendClick: () => void;
  buttonDisabled: boolean;
  errorMessage?: string;
  waitingTimeForNextCode: number;
}

export const EmailStep = memo(
  ({
    email,
    onEmailChange,
    onEmailSendClick,
    buttonDisabled,
    errorMessage,
    waitingTimeForNextCode,
  }: IEmailStep) => {
    const { t } = useTranslation();
    return (
      <Wrapper>
        <Title>{t('login.emailStep.authorization')}</Title>
        <InfoSubtitle>{t('login.emailStep.pleaseEnterEmail')}</InfoSubtitle>
        <InputStyled
          value={email}
          onChange={onEmailChange}
          type="email"
          label={t('login.emailStep.emailLabel')}
          placeholder="example@gmail.com"
          hasError={Boolean(errorMessage)}
          message={errorMessage}
        />
        <ButtonStyled
          onClick={onEmailSendClick}
          disabled={buttonDisabled || waitingTimeForNextCode > 0}>
          {waitingTimeForNextCode > 0
            ? `${t('login.getCodeAfterTime', { waitingTimeForNextCode })}`
            : `${t('login.getCode')}`}
        </ButtonStyled>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  padding-bottom: 33px;
`;

const Title = styled.p`
  ${fontService.h2()};
`;

const InfoSubtitle = styled.p`
  margin-top: 8px;
  ${fontService.text({ type: TextType.normal })};
  color: ${Colors.gray_dark};
`;

const InputStyled = styled(Input)`
  margin-top: 24px;
`;

const ButtonStyled = styled(Button)`
  margin-top: 24px;
`;
