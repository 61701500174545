import { useGroupLessonAttachmentQuery } from 'core/graphql';

type attachmentType = {
  attachmentFile?: string | null;
  loading?: boolean;
  error?: string;
};

export const useGetAttachment = (attachmentId: string, attachmentRole?: string): attachmentType => {
  const { data, loading, error } = useGroupLessonAttachmentQuery({
    variables: { attachmentId, attachmentRole },
  });

  return {
    attachmentFile: data?.groupLessonAttachment?.file,
    loading,
    error: error?.message,
  };
};
