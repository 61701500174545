import React, { memo } from 'react';

import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Colors } from 'context';

import { Icon, IconNames } from 'atoms';

export interface IInputTag {
  text: string;
  onClick: () => void;
}

export const InputTag = memo(({ text, onClick }: IInputTag) => (
  <Wrapper>
    <Text>{text}</Text>
    <Close onClick={onClick}>
      <Icon name={IconNames.GrayCloseSm} width={16} height={16} />
    </Close>
  </Wrapper>
));

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  cursor: pointer;
  background-color: ${Colors.additional_gray};
  padding: 4px 4px 4px 8px;
  border-radius: 4px;
`;

const Text = styled.div`
  ${fontService.text({ size: 14, lineHeight: 100, weight: FontWeight.regular })};
  margin-right: 4px;
`;

const Close = styled.div`
  &:hover {
    & svg > * {
      transition: 0.5s;
      fill: ${Colors.black};
    }
  }
`;
