import { useCallback } from 'react';
import { FinishLessonMutationVariables, useFinishLessonMutation } from 'core/graphql';

import { CourseQuery, LessonAttendancesQuery, LessonQuery } from 'core/operations';

type FinishLessonType = [
  (variables: FinishLessonMutationVariables) => void,
  { success: boolean; loading: boolean; error?: string | null },
];

export const useFinishLesson = (groupId = '', groupLessonId = ''): FinishLessonType => {
  const [finishLessonMutation, { data, loading }] = useFinishLessonMutation({
    refetchQueries: [
      { query: CourseQuery, variables: { groupId } },
      { query: LessonQuery, variables: { groupLessonId } },
      { query: LessonAttendancesQuery, variables: { groupLessonId } },
    ],
  });

  const finishLesson = useCallback(
    (variables) => finishLessonMutation({ variables }),
    [finishLessonMutation],
  );

  return [
    finishLesson,
    {
      success: Boolean(data?.finishLmsLesson?.success),
      loading,
      error: data?.finishLmsLesson?.customErrors?.[0]?.code,
    },
  ];
};
