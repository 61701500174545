import React, { memo } from 'react';

import styled, { css } from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';

import { Colors, ISelectOptions } from 'context';
import { Icon, IconNames } from 'atoms';

export interface ISelect {
  options: ISelectOptions[];
  selectedOption: ISelectOptions;
  placeholder?: string;
  disabled?: boolean;
  isOpened: boolean;
  onOptionClick: (option: ISelectOptions) => void;
  onSelectClick: () => void;
  dropdownRef: React.Ref<HTMLDivElement>;
  className?: string;
}

export const SelectComponent = memo(
  ({
    className,
    options,
    selectedOption,
    placeholder,
    disabled,
    isOpened,
    onSelectClick,
    onOptionClick,
    dropdownRef,
  }: ISelect) => (
    <Wrapper ref={dropdownRef} className={className}>
      <SelectInput disabled={disabled} onClick={onSelectClick} isOpened={isOpened}>
        <SelectedOption selected={Boolean(selectedOption.value.length)}>
          {selectedOption.value.length ? selectedOption.label : placeholder}
        </SelectedOption>
        <Icon
          name={isOpened && !disabled ? IconNames.TriangleUp : IconNames.TriangleDown}
          width={12}
          height={12}
        />
      </SelectInput>

      <Dropdown isOpened={isOpened} disabled={disabled}>
        {options.map((option) => (
          <DropdownItem
            onClick={() => onOptionClick(option)}
            key={option.value}
            selected={option.value === selectedOption.value}>
            {option.label}
          </DropdownItem>
        ))}
      </Dropdown>
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  position: relative;
`;

export const SelectInput = styled.div<{ disabled?: boolean; isOpened: boolean }>`
  position: relative;
  width: 100%;
  padding: 14px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  ${({ isOpened }) =>
    isOpened
      ? css`
          border: 1px solid ${Colors.main};
          z-index: 10;
        `
      : css`
          border: 1px solid ${Colors.gray};
        `};
  border-radius: 8px;
  color: ${Colors.black};
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.regular })}
  cursor: pointer;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        color: ${Colors.gray_dark};
        background-color: ${Colors.bg_gray};
        border-color: ${Colors.gray};
        user-select: none;
        cursor: default;
      `;
    }
  }}
`;

const SelectedOption = styled.span<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? Colors.black : Colors.gray)};
`;

const Dropdown = styled.div<{ isOpened: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  max-height: 192px;
  overflow: scroll;
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.regular })}
  box-shadow: 0 8px 20px rgba(100, 100, 100, 0.4);
  background-color: ${Colors.white};
  border-radius: 8px;
  padding: 6px 0;
  margin: 2px 0 0 0;
  z-index: 100;
  position: absolute;
  cursor: pointer;
  ::-webkit-scrollbar-track {
    display: none;
  }
  ${({ isOpened }) =>
    isOpened
      ? `
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
        transition: all .3s ease-in-out;
      `
      : `
        transform: translateY(-50px);
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in-out;
      `}

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `}
`;

const DropdownItem = styled.li<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  height: 36px;
  padding: 8px 12px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${Colors.additional_gray};
    `}

  &:hover {
    background-color: ${Colors.additional_gray};
  }
`;
