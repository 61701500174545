import { makeVar } from '@apollo/client';
import { NetworkErrors } from 'context';

export interface IAppState {
  networkError: NetworkErrors | null;
}

export const defaultAppState: IAppState = Object.freeze({
  networkError: null,
});

export const appState = makeVar(defaultAppState);
