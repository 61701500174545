import { authState, useMeQuery } from 'core';

import { IUserData } from 'components/types';
import { useMemo } from 'react';
import { UserRole } from 'context';
import { useReactiveVar } from '@apollo/client';

type userType = {
  user: IUserData;
  loading: boolean;
  error: string;
};

export const useUser = (): userType => {
  const { data, loading, error } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const { active_role } = useReactiveVar(authState);

  const userData: IUserData = useMemo(
    () => ({
      firstName: data?.me?.firstName || '',
      lastName: data?.me?.lastName || '',
      email: data?.me?.email || '',
      phone: data?.me?.phone || '',
      name:
        data?.me?.firstName && data?.me?.lastName
          ? `${data?.me?.firstName} ${data?.me?.lastName}`
          : '',
      role: active_role || '',
      hasRoles:
        data?.me?.roles
          .filter((role) => Object.values(UserRole).includes(role as UserRole))
          .map((role) => role as UserRole) || [],
      avatarImage: active_role === UserRole.TEACHER ? data?.me?.teacher?.photo || '' : '',
      groupsCount:
        active_role === UserRole.TEACHER
          ? data?.me?.teacher?.groupsCount || 0
          : active_role === UserRole.STUDENT
          ? data?.me?.student?.groupsCount || 0
          : data?.me?.methodistGroupsCount || 0,
    }),
    [data?.me, active_role],
  );

  return {
    user: userData,
    loading,
    error: error?.message || '',
  };
};
