import { gql } from '@apollo/client';

export const ResumeQuery = gql`
  query StudentResume($language: String) {
    studentResume(language: $language) {
      id
      firstName
      lastName
      email
      phone
      jobTitle
      additionalInfo
      image
      language
      tools {
        toolsName
      }
      skills {
        skillName
      }
      educations {
        id
        edInstitution
        degree
        specialization
        startDateYear
        startDateMonth
        endDateYear
        endDateMonth
      }
      cvMedias {
        id
        media {
          mediaName
        }
        socialLink
      }
      cvExperience {
        id
        company
        jobTitle
        startDateYear
        startDateMonth
        endDateYear
        endDateMonth
        projects {
          id
          projectName
          description
          summary
          technologies
        }
      }
      cvLanguages {
        id
        languageLevel
        language {
          language
        }
      }
    }
  }
`;

export const ResumeSocialMediaQuery = gql`
  query ResumeSocialMedia {
    allCvSocialMedias {
      edges {
        node {
          id
          mediaName
        }
      }
    }
  }
`;
