import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { desktop, mobile, tablet } from 'services/ui';

import { Colors } from 'context';
import { PopupContainer } from 'molecules';
import { Button } from 'atoms';

export interface ILeaveResumePagePopup {
  isShowing: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export const LeaveResumePagePopup = memo(
  ({ isShowing, onClose, onContinue }: ILeaveResumePagePopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer title={t('resume.wontBeSaved')} isShowing={isShowing} onClose={onClose}>
        <Wrapper>
          <Content>{t('resume.youAreGoingToLeavePage')}</Content>

          <ButtonsWrapper>
            <StyledCloseButton variant="text" onClick={onClose}>
              {t('resume.button.cancel')}
            </StyledCloseButton>
            <StyledButton onClick={onContinue}>{t('resume.button.continue')}</StyledButton>
          </ButtonsWrapper>
        </Wrapper>
      </PopupContainer>
    );
  },
);

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 20px 40px;

  ${tablet} {
    padding: 32px 40px 64px;
  }

  ${desktop} {
    padding: 0 60px 64px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  background-color: ${Colors.bg_gray};
  padding: 20px 20px 32px;
  border-radius: 0 0 24px 24px;

  ${tablet} {
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    padding: 12px 40px;
  }

  ${desktop} {
    padding: 16px 60px;
  }
`;

const StyledButton = styled(Button)`
  ${tablet} {
    max-width: max-content;
  }
`;

const StyledCloseButton = styled(StyledButton)`
  ${mobile} {
    border: 1px solid ${Colors.gray};
  }
`;
