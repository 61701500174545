import React, { memo } from 'react';

import styled from 'styled-components';
import { tablet } from 'services/ui';

import { Select, Input } from 'atoms';
import { Colors, ISelectOptions, SocialMedia } from 'context';
import { SelectInput } from 'atoms/Select/Select';

export interface IInputSocialMedia {
  index: number;
  options: ISelectOptions[];
  preselectedOption?: ISelectOptions;
  namePlaceholder: string;
  linkPlaceholder: string;
  linkValue: string;
  onInputChange: (name: string, value: string | SocialMedia) => void;
}

export const InputSocialMedia = memo(
  ({
    index,
    options,
    preselectedOption,
    namePlaceholder,
    linkPlaceholder,
    linkValue,
    onInputChange,
  }: IInputSocialMedia) => (
    <Wrapper>
      <StyledSelect
        preselectedOption={preselectedOption}
        options={options}
        placeholder={namePlaceholder}
        disabled={false}
        onSelect={(option) =>
          onInputChange(`socialMedia[${index}].name`, option.value as SocialMedia)
        }
      />
      <StyledInput
        type="text"
        placeholder={linkPlaceholder}
        value={linkValue}
        onChange={({ target: { value } }) => onInputChange(`socialMedia[${index}].link`, value)}
        max={300}
      />
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledSelect = styled(Select)`
  ${SelectInput} {
    width: 88%;

    ${tablet} {
      width: 190px;
      border-radius: 8px 0 0 8px;
    }
  }
`;

const StyledInput = styled(Input)`
  width: 100%;

  ${tablet} {
    input {
      border-left: none;
      border-radius: 0 8px 8px 0;

      :focus {
        position: relative;
        z-index: 10;
        margin-left: -1px;
        border-left: 1px solid ${Colors.main};
      }
    }
  }
`;
