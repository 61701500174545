import * as React from 'react';
import { memo } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { tablet } from 'services/ui';

export interface ITextArea extends ITextAreaStyle {
  name?: string;
  value: string;
  placeholder?: string;
  title?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows: number;
  maxLength?: number;
}

interface ITextAreaStyle {
  color?: string;
  borderColor?: string;
  disabled?: boolean;
  hasError?: boolean;
}

export const TextArea = memo(
  ({ name, value, placeholder, title, onChange, maxLength, rows, ...rest }: ITextArea) => (
    <>
      {title && <TitleStyled>{title}</TitleStyled>}
      <StyledTextArea
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        maxLength={maxLength}
        spellCheck="false"
        {...rest}
      />
    </>
  ),
);

const StyledTextArea = styled.textarea<ITextAreaStyle>`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 10px;
  background-color: ${Colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor || Colors.system_gray};
  border-radius: 4px;
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.regular })}
  color: ${({ color }) => color || Colors.black};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  outline: none;
  user-select: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  resize: none;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        color: ${Colors.gray_dark};
        background-color: ${Colors.bg_gray};
      `;
    }
  }}
  &:focus {
    border-color: ${Colors.main};
  }
  ::placeholder {
    color: ${Colors.gray};
    opacity: 1;
  }
`;

const TitleStyled = styled.div`
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}
  margin-bottom: 12px;

  ${tablet} {
    ${fontService.h3()}
  }
`;
