import { AttendanceType } from 'types';

type attendances = Array<{
  id: string;
  attendance?: boolean | null;
  student?: {
    user?: {
      firstName: string;
      lastName: string;
    } | null;
  };
} | null>;

export const transformAttendances = (attendances: attendances): AttendanceType[] =>
  attendances.reduce((attendancesArray: AttendanceType[], attendance) => {
    if (!attendance?.student?.user) {
      return attendancesArray;
    }

    return [
      ...attendancesArray,
      {
        attendanceId: attendance?.id,
        studentName: `${attendance?.student?.user?.firstName} ${attendance?.student?.user?.lastName}`,
        checked: Boolean(attendance.attendance),
      },
    ];
  }, []);
