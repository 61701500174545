import { useState, useCallback } from 'react';
import { AUTHORIZATION_STEPS } from 'context';

export const useAuthStep = () => {
  const [step, setStep] = useState(AUTHORIZATION_STEPS.email);
  const [isOpenInfo, setIsOpenInfo] = useState(false);

  const onBackButtonClick = useCallback(() => {
    setStep(AUTHORIZATION_STEPS.email);
    setIsOpenInfo(false);
  }, []);

  const onHelpButtonClick = useCallback(() => setIsOpenInfo(!isOpenInfo), [isOpenInfo]);

  return { step, isOpenInfo, setStep, onBackButtonClick, onHelpButtonClick };
};
