import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import 'videojs-hotkeys';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import styled from 'styled-components';

import PlayIcon from 'assets/icons/rectangle_yellow.svg';
import { UserRole } from 'context';
import { desktop, mobile, tablet } from 'services/ui';

/* eslint-disable jsx-a11y/media-has-caption */

interface IVideoPlayerProps {
  src: string;
  active_role?: string | null;
  onReady?: () => void;
}

export const VideoPlayer = memo(({ src, active_role, onReady }: IVideoPlayerProps) => {
  const initialOptions = useMemo(
    () => ({
      sources: [{ src }],
      fluid: true,
      aspectRatio: '16:11',
      controls: true,
      playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      poster: ' ',
      controlBar: {
        skipButtons: {
          forward: 5,
          backward: 5,
        },
        volumePanel: {
          vertical: true,
          inline: false,
        },
      },
      plugins: {
        hotkeys: {
          volumeStep: 0.1,
          seekStep: 5,
          enableModifiersForNumbers: false,
        },
      },
    }),
    [src],
  );

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<any | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!playerRef.current && videoElement) {
      playerRef.current = videojs(videoElement, {
        ...initialOptions,
      }).ready(() => onReady && onReady());
    }
  }, [initialOptions, videoRef, onReady]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const onContextMenuClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (active_role !== UserRole.METHODIST) {
        event.preventDefault();
      }
    },
    [active_role],
  );

  return (
    <VideoPlayerWrapper onContextMenu={onContextMenuClick}>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </VideoPlayerWrapper>
  );
});

const VideoPlayerWrapper = styled.div`
  .video-js {
    border-radius: 16px;
    font-family: Rubik, sans-serif;

    .vjs-remaining-time {
      display: none;
    }

    .vjs-current-time,
    .vjs-duration,
    .vjs-time-divider {
      display: block;
    }

    .vjs-current-time,
    .vjs-duration {
      min-width: 72px;
      width: unset;
      padding: 0 10px;
    }

    .vjs-time-divider {
      text-align: center;
      padding: 0;
      min-width: unset;
      width: 1em;
    }

    &:hover {
      .vjs-big-play-button {
        background: url(${PlayIcon}) no-repeat center transparent;
        background-size: 80%;
      }
    }

    .vjs-modal-dialog {
      border-radius: 16px;
    }

    .vjs-time-control {
      line-height: 2.8em;
      font-size: 14px;
    }

    video:focus-visible {
      outline: none;
    }

    .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
      justify-content: center;
      display: flex;

      ${mobile} {
        display: none;
      }
    }

    ${mobile} {
      .vjs-control-bar {
        justify-content: center;
      }

      .vjs-control {
        width: 3.3em;
      }

      .vjs-picture-in-picture-control {
        display: none;
      }

      .vjs-progress-control {
        position: absolute;
        top: -26px;
        width: 100%;
      }

      .vjs-current-time,
      .vjs-duration {
        min-width: 54px;
        width: unset;
        padding: 0 0;
      }
    }
    @media (max-width: 440px) {
      .vjs-control-bar {
        justify-content: space-between;
      }
    }
  }

  .vjs-big-play-button {
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -30px;
    border: none;
    background: url(${PlayIcon}) no-repeat center transparent;
    background-size: 80%;

    &:focus {
      background: url(${PlayIcon}) no-repeat center transparent;
      background-size: 80%;
    }

    .vjs-icon-placeholder {
      display: none;
    }

    ${tablet} {
      width: 120px;
      height: 120px;
      margin-top: -60px;
      margin-left: -50px;
    }

    ${desktop} {
      width: 160px;
      height: 160px;
      margin-top: -80px;
      margin-left: -80px;
    }
  }

  .vjs-control-bar {
    border-radius: 0 0 16px 16px;
    height: 4em;
  }

  .vjs-button > .vjs-icon-placeholder:before {
    line-height: 2.2em;
  }

  .vjs-time-control {
    line-height: 4em;
  }

  .vjs-playback-rate .vjs-playback-rate-value {
    line-height: 2.5em;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    bottom: 2em;
  }

  .vjs-menu-content::-webkit-scrollbar {
    display: none;
  }
  .vjs-menu-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .vjs-volume-bar {
    margin: 1.85em 0.45em;
  }

  .vjs-poster {
    border-radius: 16px;
    position: relative;
  }
`;
