import { useCallback, useState } from 'react';

type ModalType = {
  isShowing: boolean;
  toggle: () => void;
};

export const useModal = (): ModalType => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = useCallback(() => setIsShowing((prevState) => !prevState), []);

  return {
    isShowing,
    toggle,
  };
};
