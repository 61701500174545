import React, { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserRole } from 'context';
import { Icon, IconNames, ITab } from 'atoms';
import { LessonType } from 'types';

import styled from 'styled-components';
import { PATHS } from 'router/config';

type ILessonTabs = {
  pathToDescription: string;
  pathToTasks: string;
  tabs: ITab[];
};

interface ILessonTabsVariables {
  groupId?: string;
  lessonId?: string;
  userRole?: string | null;
  currentLesson?: LessonType | null;
  dateIsNow?: boolean;
}

export const useLessonTabs = ({
  groupId,
  lessonId,
  userRole,
  currentLesson,
  dateIsNow,
}: ILessonTabsVariables): ILessonTabs => {
  const { t } = useTranslation();
  const pathToDescription = useMemo(
    () => `/courses/${groupId}/${lessonId}/${PATHS.lessonDescription}`,
    [groupId, lessonId],
  );

  const pathToStudentMaterials = useMemo(
    () => `/courses/${groupId}/${lessonId}/${PATHS.lessonStudentAttachments}`,
    [groupId, lessonId],
  );

  const pathToTeacherMaterials = useMemo(
    () => `/courses/${groupId}/${lessonId}/${PATHS.lessonTeacherAttachments}`,
    [groupId, lessonId],
  );

  const pathToJournal = useMemo(
    () => `/courses/${groupId}/${lessonId}/${PATHS.lessonJournal}`,
    [groupId, lessonId],
  );

  const pathToTasks = useMemo(
    () => `/courses/${groupId}/${lessonId}/${PATHS.lessonTasks}`,
    [groupId, lessonId],
  );

  const pathToResults = useMemo(
    () => `/courses/${groupId}/${lessonId}/${PATHS.lessonResults}`,
    [groupId, lessonId],
  );

  const isTasksTabActive = useMatch({ path: pathToTasks, end: false });

  const journalTab = useMemo(
    () =>
      currentLesson?.needToFinish ? (
        <TabText>
          {t('lesson.tabs.journal')}
          <IconStyled name={IconNames.WarningYellow} width={16} height={16} />
        </TabText>
      ) : (
        `${t('lesson.tabs.journal')}`
      ),
    [currentLesson?.needToFinish, t],
  );

  const teacherTabs: ITab[] = useMemo(
    () => [
      {
        children: `${t('lesson.tabs.lesson')}`,
        to: pathToDescription,
      },
      ...(currentLesson?.hasTeacherAttachment
        ? [
            {
              children: `${t('lesson.tabs.attachments')}`,
              to: pathToTeacherMaterials,
            },
          ]
        : []),
      {
        children: journalTab,
        to: pathToJournal,
        isEnabled: currentLesson?.isEnded || dateIsNow,
        enabledText: `${t('lesson.tabs.journalTooltip')}`,
      },
      ...(currentLesson?.taskTypes.length
        ? [
            {
              children: `${t('lesson.tabs.taskEvaluation')}`,
              to: pathToTasks,
              isEnabled: currentLesson?.isEnded || dateIsNow,
              enabledText: `${t('lesson.tabs.taskEvaluationTooltip')}`,
              isActive: Boolean(isTasksTabActive),
            },
          ]
        : []),
    ],
    [
      currentLesson?.isEnded,
      currentLesson?.hasTeacherAttachment,
      currentLesson?.taskTypes,
      pathToDescription,
      pathToTeacherMaterials,
      pathToJournal,
      pathToTasks,
      isTasksTabActive,
      dateIsNow,
      journalTab,
      t,
    ],
  );

  const studentTabs: ITab[] = useMemo(
    () => [
      {
        children: `${t('lesson.tabs.lesson')}`,
        to: pathToDescription,
      },
      ...(currentLesson?.hasStudentAttachment
        ? [
            {
              children: `${t('lesson.tabs.attachments')}`,
              to: pathToStudentMaterials,
              isEnabled: currentLesson?.isEnded || dateIsNow,
              enabledText: `${t('lesson.tabs.attachmentsTooltip')}`,
            },
          ]
        : []),
      ...(currentLesson?.hasStudentMarks
        ? [
            {
              children: `${t('lesson.tabs.taskResults')}`,
              to: pathToResults,
            },
          ]
        : []),
    ],
    [
      currentLesson?.isEnded,
      currentLesson?.hasStudentAttachment,
      currentLesson?.hasStudentMarks,
      pathToDescription,
      pathToStudentMaterials,
      pathToResults,
      dateIsNow,
      t,
    ],
  );

  const methodistTabs: ITab[] = useMemo(
    () => [
      {
        children: `${t('lesson.tabs.lesson')}`,
        to: pathToDescription,
      },
      ...(currentLesson?.hasStudentAttachment
        ? [
            {
              children: `${t('lesson.tabs.attachmentsStudent')}`,
              to: pathToStudentMaterials,
              isEnabled: true,
            },
          ]
        : []),
      ...(currentLesson?.hasTeacherAttachment
        ? [
            {
              children: `${t('lesson.tabs.attachmentsTeacher')}`,
              to: pathToTeacherMaterials,
              isEnabled: true,
            },
          ]
        : []),
    ],
    [
      currentLesson?.hasStudentAttachment,
      currentLesson?.hasTeacherAttachment,
      pathToDescription,
      pathToStudentMaterials,
      pathToTeacherMaterials,
      t,
    ],
  );

  const TABS = useMemo(() => {
    switch (userRole) {
      case UserRole.TEACHER:
        return teacherTabs;
      case UserRole.STUDENT:
        return studentTabs;
      default:
        return methodistTabs;
    }
  }, [userRole, teacherTabs, studentTabs, methodistTabs]);

  return {
    pathToDescription,
    pathToTasks,
    tabs: TABS,
  };
};

const TabText = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  margin-left: 4px;
`;
