import React, { memo } from 'react';

import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Icon, IconNames, Button } from 'atoms';
import { InputLanguage } from 'molecules';
import { FormFieldset } from 'organisms/ResumeForm/FormFieldset';
import { Colors, ILanguages, IResumeFields, LanguageLevel, LanguageLevelOptions } from 'context';
import { tablet } from 'services/ui';

export interface ILanguageBlock {
  fields: IResumeFields;
  languagesList: ILanguages[];
  onInputChange: (name: string, value: string | LanguageLevel) => void;
  onRemoveLanguage: (id: string) => void;
  onAddLanguage: () => void;
}

export const LanguageBlockComponent = memo(
  ({ fields, languagesList, onInputChange, onRemoveLanguage, onAddLanguage }: ILanguageBlock) => (
    <FormFieldset groupTitle={fields.languages.fieldsetTitle}>
      <Wrapper>
        {!!languagesList.length &&
          languagesList.map((language, index) => (
            <Element key={language.id}>
              <Label>{fields.languages.language.label}</Label>
              <InputWrapper>
                <InputLanguage
                  index={index}
                  options={LanguageLevelOptions}
                  languageValue={language.language}
                  preselectedOption={LanguageLevelOptions.find(
                    (option) => option.value === language.level,
                  )}
                  levelPlaceholder={fields.languages.level.placeholder}
                  languagePlaceholder={fields.languages.language.placeholder}
                  onInputChange={onInputChange}
                />

                <RemoveButton onClick={() => onRemoveLanguage(language.id)}>
                  <Icon name={IconNames.GrayCloseSm} width={24} height={24} />
                </RemoveButton>
              </InputWrapper>
            </Element>
          ))}
      </Wrapper>
      <StyledButton variant="underline" onClick={onAddLanguage} sidePaddings={0}>
        {fields.languages.addLanguageButton}
      </StyledButton>
    </FormFieldset>
  ),
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;

  ${tablet} {
    display: flex;
    align-items: center;
  }
`;

const Label = styled.p`
  color: ${Colors.black};
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.medium })};
  margin: 0 0 4px 0;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;

  ${tablet} {
    position: static;
  }

  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 16px;

  &:hover {
    & svg > * {
      transition: 0.5s;
      fill: ${Colors.black};
    }
  }
`;

const StyledButton = styled(Button)`
  max-width: max-content;
`;
