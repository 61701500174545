import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon, IconNames } from 'atoms';
import fontService, { FontWeight, TextType } from 'services/FontService';
import { desktop, tablet } from 'services/ui';
import { AttendanceType } from 'components/types';

import { LessonJournalFooter } from './LessonJournalFooter';
import { JournalComment } from './JournalComment';
import { StudentsAttendance } from './StudentsAttendance';

export interface ILessonJournal {
  comment: string;
  isFinished: boolean;
  students: AttendanceType[];
  finishLessonLoading: boolean;
  success: boolean;
  isAllStudentsChecked: boolean;
  isEnded: boolean;
  hasLessonsToFinish: boolean;
  onCheckAllStudents: () => void;
  onUncheckAllStudents: () => void;
  onCheckStudent: (index: number) => void;
  onChangeComment: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  onCancel: () => void;
  className?: string;
}

export const LessonJournal = memo(
  ({
    isFinished,
    students,
    comment,
    finishLessonLoading,
    isAllStudentsChecked,
    isEnded,
    hasLessonsToFinish,
    onCheckAllStudents,
    onUncheckAllStudents,
    onCheckStudent,
    onChangeComment,
    onSubmit,
    onCancel,
    className,
    success,
  }: ILessonJournal) => {
    const { t } = useTranslation();
    const hasCheckedStudents = useMemo(
      () => Boolean(students.find(({ checked }) => checked)),
      [students],
    );
    return (
      <LessonJournalContainer className={className}>
        {hasLessonsToFinish && !isFinished && isEnded && (
          <AttentionMessage>
            <IconStyled name={IconNames.WarningYellow} />
            <div>
              <AttentionBold>{t('lesson.attention')}</AttentionBold>
              {t('lesson.needToFinish')}
            </div>
          </AttentionMessage>
        )}
        {hasLessonsToFinish && !isFinished && !isEnded && (
          <AttentionMessage>
            <IconStyled name={IconNames.WarningYellow} />
            <div>
              <AttentionBold>{t('lesson.attention')}</AttentionBold>
              {t('lesson.haveUnfinished')}
            </div>
          </AttentionMessage>
        )}

        <StudentsAttendance
          students={students}
          isFinished={isFinished}
          isAllStudentsChecked={isAllStudentsChecked}
          onCheckAllStudents={onCheckAllStudents}
          onUncheckAllStudents={onUncheckAllStudents}
          onCheckStudent={onCheckStudent}
        />

        <JournalCommentStyled
          comment={comment}
          isFinished={isFinished}
          onChangeComment={onChangeComment}
        />

        <LessonJournalFooterStyled
          isFinished={isFinished}
          onSubmit={onSubmit}
          onCancel={onCancel}
          finishLessonLoading={finishLessonLoading}
          success={success}
          hasCheckedStudents={hasCheckedStudents}
        />
      </LessonJournalContainer>
    );
  },
);

const LessonJournalContainer = styled.div`
  width: 100%;
`;

const JournalCommentStyled = styled(JournalComment)<{ isFinished: boolean }>`
  margin: ${({ isFinished }) => (isFinished ? '32px 0 78px' : '32px 0 212px')};

  ${tablet} {
    margin: 40px 0 80px;
  }

  ${desktop} {
    margin: 60px 0 0;
  }
`;

const LessonJournalFooterStyled = styled(LessonJournalFooter)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const AttentionMessage = styled.div`
  display: flex;
  padding: 5px 12px;
  margin-bottom: 32px;
  border-radius: 4px;
  border: 1px solid #fdd32a;
  background: rgba(255, 218, 23, 0.08);
  ${fontService.text({ type: TextType.small })};

  ${tablet} {
    width: max-content;
    align-items: center;
    ${fontService.text({ type: TextType.normal })};
  }
`;

const AttentionBold = styled.span`
  ${fontService.text({ type: TextType.small, weight: FontWeight.bold })};

  ${tablet} {
    ${fontService.text({ type: TextType.normal, weight: FontWeight.bold })};
  }
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  margin-right: 4px;
  width: 16px;
  height: 16px;

  ${tablet} {
    width: 20px;
    height: 20px;
  }
`;
