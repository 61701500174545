import { Icon, IconNames } from 'components/atoms';
import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import fontService, { FontWeight } from 'services/FontService';
import { Colors } from 'context';

export const ScreenNotSupported = memo(() => {
  const { t } = useTranslation();
  return (
    <Container>
      <Icon name={IconNames.SmallScreen} width={104} height={116} />
      <Title>{t('screenNotSupported')}</Title>
      <Description>{t('screenNotSupportedText')}</Description>
    </Container>
  );
});

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.div`
  ${fontService.text({ size: 24, lineHeight: 133, weight: FontWeight.bold })}
  color: ${Colors.gray};
  margin: 24px 0 0 0;
  text-align: center;
`;

export const Description = styled.div`
  ${fontService.text({ size: 14, lineHeight: 150, weight: FontWeight.regular })}
  color: ${Colors.gray};
  margin: 8px 0 0 0;
  text-align: center;
`;
