import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments/user_fields';

export const SendAuthCode = gql`
  mutation sendAuthCode($email: String!, $scope: String!) {
    sendLmsAuthEmail(authenticationInput: { email: $email, scope: $scope }) {
      success
      createdAt
      customErrors {
        code
        field
        message
      }
    }
  }
`;

export const LoginMutation = gql`
  ${USER_FIELDS}
  mutation login($authenticationCode: String!, $email: String!, $scope: String!) {
    customLogin(authenticationCode: $authenticationCode, email: $email, scope: $scope) {
      success
      token
      refreshToken
      customErrors {
        code
        field
        message
      }
      user {
        ...UserFields
      }
    }
  }
`;

export const RefreshMutation = gql`
  mutation refreshToken($refresh_token: String!, $scope: String!, $activeRole: LmsRoles) {
    refreshToken(refreshToken: $refresh_token, scope: $scope, activeRole: $activeRole) {
      token
      refreshToken
      payload
      errors
    }
  }
`;

export const DemoLoginMutation = gql`
  mutation demoLogin($email: String!, $scope: String!) {
    demoCustomLogin(email: $email, scope: $scope) {
      token
      refreshToken
      errors
    }
  }
`;
