import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet } from 'services/ui';

import {
  Colors,
  ILanguages,
  IResumeDate,
  IResumeFields,
  ISocialMedia,
  LanguageLevel,
  Languages,
  RESUME_FIELDS_EN,
  RESUME_FIELDS_RU,
  SocialMedia,
  SocialMediaOptions,
} from 'context';

import { Button, TextArea } from 'atoms';
import { InputWithTags } from 'molecules';
import {
  PersonalInfoBlock,
  ExperienceBlock,
  IPersonalInfoValues,
  IExperienceValues,
  EducationBlock,
  IEducationValues,
  LanguageBlock,
} from 'organisms';
import { FormFieldset } from './FormFieldset';

export interface IResumeFormValues extends IPersonalInfoValues {
  experience: IExperienceValues[];
  education: IEducationValues[];
  about: string;
  languages: ILanguages[];
  skills: string[];
  tools: string[];
}

export interface IResumeForm {
  language: Languages;
  values: IResumeFormValues;
  errors?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    position?: string;
  };
  removeImageError: boolean;
  onInputChange: (
    name: string,
    value:
      | string
      | string[]
      | File
      | SocialMedia
      | ISocialMedia[]
      | IResumeDate
      | IExperienceValues[]
      | IEducationValues[]
      | LanguageLevel
      | ILanguages[],
  ) => void;
  onSaveResume: () => void;
  onDownloadResume: () => void;
}

export const ResumeFormComponent = memo(
  ({
    language,
    values,
    errors,
    removeImageError,
    onInputChange,
    onSaveResume,
    onDownloadResume,
  }: IResumeForm) => {
    const { t } = useTranslation();
    const Fields: IResumeFields = useMemo(
      () => (language === Languages.russian ? RESUME_FIELDS_RU : RESUME_FIELDS_EN),
      [language],
    );

    return (
      <Wrapper>
        <PersonalInfoBlock
          socialMediaOptions={SocialMediaOptions}
          fields={Fields}
          values={values}
          errors={errors}
          removeImageError={removeImageError}
          onChangeSocialMedia={onInputChange}
          onInputChange={onInputChange}
        />

        <ExperienceBlock
          language={language}
          fields={Fields}
          values={values.experience}
          onExperienceChange={onInputChange}
          onInputChange={onInputChange}
        />

        <EducationBlock
          language={language}
          fields={Fields}
          values={values.education}
          onChangeEducation={onInputChange}
          onInputChange={onInputChange}
        />

        <FormFieldset groupTitle={Fields.about.fieldsetTitle}>
          <Label>{Fields.about.label}</Label>
          <StyledTextArea
            value={values.about}
            rows={9}
            onChange={({ target: { value } }) => onInputChange('about', value)}
            placeholder={Fields.about.placeholder}
            borderColor={Colors.gray}
            maxLength={1500}
          />
        </FormFieldset>

        <LanguageBlock
          fields={Fields}
          languagesList={values.languages}
          onChangeLanguages={onInputChange}
          onInputChange={onInputChange}
        />

        <FormFieldset groupTitle={Fields.skills.fieldsetTitle}>
          <Label>{Fields.skills.label}</Label>
          <InputWithTags
            tagsList={values.skills}
            placeholder={Fields.skills.placeholder}
            onChange={(value) => onInputChange('skills', value)}
          />
        </FormFieldset>

        <FormFieldset groupTitle={Fields.tools.fieldsetTitle} last>
          <Label>{Fields.tools.label}</Label>
          <InputWithTags
            tagsList={values.tools}
            placeholder={Fields.tools.placeholder}
            onChange={(value) => onInputChange('tools', value)}
          />
        </FormFieldset>

        <ButtonsWrapper>
          {errors && !!Object.keys(errors).length && (
            <ErrorText>{t('resume.validation.fillEmptyField')}</ErrorText>
          )}
          <Buttons>
            <StyledButton
              variant="outline"
              colors={{ borderColor: Colors.gray }}
              onClick={onSaveResume}
              type="submit">
              {t('resume.button.save')}
            </StyledButton>
            <StyledButton onClick={onDownloadResume} type="submit">
              {t('resume.button.download')}
            </StyledButton>
          </Buttons>
        </ButtonsWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -20px;

  ${tablet} {
    width: 100%;
    margin: 0;
  }
`;

const Label = styled.p`
  margin-bottom: 4px;
  ${fontService.text({ size: 14, lineHeight: 145, weight: FontWeight.medium })}
`;

const StyledTextArea = styled(TextArea)`
  padding: 14px 12px;
  border-radius: 8px;
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.regular })}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  row-gap: 16px;
  background-color: ${Colors.bg_gray};
  border-left: 1px solid ${Colors.system_gray};
  border-right: 1px solid ${Colors.system_gray};
  border-bottom: 1px solid ${Colors.system_gray};
  border-radius: 0 0 24px 24px;
  padding: 20px 20px 32px;

  ${tablet} {
    justify-content: flex-end;
    flex-direction: row;
    padding: 12px 32px;
  }

  ${desktop} {
    padding: 16px 60px;
  }
`;

const ErrorText = styled.p`
  color: ${Colors.signal_red};
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })}

  ${tablet} {
    margin-right: auto;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  width: 100%;

  ${tablet} {
    flex-direction: row;
    gap: 16px;
    width: unset;
  }
`;

const StyledButton = styled(Button)`
  ${tablet} {
    max-width: max-content;
  }
`;
