import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import fontService, { FontWeight } from 'services/FontService';
import { Colors } from 'context';

export interface IMenuItem extends IMenuItemStyle {
  children: string;
  to: string;
  subMenuCount?: number;
  onMenuItemClick?: () => void;
}

export interface IMenuItemStyle {
  isActive?: boolean;
  disabled?: boolean;
}

export const MenuItem = memo(
  ({ to, children, subMenuCount, isActive, disabled, onMenuItemClick }: IMenuItem) => (
    <StyledMenuItem isActive={isActive} disabled={disabled} onClick={onMenuItemClick}>
      <StyledLink to={to}>
        {children}
        {!Number.isNaN(Number(subMenuCount)) && <CountIndicator>{subMenuCount}</CountIndicator>}
      </StyledLink>
    </StyledMenuItem>
  ),
);

const StyledLink = styled(Link)`
  box-sizing: border-box;
  padding: 0px;
  ${fontService.text({ size: 18, lineHeight: 133, weight: FontWeight.medium })}
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${Colors.black};
`;

const CountIndicator = styled.sup<IMenuItemStyle>`
  ${fontService.text({ size: 11, lineHeight: 145, weight: FontWeight.regular })}
  margin: 0px 0px 8px 4px;
`;

const StyledMenuItem = styled.li<IMenuItemStyle>`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;

  & > ${StyledLink} {
    color: ${({ isActive }) => (isActive ? Colors.main_dark : Colors.black)};
    opacity: ${({ disabled }) => (disabled ? '0.1' : '1')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    & > ${CountIndicator} {
      color: ${({ isActive }) => (isActive ? Colors.main_dark : Colors.gray)};
      opacity: ${({ disabled }) => (disabled ? '0.1' : '1')};
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    }
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
`;
