import React, { memo } from 'react';

import styled from 'styled-components';
import { Colors, IResumeDate, IResumeFields, Languages } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet } from 'services/ui';

import { Icon, IconNames, Input } from 'atoms';
import { InputLabel } from 'atoms/Input/Input';
import { InputYearMonth } from 'molecules';

export interface IEducationValues {
  id: string;
  institution: string;
  degree: string;
  specialization: string;
  startDate: IResumeDate;
  endDate: IResumeDate;
}

export interface IResumeEducationCard {
  language: Languages;
  fields: IResumeFields;
  index: number;
  values: IEducationValues;
  onInputChange: (name: string, value: string | IResumeDate) => void;
  isEndDateDisabled: boolean;
  onRemoveEducation: (id: string) => void;
}

export const ResumeEducationCardComponent = memo(
  ({
    language,
    fields,
    index,
    values,
    onInputChange,
    isEndDateDisabled,
    onRemoveEducation,
  }: IResumeEducationCard) => (
    <Wrapper>
      <RemoveButton onClick={() => onRemoveEducation(values.id)}>
        <StyledCloseIcon name={IconNames.CloseSm} width={24} height={24} />
      </RemoveButton>

      <Content>
        <ContentPart>
          <StyledInput
            type="text"
            label={fields.education.place.label}
            placeholder={fields.education.place.placeholder}
            value={values.institution}
            max={150}
            onChange={({ target: { value } }) =>
              onInputChange(`education[${index}].institution`, value)
            }
          />
          <StyledInput
            type="text"
            label={fields.education.degree.label}
            placeholder={fields.education.degree.placeholder}
            value={values.degree}
            max={100}
            onChange={({ target: { value } }) => onInputChange(`education[${index}].degree`, value)}
          />
        </ContentPart>

        <ContentPart>
          <StyledInput
            type="text"
            label={fields.education.specialization.label}
            placeholder={fields.education.specialization.placeholder}
            value={values.specialization}
            max={100}
            onChange={({ target: { value } }) =>
              onInputChange(`education[${index}].specialization`, value)
            }
          />

          <DatesWrapper>
            <InputWrapper>
              <Label>{fields.date.startLabel}</Label>
              <InputYearMonth
                value={values.startDate}
                language={language}
                monthsPlaceholder={fields.date.month}
                yearPlaceholder={fields.date.year}
                isDisabled={false}
                onSelect={(value) => onInputChange(`education[${index}].startDate`, { ...value })}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>{fields.date.endLabel}</Label>
              <InputYearMonth
                value={values.endDate}
                language={language}
                monthsPlaceholder={fields.date.month}
                yearPlaceholder={fields.date.year}
                isDisabled={isEndDateDisabled}
                onSelect={(value) => onInputChange(`education[${index}].endDate`, { ...value })}
              />
            </InputWrapper>
          </DatesWrapper>
        </ContentPart>
      </Content>
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  position: relative;
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 20px 20px 28px;

  ${tablet} {
    padding: 24px;
  }

  ${desktop} {
    padding: 32px;
  }
`;

const RemoveButton = styled.button`
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background-color: ${Colors.white};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 16px rgba(100, 100, 100, 0.2);
  position: absolute;
  top: -15px;
  right: -14px;
  z-index: 3;
  padding: 0;

  ${desktop} {
    width: 40px;
    height: 40px;
    top: -12px;
    right: -20px;
  }

  &:hover {
    & svg > * {
      transition: 0.5s;
      fill: ${Colors.gray_dark};
    }
  }
`;

const StyledCloseIcon = styled(Icon)`
  width: 20px;
  height: 20px;

  ${desktop} {
    width: 24px;
    height: 24px;
  }
`;

const Content = styled.div``;

const StyledInput = styled(Input)`
  ${InputLabel} {
    font-weight: ${FontWeight.medium};
  }
  margin-bottom: 24px;

  ${desktop} {
    width: 50%;
  }
`;

const ContentPart = styled.div`
  display: flex;
  flex-direction: column;

  ${desktop} {
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
  }
`;

const DatesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${desktop} {
    width: 50%;
  }
`;

const InputWrapper = styled.div`
  flex-grow: 1;
`;

const Label = styled.p`
  color: ${Colors.black};
  background-color: transparent;
  ${fontService.text({ size: 14, lineHeight: 125, weight: FontWeight.medium })};
  margin: 0 0 4px 0;
`;
