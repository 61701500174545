import React, { memo, useEffect } from 'react';
import styled from 'styled-components';

import { Footer, Header } from 'molecules';

import { Colors } from 'context';
import { desktop } from 'services/ui';

export interface IMainTemplate {
  children: React.ReactElement;
}

export const MainTemplate = memo(({ children }: IMainTemplate) => {
  useEffect(() => {
    document.body.classList.remove('no--scroll');
  });

  return (
    <Paper>
      <Header />

      <ContentWrapper>{children}</ContentWrapper>

      <Footer />
    </Paper>
  );
});

export const Paper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${Colors.white};
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1110px;
  min-height: calc(100vh - 345px);
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  z-index: 10;
  padding: 0 20px;

  ${desktop} {
    padding: 0;
  }
`;
