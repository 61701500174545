import React, { memo, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import { Attachments, Course, Courses, Login, ErrorPage, Resume } from 'pages';
import { MainTemplate, AttachmentsTemplate } from 'components/templates';

import { useReactiveVar } from '@apollo/client';

import { appState, authState } from 'core/state';
import { ScrollToTop } from 'core/hooks/Routes/useScrollToTop';
import { PATHS } from './config';

export const AppRouter = memo(() => {
  const navigate = useNavigate();
  const { networkError } = useReactiveVar(appState);
  const { isLoggedIn } = useReactiveVar(authState);
  const currentState = appState();

  useEffect(() => {
    if (networkError) {
      navigate(`/${networkError}`);
      appState({
        ...currentState,
        networkError: null,
      });
    }
  }, [navigate, networkError, currentState]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(PATHS.login);
    }
  }, [isLoggedIn, navigate]);

  return (
    <ScrollToTop>
      <Routes>
        <Route path={PATHS.main} element={<Navigate to={PATHS.courses} />} />
        <Route
          path={PATHS.courses}
          element={
            <MainTemplate>
              <Courses />
            </MainTemplate>
          }
        />
        <Route
          path={PATHS.course}
          element={
            <MainTemplate>
              <Course />
            </MainTemplate>
          }
        />
        <Route
          path={PATHS.lesson}
          element={
            <MainTemplate>
              <Course />
            </MainTemplate>
          }
        />

        <Route
          path={PATHS.resume}
          element={
            <MainTemplate>
              <Resume />
            </MainTemplate>
          }
        />

        <Route
          path={PATHS.attachment}
          element={
            <AttachmentsTemplate>
              <Attachments />
            </AttachmentsTemplate>
          }
        />

        <Route path={PATHS.login} key={PATHS.login} element={<Login />} />
        <Route path="*" element={<ErrorPage status={404} />} />
        <Route path="/404" element={<ErrorPage status={404} />} />
        <Route path="/500" element={<ErrorPage status={500} />} />
        <Route path="/503" element={<ErrorPage status={503} />} />
        <Route path="/error" element={<ErrorPage status="error" />} />
      </Routes>
    </ScrollToTop>
  );
});
