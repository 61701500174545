import * as React from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { client } from 'core/graphql/client';

import { useReactiveVar } from '@apollo/client';
import { authState, LmsRoles } from 'core';
import { logout, useActiveMenu, useGoToErrorPage, useRefreshToken, useUser } from 'core/hooks';
import { PATHS } from 'router/config';
import {
  IMenuStructure,
  MenuItems,
  StudentMenu,
  TeacherMenu,
  MethodistMenu,
  UserRole,
} from 'context';
import { getToken } from 'services/AuthService';

import { HeaderComponent } from './Header';

const transformMenu = (
  menu: IMenuStructure[],
  menuCount: number,
  activeMenuItem: MenuItems | undefined,
) =>
  menu.map((menuItem) =>
    menuItem.hasCount
      ? { ...menuItem, isActive: menuItem.name === activeMenuItem, subMenuCount: menuCount }
      : { ...menuItem, isActive: menuItem.name === activeMenuItem },
  );

export const Header = memo(() => {
  const { t } = useTranslation();
  const { user: userData, loading, error } = useUser();
  const activeMenu = useActiveMenu();
  const goToErrorPage = useGoToErrorPage();
  const navigate = useNavigate();
  const { active_role, isDemoUser } = useReactiveVar(authState);
  const { updateTokens } = useRefreshToken();

  const demoMenu = useMemo(
    () => [
      {
        name: MenuItems.Courses,
        children: `${t('menu.student.courses')}`,
        to: PATHS.courses,
        path: PATHS.courses,
        hasCount: true,
        subMenuCount: userData.groupsCount || 0,
        isActive: MenuItems.Courses === activeMenu,
        disabled: false,
      },
    ],
    [activeMenu, t, userData.groupsCount],
  );

  const finalMenu = useMemo(() => {
    switch (active_role) {
      case UserRole.STUDENT:
        return transformMenu(StudentMenu, userData.groupsCount, activeMenu);
      case UserRole.TEACHER:
        return transformMenu(TeacherMenu, userData.groupsCount, activeMenu);
      default:
        return transformMenu(MethodistMenu, userData.groupsCount, activeMenu);
    }
  }, [active_role, userData.groupsCount, activeMenu]);

  useEffect(() => {
    if (error) {
      console.log({ headerError: error });
      goToErrorPage();
    }
  }, [error, goToErrorPage]);

  const onChangeRole = useCallback(
    async (role: UserRole) => {
      const refresh_token = getToken();
      if (refresh_token) {
        await updateTokens(refresh_token, LmsRoles[role as keyof typeof LmsRoles]);

        await client.clearStore();
        navigate(PATHS.courses, { replace: true });
      }
    },
    [updateTokens, navigate],
  );

  return (
    <HeaderComponent
      onExit={logout}
      name={isDemoUser ? t('header.demoUser') : userData.name}
      role={userData.role}
      hasRoles={userData.hasRoles}
      onChangeRole={onChangeRole}
      avatarImage={userData.avatarImage}
      menuItems={isDemoUser ? demoMenu : finalMenu}
      loading={loading}
    />
  );
});
