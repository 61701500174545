import { makeVar } from '@apollo/client';
import { ToastTypes } from 'components/atoms';

export interface IToastState {
  [key: string]: { type: ToastTypes; text: string } | null;
}

export const defaultToastState: IToastState = Object.freeze({});

export const toastState = makeVar(defaultToastState);
