import * as React from 'react';
import { memo, useMemo } from 'react';

import styled from 'styled-components';

import ContentLoader from 'react-content-loader';
import { useWindowSize } from 'services/hooks';
import { CLIENT_WIDTH, desktop } from 'services/ui';

export const CoursesPreloader = memo((props) => {
  const { width: clientWidth } = useWindowSize();
  const maxContentScreenSize = 1110;
  const maxPreloaderWrapperSize = 1070;
  const paddingSize = 40;

  const tabletSizes = useMemo(() => {
    const defaultPreloaderValues = {
      top: 704,
      innerRight: 727,
      innerRadius: 716.15,
      outerRadius: 716.979,
      outerRight: 727.5,
      imageStart: 469,
    };

    const radiusStartPoint = 24;
    const innerBorderWidth = 1;
    const outerBorderWidth = 0.5;
    const innerRadiusValue = 11.85;
    const outerRadiusValue = 11.021;
    const imagePositionPercent = 0.644;

    if (clientWidth && clientWidth >= CLIENT_WIDTH.TABLET) {
      const wrapperWidth =
        clientWidth > maxContentScreenSize ? maxPreloaderWrapperSize : clientWidth - paddingSize;
      return {
        top: wrapperWidth - radiusStartPoint,
        innerRight: wrapperWidth - innerBorderWidth,
        innerRadius: wrapperWidth - innerRadiusValue,
        outerRadius: wrapperWidth - outerRadiusValue,
        outerRight: wrapperWidth - outerBorderWidth,
        imageStart: wrapperWidth * imagePositionPercent,
      };
    }
    return defaultPreloaderValues;
  }, [clientWidth]);

  const mobileSizes = useMemo(() => {
    const defaultPreloaderValues = {
      top: 319,
      innerRight: 334,
      radius: 327.56,
      outerRight: 334.5,
    };

    const radiusStartPoint = 16;
    const innerBorderWidth = 1;
    const outerBorderWidth = 0.5;
    const radiusValue = 7.44;

    if (clientWidth && clientWidth < CLIENT_WIDTH.TABLET) {
      const wrapperWidth = clientWidth - paddingSize;
      return {
        top: wrapperWidth - radiusStartPoint,
        innerRight: wrapperWidth - innerBorderWidth,
        radius: wrapperWidth - radiusValue,
        outerRight: wrapperWidth - outerBorderWidth,
      };
    }
    return defaultPreloaderValues;
  }, [clientWidth]);

  return (
    <Container>
      {clientWidth && clientWidth >= CLIENT_WIDTH.DESKTOP ? (
        <>
          <ContentLoader
            speed={2}
            width={1110}
            height={314}
            viewBox="0 0 1110 314"
            backgroundColor="rgba(244, 245, 247, 1)"
            foregroundColor="rgba(233, 234, 238, 1)"
            {...props}>
            <path
              d="M650 0H1086C1099.25 0 1110 10.7452 1110 24V290C1110 303.255 1099.25 314 1086 314H650V0Z"
              fill="#F4F5F7"
            />
            <path d="M48 40H252V61H48V40Z" fill="#F4F5F7" />
            <rect x="264" y="40" width="21" height="21" fill="#F4F5F7" />
            <rect x="293" y="40" width="160" height="21" fill="#F4F5F7" />
            <rect x="48" y="225" width="48" height="16" fill="#F4F5F7" />
            <rect x="48" y="245" width="48" height="20" fill="#F4F5F7" />
            <rect x="123" y="225" width="48" height="16" fill="#F4F5F7" />
            <rect x="123" y="245" width="48" height="20" fill="#F4F5F7" />
            <rect x="195" y="225" width="112" height="16" fill="#F4F5F7" />
            <rect x="195" y="245" width="184" height="20" fill="#F4F5F7" />
            <rect x="48" y="77" width="320" height="56" fill="#F4F5F7" />
            <rect x="48" y="145" width="545" height="56" fill="#F4F5F7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1086 2H24C11.8497 2 2 11.8497 2 24V290C2 302.15 11.8498 312 24 312H1086C1098.15 312 1108 302.15 1108 290V24C1108 11.8497 1098.15 2 1086 2ZM24 0C10.7452 0 0 10.7452 0 24V290C0 303.255 10.7452 314 24 314H1086C1099.25 314 1110 303.255 1110 290V24C1110 10.7452 1099.25 0 1086 0H24Z"
              fill="#CFD6DC"
            />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width={1110}
            height={314}
            viewBox="0 0 1110 314"
            backgroundColor="rgba(244, 245, 247, 0.7)"
            foregroundColor="rgba(233, 234, 238, 0.7)"
            {...props}>
            <path
              d="M650 0H1086C1099.25 0 1110 10.7452 1110 24V290C1110 303.255 1099.25 314 1086 314H650V0Z"
              fill="#F4F5F7"
            />
            <path d="M48 40H252V61H48V40Z" fill="#F4F5F7" />
            <rect x="264" y="40" width="21" height="21" fill="#F4F5F7" />
            <rect x="293" y="40" width="160" height="21" fill="#F4F5F7" />
            <rect x="48" y="225" width="48" height="16" fill="#F4F5F7" />
            <rect x="48" y="245" width="48" height="20" fill="#F4F5F7" />
            <rect x="123" y="225" width="48" height="16" fill="#F4F5F7" />
            <rect x="123" y="245" width="48" height="20" fill="#F4F5F7" />
            <rect x="195" y="225" width="112" height="16" fill="#F4F5F7" />
            <rect x="195" y="245" width="184" height="20" fill="#F4F5F7" />
            <rect x="48" y="77" width="320" height="56" fill="#F4F5F7" />
            <rect x="48" y="145" width="545" height="56" fill="#F4F5F7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1086 2H24C11.8497 2 2 11.8497 2 24V290C2 302.15 11.8498 312 24 312H1086C1098.15 312 1108 302.15 1108 290V24C1108 11.8497 1098.15 2 1086 2ZM24 0C10.7452 0 0 10.7452 0 24V290C0 303.255 10.7452 314 24 314H1086C1099.25 314 1110 303.255 1110 290V24C1110 10.7452 1099.25 0 1086 0H24Z"
              fill="#CFD6DC"
            />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width={1110}
            height={314}
            viewBox="0 0 1110 314"
            backgroundColor="rgba(244, 245, 247, 0.5)"
            foregroundColor="rgba(233, 234, 238, 0.5)"
            {...props}>
            <path
              d="M650 0H1086C1099.25 0 1110 10.7452 1110 24V290C1110 303.255 1099.25 314 1086 314H650V0Z"
              fill="#F4F5F7"
            />
            <path d="M48 40H252V61H48V40Z" fill="#F4F5F7" />
            <rect x="264" y="40" width="21" height="21" fill="#F4F5F7" />
            <rect x="293" y="40" width="160" height="21" fill="#F4F5F7" />
            <rect x="48" y="225" width="48" height="16" fill="#F4F5F7" />
            <rect x="48" y="245" width="48" height="20" fill="#F4F5F7" />
            <rect x="123" y="225" width="48" height="16" fill="#F4F5F7" />
            <rect x="123" y="245" width="48" height="20" fill="#F4F5F7" />
            <rect x="195" y="225" width="112" height="16" fill="#F4F5F7" />
            <rect x="195" y="245" width="184" height="20" fill="#F4F5F7" />
            <rect x="48" y="77" width="320" height="56" fill="#F4F5F7" />
            <rect x="48" y="145" width="545" height="56" fill="#F4F5F7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1086 2H24C11.8497 2 2 11.8497 2 24V290C2 302.15 11.8498 312 24 312H1086C1098.15 312 1108 302.15 1108 290V24C1108 11.8497 1098.15 2 1086 2ZM24 0C10.7452 0 0 10.7452 0 24V290C0 303.255 10.7452 314 24 314H1086C1099.25 314 1110 303.255 1110 290V24C1110 10.7452 1099.25 0 1086 0H24Z"
              fill="#CFD6DC"
            />
          </ContentLoader>
        </>
      ) : clientWidth && clientWidth >= CLIENT_WIDTH.TABLET ? (
        <>
          <ContentLoader
            speed={2}
            width="100%"
            height={240}
            viewBox="0 0 100% 240"
            backgroundColor="rgba(244, 245, 247, 1)"
            foregroundColor="rgba(233, 234, 238, 1)"
            {...props}>
            <path
              d={`M${tabletSizes.top} 1
              H24
              C11.8497 1 1 11.8497 1 24
              V216
              C1 228.15 11.8498 239 24 239 
              H${tabletSizes.top}
              C${tabletSizes.innerRadius} 239 ${tabletSizes.innerRight} 228.15 ${tabletSizes.innerRight} 216 
              V24
              C${tabletSizes.innerRight} 11.8497 ${tabletSizes.innerRadius} 1 ${tabletSizes.top} 1Z
              M0.5 24
              C0.5 11.0213 11.0213 0.5 24 0.5
              H${tabletSizes.top}
              C${tabletSizes.outerRadius} 0.5 ${tabletSizes.outerRight} 11.0213 ${tabletSizes.outerRight} 24
              V216
              C${tabletSizes.outerRight} 228.979 ${tabletSizes.outerRadius} 239.5 ${tabletSizes.top} 239.5
              H24
              C11.0213 239.5 0.5 228.979 0.5 216
              V24Z`}
              fill="white"
              stroke="#CFD6DC"
            />
            <rect x="32" y="64" width="240" height="36" fill="#F4F5F7" />
            <rect x="32" y="108" width="386" height="36" fill="#F4F5F7" />
            <rect x="34" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="34" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="106" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="106" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="178" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="178" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="250" y="168" width="88" height="16" fill="#F4F5F7" />
            <rect x="250" y="188" width="168" height="20" fill="#F4F5F7" />
            <path d="M32 32H208V48H32V32Z" fill="#F4F5F7" />
            <rect x="214" y="32" width="16" height="16" fill="#F4F5F7" />
            <rect x="234" y="32" width="104" height="16" fill="#F4F5F7" />
            <path
              d={`M${tabletSizes.imageStart} 1
              H${tabletSizes.top}
              C${tabletSizes.innerRadius} 1 ${tabletSizes.innerRight} 11.8498 ${tabletSizes.innerRight} 25
              V215
              C${tabletSizes.innerRight} 228.255 ${tabletSizes.innerRadius} 239 ${tabletSizes.top} 239
              H${tabletSizes.imageStart}
              V1Z`}
              fill="#F4F5F7"
            />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={240}
            viewBox="0 0 100% 240"
            backgroundColor="rgba(244, 245, 247, 0.7)"
            foregroundColor="rgba(233, 234, 238, 0.7)"
            {...props}>
            <path
              d={`M${tabletSizes.top} 1
              H24
              C11.8497 1 1 11.8497 1 24
              V216
              C1 228.15 11.8498 239 24 239 
              H${tabletSizes.top}
              C${tabletSizes.innerRadius} 239 ${tabletSizes.innerRight} 228.15 ${tabletSizes.innerRight} 216 
              V24
              C${tabletSizes.innerRight} 11.8497 ${tabletSizes.innerRadius} 1 ${tabletSizes.top} 1Z
              M0.5 24
              C0.5 11.0213 11.0213 0.5 24 0.5
              H${tabletSizes.top}
              C${tabletSizes.outerRadius} 0.5 ${tabletSizes.outerRight} 11.0213 ${tabletSizes.outerRight} 24
              V216
              C${tabletSizes.outerRight} 228.979 ${tabletSizes.outerRadius} 239.5 ${tabletSizes.top} 239.5
              H24
              C11.0213 239.5 0.5 228.979 0.5 216
              V24Z`}
              fill="white"
              stroke="#CFD6DC"
            />
            <rect x="32" y="64" width="240" height="36" fill="#F4F5F7" />
            <rect x="32" y="108" width="386" height="36" fill="#F4F5F7" />
            <rect x="34" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="34" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="106" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="106" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="178" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="178" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="250" y="168" width="88" height="16" fill="#F4F5F7" />
            <rect x="250" y="188" width="168" height="20" fill="#F4F5F7" />
            <path d="M32 32H208V48H32V32Z" fill="#F4F5F7" />
            <rect x="214" y="32" width="16" height="16" fill="#F4F5F7" />
            <rect x="234" y="32" width="104" height="16" fill="#F4F5F7" />
            <path
              d={`M${tabletSizes.imageStart} 1
              H${tabletSizes.top}
              C${tabletSizes.innerRadius} 1 ${tabletSizes.innerRight} 11.8498 ${tabletSizes.innerRight} 25
              V215
              C${tabletSizes.innerRight} 228.255 ${tabletSizes.innerRadius} 239 ${tabletSizes.top} 239
              H${tabletSizes.imageStart}
              V1Z`}
              fill="#F4F5F7"
            />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={240}
            viewBox="0 0 100% 240"
            backgroundColor="rgba(244, 245, 247, 0.5)"
            foregroundColor="rgba(233, 234, 238, 0.5)"
            {...props}>
            <path
              d={`M${tabletSizes.top} 1
              H24
              C11.8497 1 1 11.8497 1 24
              V216
              C1 228.15 11.8498 239 24 239 
              H${tabletSizes.top}
              C${tabletSizes.innerRadius} 239 ${tabletSizes.innerRight} 228.15 ${tabletSizes.innerRight} 216 
              V24
              C${tabletSizes.innerRight} 11.8497 ${tabletSizes.innerRadius} 1 ${tabletSizes.top} 1Z
              M0.5 24
              C0.5 11.0213 11.0213 0.5 24 0.5
              H${tabletSizes.top}
              C${tabletSizes.outerRadius} 0.5 ${tabletSizes.outerRight} 11.0213 ${tabletSizes.outerRight} 24
              V216
              C${tabletSizes.outerRight} 228.979 ${tabletSizes.outerRadius} 239.5 ${tabletSizes.top} 239.5
              H24
              C11.0213 239.5 0.5 228.979 0.5 216
              V24Z`}
              fill="white"
              stroke="#CFD6DC"
            />
            <rect x="32" y="64" width="240" height="36" fill="#F4F5F7" />
            <rect x="32" y="108" width="386" height="36" fill="#F4F5F7" />
            <rect x="34" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="34" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="106" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="106" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="178" y="168" width="48" height="16" fill="#F4F5F7" />
            <rect x="178" y="188" width="48" height="20" fill="#F4F5F7" />
            <rect x="250" y="168" width="88" height="16" fill="#F4F5F7" />
            <rect x="250" y="188" width="168" height="20" fill="#F4F5F7" />
            <path d="M32 32H208V48H32V32Z" fill="#F4F5F7" />
            <rect x="214" y="32" width="16" height="16" fill="#F4F5F7" />
            <rect x="234" y="32" width="104" height="16" fill="#F4F5F7" />
            <path
              d={`M${tabletSizes.imageStart} 1
              H${tabletSizes.top}
              C${tabletSizes.innerRadius} 1 ${tabletSizes.innerRight} 11.8498 ${tabletSizes.innerRight} 25
              V215
              C${tabletSizes.innerRight} 228.255 ${tabletSizes.innerRadius} 239 ${tabletSizes.top} 239
              H${tabletSizes.imageStart}
              V1Z`}
              fill="#F4F5F7"
            />
          </ContentLoader>
        </>
      ) : (
        <>
          <ContentLoader
            speed={2}
            width="100%"
            height={346}
            viewBox="0 0 100% 346"
            backgroundColor="rgba(244, 245, 247, 1)"
            foregroundColor="rgba(233, 234, 238, 1)"
            {...props}>
            <path
              d={`M${mobileSizes.top} 1
              H16
              C7.43959 1 1 7.43959 1 16
              V330
              C1 338.56 7.43959 345 16 345
              H${mobileSizes.top}
              C${mobileSizes.radius} 345 ${mobileSizes.innerRight} 338.56 ${mobileSizes.innerRight} 330
              V16
              C${mobileSizes.innerRight} 7.43959 ${mobileSizes.radius} 1 ${mobileSizes.top} 1Z

              M0.5 16
              C0.5 7.43959 7.43959 0.5 16 0.5
              H${mobileSizes.top}
              C${mobileSizes.radius} 0.5 ${mobileSizes.outerRight} 7.43959 ${mobileSizes.outerRight} 16
              V330
              C${mobileSizes.outerRight} 338.56 ${mobileSizes.radius} 345.5 ${mobileSizes.top} 345.5
              H16
              C7.43959 345.5 0.5 338.56 0.5 330
              V16Z`}
              fill="white"
              stroke="#CFD6DC"
            />
            <path d="M20 24H160V38H20V24Z" fill="#F4F5F7" />
            <rect x="164" y="24" width="14" height="14" fill="#F4F5F7" />
            <rect x="182" y="24" width="104" height="14" fill="#F4F5F7" />
            <rect x="20" y="54" width="280" height="30" fill="#F4F5F7" />
            <rect x="20" y="122" width="140" height="30" fill="#F4F5F7" />
            <rect x="20" y="88" width="210" height="30" fill="#F4F5F7" />
            <rect x="20" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="20" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="92" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="92" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="164" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="164" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="20" y="248" width="88" height="16" fill="#F4F5F7" />
            <rect x="20" y="268" width="168" height="20" fill="#F4F5F7" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={346}
            viewBox="0 0 100% 346"
            backgroundColor="rgba(244, 245, 247, 0.7)"
            foregroundColor="rgba(233, 234, 238, 0.7)"
            {...props}>
            <path
              d={`M${mobileSizes.top} 1
              H16
              C7.43959 1 1 7.43959 1 16
              V330
              C1 338.56 7.43959 345 16 345
              H${mobileSizes.top}
              C${mobileSizes.radius} 345 ${mobileSizes.innerRight} 338.56 ${mobileSizes.innerRight} 330
              V16
              C${mobileSizes.innerRight} 7.43959 ${mobileSizes.radius} 1 ${mobileSizes.top} 1Z

              M0.5 16
              C0.5 7.43959 7.43959 0.5 16 0.5
              H${mobileSizes.top}
              C${mobileSizes.radius} 0.5 ${mobileSizes.outerRight} 7.43959 ${mobileSizes.outerRight} 16
              V330
              C${mobileSizes.outerRight} 338.56 ${mobileSizes.radius} 345.5 ${mobileSizes.top} 345.5
              H16
              C7.43959 345.5 0.5 338.56 0.5 330
              V16Z`}
              fill="white"
              stroke="#CFD6DC"
            />
            <path d="M20 24H160V38H20V24Z" fill="#F4F5F7" />
            <rect x="164" y="24" width="14" height="14" fill="#F4F5F7" />
            <rect x="182" y="24" width="104" height="14" fill="#F4F5F7" />
            <rect x="20" y="54" width="280" height="30" fill="#F4F5F7" />
            <rect x="20" y="122" width="140" height="30" fill="#F4F5F7" />
            <rect x="20" y="88" width="210" height="30" fill="#F4F5F7" />
            <rect x="20" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="20" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="92" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="92" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="164" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="164" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="20" y="248" width="88" height="16" fill="#F4F5F7" />
            <rect x="20" y="268" width="168" height="20" fill="#F4F5F7" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={346}
            viewBox="0 0 100% 346"
            backgroundColor="rgba(244, 245, 247, 0.5)"
            foregroundColor="rgba(233, 234, 238, 0.5)"
            {...props}>
            <path
              d={`M${mobileSizes.top} 1
              H16
              C7.43959 1 1 7.43959 1 16
              V330
              C1 338.56 7.43959 345 16 345
              H${mobileSizes.top}
              C${mobileSizes.radius} 345 ${mobileSizes.innerRight} 338.56 ${mobileSizes.innerRight} 330
              V16
              C${mobileSizes.innerRight} 7.43959 ${mobileSizes.radius} 1 ${mobileSizes.top} 1Z

              M0.5 16
              C0.5 7.43959 7.43959 0.5 16 0.5
              H${mobileSizes.top}
              C${mobileSizes.radius} 0.5 ${mobileSizes.outerRight} 7.43959 ${mobileSizes.outerRight} 16
              V330
              C${mobileSizes.outerRight} 338.56 ${mobileSizes.radius} 345.5 ${mobileSizes.top} 345.5
              H16
              C7.43959 345.5 0.5 338.56 0.5 330
              V16Z`}
              fill="white"
              stroke="#CFD6DC"
            />
            <path d="M20 24H160V38H20V24Z" fill="#F4F5F7" />
            <rect x="164" y="24" width="14" height="14" fill="#F4F5F7" />
            <rect x="182" y="24" width="104" height="14" fill="#F4F5F7" />
            <rect x="20" y="54" width="280" height="30" fill="#F4F5F7" />
            <rect x="20" y="122" width="140" height="30" fill="#F4F5F7" />
            <rect x="20" y="88" width="210" height="30" fill="#F4F5F7" />
            <rect x="20" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="20" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="92" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="92" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="164" y="184" width="48" height="16" fill="#F4F5F7" />
            <rect x="164" y="204" width="48" height="20" fill="#F4F5F7" />
            <rect x="20" y="248" width="88" height="16" fill="#F4F5F7" />
            <rect x="20" y="268" width="168" height="20" fill="#F4F5F7" />
          </ContentLoader>
        </>
      )}
    </Container>
  );
});

const Container = styled.div`
  & svg {
    margin-bottom: 20px;

    ${desktop} {
      margin-bottom: 32px;
    }
  }
`;
