import { gql } from '@apollo/client';

export const LessonQuery = gql`
  query lessonForLms($groupLessonId: ID) {
    lessonForLms(groupLessonId: $groupLessonId) {
      ... on GroupLessonForLMSType {
        id
        name
        date
        index
        lmsDescription
        startTime
        endTime
        finished
        needToFinish
        comment
        video
        taskTypes
        hasTeacherAttachment
      }
      ... on StudentLessonType {
        id
        name
        date
        index
        lmsDescription
        startTime
        endTime
        finished
        comment
        video
        hasStudentMarks
        hasStudentAttachment
      }
      ... on MethodistLessonType {
        id
        name
        date
        index
        lmsDescription
        startTime
        endTime
        finished
        comment
        video
        hasTeacherAttachment
        hasStudentAttachment
      }
    }
  }
`;

export const LessonAttendancesQuery = gql`
  query groupLessonAttendanceForLms($groupLessonId: ID) {
    groupLessonAttendanceForLms(groupLessonId: $groupLessonId) {
      id
      attendance
      student {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const LessonAllAttachmentsQuery = gql`
  query groupLessonAttachmentForLms($groupLessonId: ID, $attachmentRole: String) {
    groupLessonAttachmentForLms(groupLessonId: $groupLessonId, attachmentRole: $attachmentRole) {
      id
      title
      file
    }
  }
`;

export const LessonAttachmentQuery = gql`
  query groupLessonAttachment($attachmentId: ID, $attachmentRole: String) {
    groupLessonAttachment(attachmentId: $attachmentId, attachmentRole: $attachmentRole) {
      id
      title
      file
    }
  }
`;

export const LessonStudentsMarksQuery = gql`
  query studentsMarks($lessonsId: ID!, $lessonIdForMarks: String!, $taskType: String!) {
    studentsMarks(lessonsId: $lessonsId) {
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
            student {
              id
            }
          }
          marks(taskType: $taskType, lessonId: $lessonIdForMarks) {
            edges {
              node {
                id
                date
                mark
                hwMark
              }
            }
          }
        }
      }
    }
  }
`;

export const StudentMarksQuery = gql`
  query studentMarks($lessonsId: ID!) {
    studentMarks(lessonsId: $lessonsId) {
      edges {
        node {
          mark
          hwMark
          date
          taskType
        }
      }
    }
  }
`;
