import { useCallback, useEffect } from 'react';

import { ResumeQuery } from 'core/operations/queries/resume';
import { LanguageEnum, useGetSocialMedia, useUploadResumeMutation } from 'core';
import { Languages, LanguagesType } from 'context';
import { IResumeFormValues } from 'organisms';
import {
  transformEducation,
  transformExperience,
  transformLanguages,
  transformPhoto,
  transformSocialMedia,
} from './transform';

type UploadResumeType = [
  (variables: IResumeFormValues) => void,
  { success: boolean; loading: boolean; error?: string | null },
];

export const useUploadResume = (language: Languages): UploadResumeType => {
  const [loadMedia, { data: mediaData }] = useGetSocialMedia();

  const [uploadResumeMutation, { data, loading }] = useUploadResumeMutation({
    refetchQueries: [{ query: ResumeQuery, variables: { language } }],
  });

  useEffect(() => {
    loadMedia();
  }, [loadMedia]);

  const uploadResume = useCallback(
    (values: IResumeFormValues) =>
      uploadResumeMutation({
        variables: {
          photo: transformPhoto(values.image),
          resumeInput: {
            language: LanguageEnum[LanguagesType[language]],
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            jobTitle: values.position,
            additionalInfo: values.about,
            socialMedia: transformSocialMedia(values.socialMedia, mediaData),
            cvLanguages: transformLanguages(values.languages),
            education: transformEducation(values.education),
            experience: transformExperience(values.experience),
            skills: values.skills,
            tools: values.tools,
          },
        },
      }),
    [uploadResumeMutation, language, mediaData],
  );

  return [
    uploadResume,
    {
      success: Boolean(data?.uploadResume?.success),
      loading,
      error: data?.uploadResume?.customErrors?.[0]?.code,
    },
  ];
};
